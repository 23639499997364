<template>
  <layout-default :links="links">
    <slot />
  </layout-default>
</template>

<script>
import layoutDefault from '../layout-default.vue'

export default {
  components: { layoutDefault },
  computed: {
    links() {
      return [
        {
          id: 1,
          label: this.$t('layout.settings'),
          to: '/parametrages'
        }
      ]
    }
  }
}
</script>

<style></style>
