import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getDatabases() {
    try {
      const response = await axios.get(config.apiURL + '/api/databases')

      return {
        databases: response.data.databases
      }
    } catch (err) {
      throw createError(err)
    }
  }
}
