import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getAllEshopOrders() {
    try {
      const response = await axios.get(config.apiURL + '/api/eshop')

      return {
        orders: response.data.orders,
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async createEshopOrder(data) {
    try {
      const response = await axios.post(config.apiURL + '/api/eshop', data)

      return {
        order: response.data.order,
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async repairBooking(data) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/booking/repair',
        data
      )

      return {
        data: response.data,
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async refundAndMailBooking(transactionID, emailSubject, mailToSend) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/transactions/refund-and-email',
        {
          transactionID,
          emailSubject,
          mailToSend,
        }
      )

      return {
        data: response.data,
      }
    } catch (err) {
      throw createError(err)
    }
  },

  // async getAllPostCategories() {
  //   try {
  //     const response = await axios.get(config.apiURL + '/api/postcategories')

  //     return {
  //       postCategories: response.data.postCategories,
  //     }
  //   } catch (err) {
  //     throw createError(err)
  //   }
  // },
  // async getPostCategory(id) {
  //   try {
  //     const response = await axios.get(
  //       config.apiURL + '/api/postcategories/' + id
  //     )

  //     return {
  //       postCategory: response.data.postCategory,
  //     }
  //   } catch (err) {
  //     throw createError(err)
  //   }
  // },
  // async createPostCategory(data) {
  //   try {
  //     const response = await axios.post(
  //       config.apiURL + '/api/postcategories',
  //       data
  //     )

  //     return {
  //       postCategory: response.data.postCategory,
  //     }
  //   } catch (err) {
  //     throw createError(err)
  //   }
  // },
  // async updatePostCategory(id, data) {
  //   try {
  //     const response = await axios.put(
  //       config.apiURL + '/api/postcategories/' + id,
  //       data
  //     )

  //     return {
  //       postCategory: response.data.postCategory,
  //     }
  //   } catch (err) {
  //     throw createError(err)
  //   }
  // },
  // async deletePostCategory(id) {
  //   try {
  //     const response = await axios.delete(
  //       config.apiURL + '/api/postcategories/' + id
  //     )

  //     return {
  //       postCategory: response.data.postCategory,
  //     }
  //   } catch (err) {
  //     throw createError(err)
  //   }
  // },
}
