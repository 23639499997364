<template>
  <div>
    <v-dialog
      :width="$vuetify.breakpoint.mdAndUp ? '50vw' : '80vw'"
      v-model="dialogUser"
    >
      <userForm
        id="listeUser"
        v-model="dialogUser"
        :currentAuth="currentAuth"
      />
    </v-dialog>
    <simple-modal
      :icon="$icones.accountGroup"
      @closeModal="closeList"
      title="Liste des comptes"
    >
      <template v-slot:simpleModalContent>
        <v-btn @click="editUser(null)" class="mb-3" text outlined rounded
          >Créer un compte</v-btn
        >
        <v-row>
          <v-col
            v-for="user in users"
            :key="user.id"
            cols="12"
            lg="3"
            md="6"
            sm="12"
          >
            <v-card height="250">
              <v-list-item-content class="justify-center">
                <div class="mx-auto text-center">
                  <v-avatar class="mt-3" color="primary">
                    <span class="white--text headline">{{
                      getFirstLetter(user.firstname)
                    }}</span>
                  </v-avatar>
                  <h3 class="mt-3">
                    <span v-if="user.firstname"
                      >{{ user.firstname }} {{ user.lastname }}</span
                    >
                    <span v-else>nom prénom manquant</span>
                  </h3>
                  <p class="caption mt-1">{{ user.email }}</p>
                  <div>
                    <v-btn
                      @click="editUser(user)"
                      class="mb-4"
                      text
                      outlined
                      rounded
                      >éditer le compte</v-btn
                    >
                  </div>
                  <v-btn
                    v-if="$store.state.auth.user.id !== user.id"
                    @click="removeUser(user)"
                    class="mb-4"
                    text
                    outlined
                    rounded
                    >Supprimer le compte</v-btn
                  >
                </div>
              </v-list-item-content>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </simple-modal>
    <!-- <v-card>
      <v-card-title>
        <h3>Liste des comptes</h3>
        <v-spacer />

        <icon-display
          @actionClick="closeList"
          :icon="myIcones.close"
          :small="false"
        />
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-btn @click="editUser(null)" class="mb-3" text outlined rounded
          >Créer un compte</v-btn
        >
        <v-row>
          <v-col
            v-for="user in users"
            :key="user.id"
            cols="12"
            lg="3"
            md="6"
            sm="12"
          >
            <v-card height="250">
              <v-list-item-content class="justify-center">
                <div class="mx-auto text-center">
                  <v-avatar class="mt-3" color="primary">
                    <span class="white--text headline">{{
                      getFirstLetter(user.firstname)
                    }}</span>
                  </v-avatar>
                  <h3 class="mt-3">
                    <span v-if="user.firstname"
                      >{{ user.firstname }} {{ user.lastname }}</span
                    >
                    <span v-else>nom prénom manquant</span>
                  </h3>
                  <p class="caption mt-1">{{ user.email }}</p>
                  <div>
                    <v-btn
                      @click="editUser(user)"
                      class="mb-4"
                      text
                      outlined
                      rounded
                      >éditer le compte</v-btn
                    >
                  </div>
                  <v-btn
                    v-if="$store.state.auth.user.id !== user.id"
                    @click="removeUser(user)"
                    class="mb-4"
                    text
                    outlined
                    rounded
                    >Supprimer le compte</v-btn
                  >
                </div>
              </v-list-item-content>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card> -->
  </div>
</template>

<script>
import userForm from '@/components/users/form'
import { MKPSDK } from '@/packages/artebeaute-sdk/src'
import SimpleModal from '@/components/helpers/simpleModal'
export default {
  components: { userForm, SimpleModal },
  data: () => {
    return {
      dialogUser: false,
      currentAuth: null
    }
  },
  computed: {
    users: function() {
      return this.$store.state.auth.users
    }
  },
  methods: {
    closeList() {
      this.$emit('input', false)
    },
    getFirstLetter(val) {
      return val ? val.toUpperCase().charAt(0) : '?'
    },
    editUser(val) {
      this.currentAuth = val
      this.dialogUser = true
    },
    async removeUser(user) {
      if (!confirm('Etes-vous sûr ?')) {
        return
      }

      try {
        await MKPSDK.CmpUsers.deleteCmpUser(user.id)

        this.$store.state.auth.users = this.$store.state.auth.users.filter(
          u => u.id !== user.id
        )
      } catch (err) {
        this.error = 'Impossible de supprimer le compte'
      }
    }
  }
}
</script>

<style></style>
