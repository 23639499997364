import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async checkGift(code) {
    try {
      const response = await axios.get(
        config.apiURL + '/api/gifts/check/' + code
      )

      return response.data.gift
    } catch (err) {
      throw createError(err)
    }
  },
  async getAll() {
    try {
      const response = await axios.get(config.apiURL + '/api/gifts')

      return response.data.orders
    } catch (err) {
      throw createError(err)
    }
  },
  async resendPDF(to, id, email) {
    // to : soit l 'email soit me
    // email
    try {
      await axios.post(
        config.apiURL + '/api/gifts/' + id + '/resend?curlang=fr',
        {
          to,
          email
        }
      )

      return true
    } catch (err) {
      throw createError(err)
    }
  }
}
