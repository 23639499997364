import { MKPSDK } from '@/packages/artebeaute-sdk/src'

export default {
  namespaced: true,
  state: () => ({
    releases: [],
    loaded: false
  }),
  getters: {
    getReleases(state) {
      return state.releases
    }
  },
  mutations: {
    SET_RELEASES(state, value) {
      state.loaded = true
      state.releases = value
    }
  },
  actions: {
    async fetchAllReleases({ commit, state }) {
      if (state.releases.length) {
        return state.releases
      }

      try {
        const response = await MKPSDK.Releases.getAllReleases()

        commit('SET_RELEASES', response.releases)
      } catch (err) {
        return []
      }
    }
  }
}
