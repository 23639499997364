import { createError } from '../helpers/errors'
import config from '../config'
import axios from 'axios'

export default {
  async getAllAbCustomersSearch(filter, page, enregByPage) {
    try {
      let params = {}
      let myData = filter

      params.filter = true
      if (page) {
        params.page = page
      }
      if (enregByPage) {
        params.enregbypage = enregByPage
      }
      const response = await axios.post(
        config.apiURL + '/api/customers/artebeaute/search',
        myData,
        { params: params }
      )
      return {
        customers: response.data.customers
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getAllAbCustomers(page, enregByPage) {
    try {
      let params = {}
      params.enregbypage = enregByPage
      if (page) {
        params.page = page
      }
      const response = await axios.get(
        config.apiURL + '/api/customers/artebeaute',
        { params: params }
      )
      return {
        customers: response.data.customers
      }
    } catch (e) {
      throw createError(e)
    }
  },
  async getAllCustomers(page, perPage) {
    try {
      let params = {
        page,
        perPage
      }
      const response = await axios.get(
        config.apiURL + '/api/customers/paginate',
        { params }
      )
      return {
        customers: response.data
      }
    } catch (e) {
      throw createError(e)
    }
  }
}
