// noinspection AllyPlainJsInspection

import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

import {
  ArteBeauteSDK,
  MKPSDK,
  StorageSDK,
  PassportSDK,
  StatsSDK,
  CaSDK,
} from '@/packages/artebeaute-sdk/src'
import axios from 'axios'
import Login from '@/views/Login.vue'
import NotFound from '@/views/NotFound.vue'

// import LayoutEmpty from '@/layouts/layout-empty.vue'
import LayoutStat from '@/layouts/layout-statistiques'
import LayoutStatNew from '@/layouts/layout-statnew'
import SubLayoutDatabase from '@/layouts/sublayouts/database.vue'
import SubLayoutBooker from '@/layouts/sublayouts/booker.vue'
import SubLayoutSettings from '@/layouts/sublayouts/settings.vue'
import SubLayoutCommunications from '@/layouts/sublayouts/communications.vue'

Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/'),
  },
  {
    path: '/database',
    name: 'Data Base',
    component: () =>
      import(/* webpackChunkName: "data-base" */ '@/views/database/'),
    meta: {
      layout: SubLayoutDatabase,
    },
  },
  {
    path: '/parametrages',
    name: 'Configuration de la compagnie',
    params: { icon: 'mdi-cog-box' },
    component: () =>
      import(
        /* webpackChunkName: "shops" */ '@/views/globalConfiguration/index.vue'
      ),
    meta: {
      layout: SubLayoutSettings,
    },
  },
  {
    path: '/database/shops',
    name: 'Liste des boutiques',
    params: { icon: 'mdi-store' },
    component: () =>
      import(
        /* webpackChunkName: "shops" */ '@/views/database/shops/index.vue'
      ),
    meta: {
      layout: SubLayoutDatabase,
    },
  },
  {
    path: '/database/articles',
    name: 'Liste des articles',
    component: () =>
      import(
        /* webpackChunkName: "articles" */ '@/views/database/articles.vue'
      ),
    meta: {
      layout: SubLayoutDatabase,
    },
  },
  {
    path: '/database/ressources',
    name: 'Liste des ressources',
    component: () =>
      import(
        /* webpackChunkName: "resources" */ '@/views/database/resources.vue'
      ),
  },
  {
    path: '/database/categories',
    name: 'Liste des Categories',
    component: () =>
      import(
        /* webpackChunkName: "categories" */ '@/views/database/categories.vue'
      ),
  },
  {
    path: '/database/aiguilles',
    name: 'Liste des aiguilles',
    component: () =>
      import(
        /* webpackChunkName: "aiguilles" */ '@/views/database/aiguilles.vue'
      ),
  },
  {
    path: '/database/pigments',
    name: 'List des pigments',
    component: () =>
      import(
        /* webpackChunkName: "pigments" */ '@/views/database/pigments.vue'
      ),
  },
  {
    path: '/database/consentements',
    name: 'List des consentements',
    component: () =>
      import(
        /* webpackChunkName: "consentements" */ '@/views/database/consentements.vue'
      ),
  },
  {
    path: '/database/collections',
    name: 'Collections',
    component: () =>
      import(
        /* webpackChunkName: "starter" */ '@/views/database/collections/index.vue'
      ),
  },
  {
    path: '/database/collections/items/:type',
    name: 'CollectionsItems',
    component: () =>
      import(
        /* webpackChunkName: "starter" */ '@/views/database/collections/items/index.vue'
      ),
  },
  {
    path: '/eshop',
    name: 'eshop',
    component: () =>
      import(/* webpackChunkName: "data-base" */ '@/views/eshop/'),
  },
  {
    path: '/booker',
    name: 'booker',
    component: () =>
      import(/* webpackChunkName: "data-base" */ '@/views/booker/'),
    meta: {
      layout: SubLayoutBooker,
    },
  },
  {
    path: '/booker/transactions',
    name: 'bookerTransaction',
    component: () =>
      import(/* webpackChunkName: "data-base" */ '@/views/booker/transactions'),
    meta: {
      layout: LayoutStat,
    },
  },
  {
    path: '/booker/customers',
    name: 'bookerCustomers',
    component: () =>
      import(/* webpackChunkName: "data-base" */ '@/views/booker/customers'),
    meta: {
      layout: SubLayoutBooker,
    },
  },
  {
    path: '/booker/maintenance',
    name: 'bookerMaintenance',
    component: () =>
      import(/* webpackChunkName: "data-base" */ '@/views/booker/maintenance'),
    meta: {
      layout: SubLayoutBooker,
    },
  },
  {
    path: '/booker/fails-transactions',
    name: 'bookerFailsTransaction',
    component: () =>
      import(
        /* webpackChunkName: "data-base" */ '@/views/booker/fails-transactions'
      ),
    meta: {
      layout: SubLayoutBooker,
    },
  },
  {
    path: '/booker/stripe',
    name: 'bookerStripeCheck',
    component: () =>
      import(/* webpackChunkName: "data-base" */ '@/views/booker/stripe'),
    meta: {
      layout: SubLayoutBooker,
    },
  },
  {
    path: '/booker/carts',
    name: 'bookerCartsTransaction',
    component: () =>
      import(/* webpackChunkName: "data-base" */ '@/views/booker/carts'),
    meta: {
      layout: SubLayoutBooker,
    },
  },
  {
    path: '/booker/gifts',
    name: 'bookerGift',
    component: () =>
      import(/* webpackChunkName: "data-base" */ '@/views/booker/gifts'),
    meta: {
      layout: SubLayoutBooker,
    },
  },
  {
    path: '/booker/remises',
    name: 'bookerGift',
    component: () =>
      import(/* webpackChunkName: "data-base" */ '@/views/booker/remises'),
    meta: {
      layout: SubLayoutBooker,
    },
  },
  {
    path: '/passbeaute',
    name: 'pass beauté',
    component: () =>
      import(/* webpackChunkName: "data-base" */ '@/views/passbeaute/'),
  },
  {
    path: '/vault',
    name: 'Coffre',
    component: () =>
      import(/* webpackChunkName: "data-base" */ '@/views/vault/'),
  },
  {
    path: '/stats',
    name: 'Statistiques',
    component: () =>
      import(/* webpackChunkName: "data-base" */ '@/views/stats/'),
    meta: {
      layout: LayoutStatNew,
    },
  },
  {
    path: '/management',
    name: 'Gestion',
    component: () =>
      import(/* webpackChunkName: "data-base" */ '@/views/management/'),
  },
  {
    path: '/management/reversBookerByShop',
    name: 'Avis de paiement',
    component: () =>
      import(
        /* webpackChunkName: "data-base" */ '@/views/management/reversBookerByShop'
      ),
    meta: {
      layout: LayoutStat,
    },
  },
  {
    path: '/management/customers',
    name: 'Comptes Clients',
    component: () =>
      import(
        /* webpackChunkName: "data-base" */ '@/views/management/customers'
      ),
    meta: {
      layout: LayoutStat,
    },
  },
  {
    path: '/stats/customers',
    name: 'Clients',
    component: () =>
      import(/* webpackChunkName: "data-base" */ '@/views/stats/customers'),
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    // meta: {
    //   layout: LayoutEmpty
    // }
  },
  {
    path: '/activate/:token',
    name: 'Activate',
    component: () =>
      import(/* webpackChunkName: "data-base" */ '@/views/users/activate'),
  },
  {
    path: '/users/me',
    name: 'UsersMe',
    component: () =>
      import(/* webpackChunkName: "data-base" */ '@/views/users/me'),
  },
  {
    path: '/users',
    name: 'UsersIndex',
    component: () =>
      import(/* webpackChunkName: "data-base" */ '@/views/users/index'),
  },
  {
    path: '/users/add',
    name: 'UsersAdd',
    component: () =>
      import(/* webpackChunkName: "data-base" */ '@/views/users/add'),
  },
  {
    path: '/users/edit/:userId',
    name: 'UsersEdit',
    component: () =>
      import(/* webpackChunkName: "data-base" */ '@/views/users/edit'),
  },
  {
    path: '/closeddays',
    name: 'ClosedDaysIndex',
    component: () =>
      import(/* webpackChunkName: "data-base" */ '@/views/closeddays/index'),
    meta: {
      layout: SubLayoutDatabase,
    },
  },
  {
    path: '/closeddays/new',
    name: 'ClosedDaysNew',
    component: () =>
      import(/* webpackChunkName: "data-base" */ '@/views/closeddays/new'),
    meta: {
      layout: SubLayoutDatabase,
    },
  },
  {
    path: '/coupons',
    name: 'CouponsIndex',
    component: () =>
      import(/* webpackChunkName: "data-base" */ '@/views/coupons/index'),
    meta: {
      layout: SubLayoutDatabase,
    },
  },
  {
    path: '/coupons/new',
    name: 'CouponsNew',
    component: () =>
      import(/* webpackChunkName: "data-base" */ '@/views/coupons/new'),
    meta: {
      layout: SubLayoutDatabase,
    },
  },
  {
    path: '/coupons/batch',
    name: 'CouponsBatch',
    component: () =>
      import(/* webpackChunkName: "data-base" */ '@/views/coupons/batch'),
    meta: {
      layout: SubLayoutDatabase,
    },
  },
  {
    path: '/coupons/edit/:id',
    name: 'CouponsEdit',
    component: () =>
      import(/* webpackChunkName: "data-base" */ '@/views/coupons/edit'),
    meta: {
      layout: SubLayoutDatabase,
    },
  },
  {
    path: '/database/blog/categories',
    name: 'BlogPostCategories',
    component: () =>
      import(
        /* webpackChunkName: "data-base" */ '@/views/database/blog/categories'
      ),
    meta: {
      layout: SubLayoutDatabase,
    },
  },
  {
    path: '/database/blog/posts',
    name: 'BlogPosts',
    component: () =>
      import(/* webpackChunkName: "data-base" */ '@/views/database/blog/posts'),
    meta: {
      layout: SubLayoutDatabase,
    },
  },
  {
    path: '/communications',
    name: 'Communications',
    component: () =>
      import(/* webpackChunkName: "data-base" */ '@/views/communications/'),
  },
  {
    path: '/communications/events',
    name: 'Events',
    component: () =>
      import(
        /* webpackChunkName: "data-base" */ '@/views/communications/events/'
      ),
    meta: {
      layout: SubLayoutCommunications,
    },
  },
  {
    path: '/communications/alerts',
    name: 'Alerts',
    component: () =>
      import(
        /* webpackChunkName: "data-base" */ '@/views/communications/alerts/'
      ),
    meta: {
      layout: SubLayoutCommunications,
    },
  },
  // {
  //   path: '/starter',
  //   name: 'Starter',
  //   component: () =>
  //     import(/* webpackChunkName: "starter" */ '@/views/starter/'),
  //   meta: {
  //     layout: LayoutEmpty
  //   }
  // },
  {
    path: '/*',
    name: 'NotFound',
    component: NotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  store.dispatch('pageLoading/setLoad', true)

  MKPSDK.initialize({
    apiURL: process.env.VUE_APP_MKP_SERVICE_API,
  })

  ArteBeauteSDK.initialize({
    apiURL: process.env.VUE_APP_MKP_SERVICE_API,
  })

  StorageSDK.initialize({
    apiURL: process.env.VUE_APP_STORAGE_SERVICE_API,
  })

  PassportSDK.initialize({
    apiURL: process.env.VUE_APP_PASSPORT_SERVICE_API,
  })

  StatsSDK.initialize({
    apiURL: process.env.VUE_APP_STAT_API,
  })

  CaSDK.initialize({
    apiURL: process.env.VUE_APP_STAT_API,
  })

  // TODO: mettre le changement de theme dark light suivant l heure

  if (!store.state.auth.user) {
    await store.dispatch('auth/autoLogin')
  }
  if (!store.state.auth.user) {
    if (to.path !== '/login' && !to.path.startsWith('/activate')) {
      return next('/login')
    }
  }
  // else if (
  //   !store.state.shop.loaded.shop &&
  //   !store.state.shop.loaded.abShops
  // ) {
  //   if (to.path !== '/starter') {
  //     return next('/starter')
  //   }
  // }
  axios.defaults.headers.company = store.state.auth.companyToken

  next()
})

router.afterEach(() => {
  store.dispatch('pageLoading/setLoad', false)
})

export default router
