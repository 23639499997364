import { MKPSDK } from '@/packages/artebeaute-sdk/src'
import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    postCategories: [],
    posts: []
  }),
  getters: {
    getPostCategories(state) {
      return state.postCategories
    },
    getBlogPosts(state) {
      return state.posts
    }
  },
  mutations: {
    SET_POST_CATEGORIES(state, value) {
      state.postCategories = value
    },
    ADD_POST_CATEGORY(state, value) {
      state.postCategories.push(value)
    },
    UPDATE_POST_CATEGORY(state, value) {
      const postCategoryIndex = state.postCategories.findIndex(
        c => c.id === value.id
      )

      if (postCategoryIndex !== -1) {
        Vue.set(state.postCategories, postCategoryIndex, value)
      }

      state.posts.map(post => {
        if (post.category && post.category.id === value.id) {
          const postIndex = state.posts.findIndex(p => p.id === post.id)
          Vue.set(state.posts, postIndex, { ...post, category: value })
        }
      })
    },
    DELETE_POST_CATEGORY(state, value) {
      const postCategoryIndex = state.postCategories.findIndex(
        c => c.id === value.id
      )

      if (postCategoryIndex !== -1) {
        state.postCategories.splice(postCategoryIndex, 1)
      }

      state.posts.map(post => {
        if (post.category && post.category.id === value.id) {
          const postIndex = state.posts.findIndex(p => p.id === post.id)
          Vue.set(state.posts, postIndex, { ...post, category: {} })
        }
      })
    },
    SET_POSTS(state, value) {
      state.posts = value
    },
    ADD_POST(state, value) {
      state.posts.push(value)
    },
    UPDATE_POST(state, value) {
      const postIndex = state.posts.findIndex(c => c.id === value.id)

      if (postIndex !== -1) {
        Vue.set(state.posts, postIndex, value)
      }
    },
    DELETE_POST(state, value) {
      const postIndex = state.posts.findIndex(c => c.id === value.id)

      if (postIndex !== -1) {
        state.posts.splice(postIndex, 1)
      }
    }
  },
  actions: {
    async fetchPostCategories({ commit, state }) {
      if (state.postCategories.length) {
        return state.postCategories
      }

      try {
        const response = await MKPSDK.Blog.getAllPostCategories()

        commit('SET_POST_CATEGORIES', response.postCategories)
      } catch (err) {
        return []
      }
    },
    async createPostCategory({ commit }, { data }) {
      try {
        const response = await MKPSDK.Blog.createPostCategory(data)

        commit('ADD_POST_CATEGORY', response.postCategory)
      } catch (err) {
        return null
      }
    },
    async updatePostCategory({ commit }, { postCategory, data }) {
      try {
        const response = await MKPSDK.Blog.updatePostCategory(
          postCategory.id,
          data
        )

        commit('UPDATE_POST_CATEGORY', response.postCategory)
      } catch (err) {
        return []
      }
    },
    async deletePostCategory({ commit, state }, { postCategory }) {
      try {
        const response = await MKPSDK.Blog.deletePostCategory(postCategory.id)

        state.posts.forEach((post, index) => {
          if (post.category && post.category.id === postCategory.id) {
            Vue.delete(state.posts[index], 'category')
          }
        })

        commit('DELETE_POST_CATEGORY', response.postCategory)
      } catch (err) {
        return []
      }
    },

    async fetchBlogPosts({ commit, state }) {
      if (state.posts.length) {
        return state.posts
      }

      try {
        const response = await MKPSDK.Blog.getAllPosts()

        commit('SET_POSTS', response.posts)
      } catch (err) {
        return []
      }
    },
    async createBlogPost({ commit }, { data }) {
      try {
        const response = await MKPSDK.Blog.createPost(data)

        commit('ADD_POST', response.post)
      } catch (err) {
        return null
      }
    },
    async updateBlogPost({ commit }, { post, data }) {
      try {
        const response = await MKPSDK.Blog.updatePost(post.id, data)

        const responseCat = await MKPSDK.Blog.getAllPostCategories()

        commit('UPDATE_POST', response.post)
        commit('SET_POST_CATEGORIES', responseCat.postCategories)
      } catch (err) {
        return []
      }
    },
    async deleteBlogPost({ commit }, { post }) {
      try {
        const response = await MKPSDK.Blog.deletePost(post.id)

        commit('DELETE_POST', response.post)
      } catch (err) {
        return []
      }
    }
  }
}
