<template>
  <div class="d-flex flex-column justify-center align-center">
    <img src="@/assets/notfound.png" alt="Not Found" height="500" />
    <h1>Page introuvable</h1>
    <p>Cette page est introuvable.</p>
    <div class="mt-6">
      <v-btn color="primary" to="/">Retourner sur le Tableau de Bord</v-btn>
    </div>
  </div>
</template>

<script>
// import Scaffold from '@/components/general/Scaffold'

export default {
  name: 'NotFoundPage'
  // components: {
  //   Scaffold
  // }
}
</script>
