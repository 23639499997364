import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getAllAbArticles(type = null) {
    try {
      let requestURL = config.apiURL + '/api/articles/artebeaute'

      if (type) {
        requestURL += '?type=' + type
      }

      const response = await axios.get(requestURL)

      return {
        articles: response.data.articles
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getAllArticles() {
    try {
      const response = await axios.get(config.apiURL + '/api/articles')

      return {
        articles: response.data.articles
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getArticle(id) {
    try {
      const response = await axios.get(config.apiURL + '/api/articles/' + id)

      return {
        article: response.data.article
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getArticleBySlug(slug) {
    try {
      const response = await axios.get(
        config.apiURL + '/api/articles/slug/' + slug
      )

      return {
        article: response.data.article
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async createArticle(data) {
    try {
      const response = await axios.post(config.apiURL + '/api/articles', data)

      return {
        article: response.data.article
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async updateArticle(id, data) {
    try {
      const response = await axios.put(
        config.apiURL + '/api/articles/' + id,
        data
      )

      return {
        article: response.data.article
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async updateOrders(data) {
    try {
      await axios.put(config.apiURL + '/api/articles/sort', data)

      return {
        success: true
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async deleteArticle(id) {
    try {
      const response = await axios.delete(config.apiURL + '/api/articles/' + id)

      return {
        article: response.data.article
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async undraftArticle(id) {
    try {
      const response = await axios.delete(
        config.apiURL + '/api/articles/' + id + '/draft'
      )

      return {
        article: response.data.article
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async draftArticle(id) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/articles/' + id + '/draft'
      )

      return {
        article: response.data.article
      }
    } catch (err) {
      throw createError(err)
    }
  }
}
