<template>
  <layout-default :links="links">
    <slot />
  </layout-default>
</template>

<script>
import layoutDefault from '../layout-default.vue'

export default {
  components: { layoutDefault },
  computed: {
    links() {
      return [
        {
          id: 1,
          label: this.$t('layout.dashboard'),
          to: '/booker',
        },
        {
          id: 2,
          label: this.$t('booker.transactions'),
          to: '/booker/transactions',
        },
        {
          id: 3,
          label: this.$t('settings.siteSettings.gift'),
          to: '/booker/gifts',
        },
        {
          id: 4,
          label: this.$t('layout.discounts'),
          to: '/booker/remises',
        },
      ]
    },
  },
}
</script>

<style></style>
