// import { ArteBeauteSDK } from '@/packages/artebeaute-sdk/src'
// import slugify from 'slugify'
// import Vue from 'vue'
// import store from '../index'
// import axiosErrorCode from '@/helpers/axios'

export default {
  namespaced: true,
  state: () => ({
    abBooking: {
      appointments: [],
      pages: {},
      lastResult: Date.now()
    },
    mutations: {},
    actions: {}
  })
}
