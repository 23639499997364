import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getAllCoupons(type = 'current', page = 1) {
    try {
      const response = await axios.get(config.apiURL + '/api/coupons', {
        params: {
          type,
          page,
        },
      })

      return {
        coupons: response.data.coupons,
        perPage: response.data.perPage,
        page: response.data.page,
        nbCoupons: response.data.nbCoupons,
      }
    } catch (err) {
      throw createError(err)
    }
  },

  async getConflicts(dateA, dateB, auto) {
    try {
      const response = await axios.get(
        config.apiURL +
          '/api/coupons/conflict/' +
          dateA +
          '/' +
          dateB +
          '/' +
          (auto ? 1 : 0)
      )

      return {
        coupons: response.data.coupons,
      }
    } catch (err) {
      throw createError(err)
    }
  },

  async getCoupon(id) {
    try {
      const response = await axios.get(config.apiURL + '/api/coupons/' + id)

      return {
        coupon: response.data.coupon,
      }
    } catch (err) {
      throw createError(err)
    }
  },

  async createCoupon(data) {
    const response = await axios.post(config.apiURL + '/api/coupons', data)

    return {
      coupon: response.data.coupon,
    }
  },

  async updateCoupon(couponId, data) {
    const response = await axios.put(
      config.apiURL + '/api/coupons/' + couponId,
      data
    )

    return {
      coupon: response.data.coupon,
    }
  },

  async applyCoupon(token, data) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/coupons/appply',
        data
      )

      return {
        coupon: response.data.coupon,
      }
    } catch (err) {
      throw createError(err)
    }
  },

  // async updateEvent(id, data) {
  //   try {
  //     const response = await axios.put(
  //       config.apiURL + '/api/events/' + id,
  //       data
  //     )
  //
  //     return {
  //       event: response.data.event
  //     }
  //   } catch (err) {
  //     throw createError(err)
  //   }
  // },

  async deleteCoupon(id) {
    try {
      const response = await axios.delete(config.apiURL + '/api/coupons/' + id)

      return {
        coupon: response.data.coupon,
      }
    } catch (err) {
      throw createError(err)
    }
  },
}
