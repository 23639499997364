import i18n from '../i18n'

export const icones = {
  account: {
    title: i18n.t('icons.userAccounts'),
    icon: 'mdi-account',
    color: 'grey',
    toolTip: i18n.t('icons.userAccounts'),
    custom: false
  },
  accountGroup: {
    title: i18n.t('icons.usersList'),
    icon: 'mdi-account-group',
    color: 'grey',
    toolTip: i18n.t('icons.usersList'),
    custom: false
  },
  new: {
    title: i18n.t('icons.new'),
    icon: 'mdi-plus-thick',
    color: 'blue',
    toolTip: i18n.t('icons.new'),
    custom: false
  },
  edit: {
    title: i18n.t('icons.edit'),
    icon: 'mdi-pencil-box-outline',
    color: 'green',
    toolTip: i18n.t('icons.edit'),
    custom: false
  },
  close: {
    title: i18n.t('icons.close'),
    icon: 'mdi-close-circle',
    color: 'grey',
    toolTip: i18n.t('icons.close'),
    custom: false
  },
  delete: {
    title: i18n.t('icons.delete'),
    icon: 'mdi-delete',
    color: 'grey',
    toolTip: i18n.t('icons.delete'),
    custom: false
  },
  submit: {
    title: i18n.t('icons.validate'),
    icon: 'mdi-check',
    color: 'green',
    toolTip: i18n.t('icons.validate'),
    custom: false
  },
  cancel: {
    title: i18n.t('icons.cancel'),
    icon: 'mdi-cancel',
    color: 'grey',
    toolTip: i18n.t('icons.cancel'),
    custom: false
  },
  all: {
    title: i18n.t('icons.all'),
    icon: 'mdi-all-inclusive',
    color: 'teal',
    toolTip: i18n.t('icons.all'),
    custom: false
  },
  shop: {
    title: i18n.t('icons.shop'),
    icon: 'mdi-store',
    color: 'blue',
    toolTip: i18n.t('icons.shop'),
    custom: false
  },
  resource: {
    title: i18n.t('icons.resources'),
    icon: 'mdi-account-box-multiple',
    color: 'green',
    toolTip: i18n.t('icons.resourcesList'),
    custom: false
  },
  geoGroupement: {
    title: i18n.t('icons.geoGroups'),
    icon: 'mdi-shape',
    color: 'blue-grey darken-1',
    toolTip: i18n.t('icons.geoGroupsList'),
    custom: false
  },
  categories: {
    title: i18n.t('icons.categories'),
    icon: 'mdi-family-tree',
    color: 'orange darken-4',
    toolTip: i18n.t('icons.categoriesList'),
    custom: false
  },
  blogCategory: {
    title: i18n.t('icons.categories'),
    icon: 'mdi-family-tree',
    color: 'orange darken-4',
    toolTip: i18n.t('icons.categories'),
    custom: false
  },
  access: {
    title: i18n.t('icons.access'),
    icon: 'mdi-key',
    color: 'orange darken-4',
    toolTip: i18n.t('icons.accessList'),
    custom: false
  },
  article: {
    title: i18n.t('icons.article'),
    icon: 'mdi-table-edit',
    color: 'orange darken-4',
    toolTip: i18n.t('icons.articlesList'),
    custom: false
  },
  booker: {
    title: 'Booker',
    icon: 'mdi-clock-outline',
    color: 'blue',
    toolTip: i18n.t('icons.onlineBooking'),
    custom: false
  },
  passbeaute: {
    title: 'Pass Beauté',
    icon: 'mdi-passport-biometric',
    color: 'green',
    toolTip: i18n.t('icons.managementCustomersConsents'),

    custom: false
  },
  onLine: {
    title: i18n.t('icons.online'),
    icon: 'mdi-earth',
    color: 'green',
    toolTip: i18n.t('icons.shopReadyGoOnline'),

    custom: false
  },
  draft: {
    title: i18n.t('icons.offline'),
    icon: 'mdi-earth',
    color: 'orange',
    toolTip: i18n.t('icons.shopReadyGoOnline'),

    custom: false
  },
  offLine: {
    title: i18n.t('icons.offline'),
    icon: 'mdi-earth-off',
    color: 'grey',
    toolTip: i18n.t('icons.shopNotPutOnline'),

    custom: false
  },
  link: {
    title: i18n.t('icons.linked'),
    icon: 'mdi-link',
    color: 'green',
    toolTip: i18n.t('icons.elementLinked'),

    custom: false
  },
  unLink: {
    title: i18n.t('icons.notLinked'),
    icon: 'mdi-link',
    color: 'grey',
    toolTip: i18n.t('icons.elementNotLinked'),

    custom: false
  },
  dataBase: {
    title: i18n.t('icons.dataBase'),
    icon: 'mdi-database',
    color: 'white',
    toolTip: i18n.t('icons.managementArticlesShops'),

    custom: false
  },
  chevronDown: {
    title: i18n.t('icons.goDown'),
    icon: 'mdi-chevron-down',
    color: 'rgb(50,50,50)',
    toolTip: i18n.t('icons.goDown'),
    custom: false
  },
  chevronUp: {
    title: i18n.t('icons.goUp'),
    icon: 'mdi-chevron-up',
    color: 'rgb(50,50,50)',
    toolTip: i18n.t('icons.goUp'),
    custom: false
  }
}

export default function() {
  return {
    icones: {
      account: {
        title: i18n.t('icons.userAccounts'),
        icon: 'mdi-account',
        color: 'grey',
        toolTip: i18n.t('icons.userAccounts'),
        custom: false
      },
      accountGroup: {
        title: i18n.t('icons.usersList'),
        icon: 'mdi-account-group',
        color: 'grey',
        toolTip: i18n.t('icons.usersList'),
        custom: false
      },
      new: {
        title: i18n.t('icons.new'),
        icon: 'mdi-plus-thick',
        color: 'blue',
        toolTip: i18n.t('icons.new'),
        custom: false
      },
      edit: {
        title: i18n.t('icons.edit'),
        icon: 'mdi-pencil-box-outline',
        color: 'green',
        toolTip: i18n.t('icons.edit'),
        custom: false
      },
      close: {
        title: i18n.t('icons.close'),
        icon: 'mdi-close-circle',
        color: 'grey',
        toolTip: i18n.t('icons.close'),
        custom: false
      },
      delete: {
        title: i18n.t('icons.delete'),
        icon: 'mdi-delete',
        color: 'grey',
        toolTip: i18n.t('icons.delete'),
        custom: false
      },
      submit: {
        title: i18n.t('icons.validate'),
        icon: 'mdi-check',
        color: 'green',
        toolTip: i18n.t('icons.validate'),
        custom: false
      },
      cancel: {
        title: i18n.t('icons.cancel'),
        icon: 'mdi-cancel',
        color: 'grey',
        toolTip: i18n.t('icons.cancel'),
        custom: false
      },
      all: {
        title: i18n.t('icons.all'),
        icon: 'mdi-all-inclusive',
        color: 'teal',
        toolTip: i18n.t('icons.all'),
        custom: false
      },
      shop: {
        title: i18n.t('icons.shop'),
        icon: 'mdi-store',
        color: 'blue',
        toolTip: i18n.t('icons.shop'),
        custom: false
      },
      resource: {
        title: i18n.t('icons.resources'),
        icon: 'mdi-account-box-multiple',
        color: 'green',
        toolTip: i18n.t('icons.resourcesList'),
        custom: false
      },
      geoGroupement: {
        title: i18n.t('icons.geoGroups'),
        icon: 'mdi-shape',
        color: 'blue-grey darken-1',
        toolTip: i18n.t('icons.geoGroupsList'),
        custom: false
      },
      categories: {
        title: i18n.t('icons.categories'),
        icon: 'mdi-family-tree',
        color: 'orange darken-4',
        toolTip: i18n.t('icons.categoriesList'),
        custom: false
      },
      blogCategory: {
        title: i18n.t('icons.categories'),
        icon: 'mdi-family-tree',
        color: 'orange darken-4',
        toolTip: i18n.t('icons.categories'),
        custom: false
      },
      access: {
        title: i18n.t('icons.access'),
        icon: 'mdi-key',
        color: 'orange darken-4',
        toolTip: i18n.t('icons.accessList'),
        custom: false
      },
      article: {
        title: i18n.t('icons.article'),
        icon: 'mdi-table-edit',
        color: 'orange darken-4',
        toolTip: i18n.t('icons.articlesList'),
        custom: false
      },
      booker: {
        title: 'Booker',
        icon: 'mdi-clock-outline',
        color: 'blue',
        toolTip: i18n.t('icons.onlineBooking'),
        custom: false
      },
      passbeaute: {
        title: 'Pass Beauté',
        icon: 'mdi-passport-biometric',
        color: 'green',
        toolTip: i18n.t('icons.managementCustomersConsents'),

        custom: false
      },
      onLine: {
        title: i18n.t('icons.online'),
        icon: 'mdi-earth',
        color: 'green',
        toolTip: i18n.t('icons.shopReadyGoOnline'),

        custom: false
      },
      draft: {
        title: i18n.t('icons.draft'),
        icon: 'mdi-note-edit',
        color: 'brown',
        toolTip: i18n.t('icons.draft'),

        custom: false
      },
      offLine: {
        title: i18n.t('icons.offline'),
        icon: 'mdi-earth-off',
        color: 'grey',
        toolTip: i18n.t('icons.shopNotPutOnline'),

        custom: false
      },
      link: {
        title: i18n.t('icons.linked'),
        icon: 'mdi-link',
        color: 'green',
        toolTip: i18n.t('icons.elementLinked'),

        custom: false
      },
      unLink: {
        title: i18n.t('icons.notLinked'),
        icon: 'mdi-link',
        color: 'grey',
        toolTip: i18n.t('icons.elementNotLinked'),

        custom: false
      },
      dataBase: {
        title: i18n.t('icons.dataBase'),
        icon: 'mdi-database',
        color: 'white',
        toolTip: i18n.t('icons.managementArticlesShops'),

        custom: false
      },
      chevronDown: {
        title: i18n.t('icons.goDown'),
        icon: 'mdi-chevron-down',
        color: 'rgb(50,50,50)',
        toolTip: i18n.t('icons.goDown'),
        custom: false
      },
      chevronUp: {
        title: i18n.t('icons.goUp'),
        icon: 'mdi-chevron-up',
        color: 'rgb(50,50,50)',
        toolTip: i18n.t('icons.goUp'),
        custom: false
      }
    },
    getIcones() {
      return this.icones
    }
  }
}
