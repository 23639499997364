import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getAllCarts() {
    try {
      const response = await axios.get(config.apiURL + '/api/carts')

      return response.data
    } catch (err) {
      throw createError(err)
    }
  },
  async getAllFailedCarts() {
    try {
      const response = await axios.get(config.apiURL + '/api/carts/failed')

      return response.data
    } catch (err) {
      throw createError(err)
    }
  },
  async getCart(id) {
    try {
      const response = await axios.get(config.apiURL + '/api/carts/' + id)

      return response.data
    } catch (err) {
      throw createError(err)
    }
  },
  async resolve(id) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/carts/resolve/' + id
      )

      return response.data
    } catch (err) {
      throw createError(err)
    }
  },
}
