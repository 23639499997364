import { render, staticRenderFns } from "./simpleModal.vue?vue&type=template&id=283ca0bc&"
import script from "./simpleModal.vue?vue&type=script&lang=js&"
export * from "./simpleModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports