<template>
<v-app>
  <v-app-bar app dark fixed>
    <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    <v-app-bar-title>
      <span class="primary--text">Statistiques</span>
    </v-app-bar-title>
    <v-spacer></v-spacer>
    <v-btn icon>
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-app-bar>
  <v-navigation-drawer dark permanent app :mini-variant="drawer">
    <v-card color="transparent" flat tile>
      <v-list-item dark>
        <v-list-item-content v-if="!drawer">
          <v-list-item-title class="text-h6">
            {{ menuCurrent.title }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon v-else>
          <v-icon>mdi-finance</v-icon>
        </v-list-item-icon>
      </v-list-item>

      <v-list
          dense nav>
        <v-list-item dense v-for="item in menuItems" :key="item.title" link @click="menuCurrent = item">
          <template v-if="item.type==='module'">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-else>
            <v-divider/>
          </template>

        </v-list-item>
      </v-list>
    </v-card>

  </v-navigation-drawer>
  <v-main style="margin: 20px" class="bodyColor">
    <slot  />
  </v-main>
</v-app>
</template>

<script>
export default {
  name: "layout-statnew",
  data:()=>{ return{
    drawer: true,
    menuItems: [
      {title: 'Tableau de bord', icon: 'mdi-view-dashboard', name: 'DashBoard', type: 'module'},
      {type: 'line'},
      {title: 'Encaissement ( flux )', icon: 'mdi-realized-multiple', name: 'Encaissement', type: 'module'},
      {type: 'line'},
      {title: 'Rendez-Vous', icon: 'mdi-clock-outline', name: 'appointementRepartition', type: 'module'},
    ],
  }}
}
</script>

<style scoped>
.bodyColor {
  background-color: #ededee;
}
</style>