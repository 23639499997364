import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getTransaction(id) {
    try {
      const response = await axios.get(
        config.apiURL + '/api/transactions/' + id
      )
      return {
        transaction: response.data.transaction
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getScenario(data) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/payment/get-scenario',
        data
      )
      return {
        transaction: response.data
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getToken(data) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/payment/token',
        data
      )

      return {
        token: response.data.token
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async processPayment(data, companyToken) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/payment/success/' + companyToken,
        data
      )

      return {
        success: response.data.success
      }
    } catch (err) {
      throw createError(err)
    }
  },
  // Todo : Deprecated plus utilisé à enlever dans le code
  async refundPayzen(data) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/payment/payzen/refund',
        data
      )

      return {
        result: response.data
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async refund(idEorder, data) {
    try {
      const response = await axios
        .post(config.apiURL + '/api/payment/refund?eorderId=' + idEorder, data)
        .then(response => response.data)

      return response.data.result
    } catch (err) {
      throw createError(err)
    }
  },
  async refundGift(data) {
    try {
      const response = await axios
        .post(config.apiURL + '/api/payment/refund', data)
        .then(response => response.data)

      return response.data.result
    } catch (err) {
      throw createError(err)
    }
  },
  async appointmentDelete(params) {
    try {
      const requestURL = config.apiURL + '/api/appointments'

      const response = await axios.delete(requestURL, {
        params
      })
      return { data: response.data }
    } catch (e) {
      throw createError(e)
    }
  }
}
