export default {
  namespaced: true,
  state: () => ({
    isLoad: false,
    ready: false
  }),
  getters: {
    isLoad: state => state.isLoad
  },
  mutations: {
    SET_LOAD(state, value) {
      state.isLoad = value
    },
    SET_READY(state, value) {
      state.ready = value
    }
  },
  actions: {
    async setLoad({ commit }, value) {
      try {
        commit('SET_LOAD', value)
      } catch (err) {
        return []
      }
    },
    setReady({ commit }, value) {
      commit('SET_READY', value)
    }
  }
}
