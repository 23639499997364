import axios from 'axios'
import { createError } from '../../artebeaute/helpers/errors'
import config from '../config'

export default {
  async advisePayments(query) {
    try {
      const response = await axios.get(config.apiURL + '/ca/advise/payments', {
        params: query
      })
      return {
        advisePayments: response.data.advisePayment
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async advisePaymentDetail(query) {
    try {
      const response = await axios.get(
        config.apiURL + '/ca/advise/payments/details',
        {
          params: query
        }
      )
      return {
        advisePayments: response.data
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async adviseAddPayment(body) {
    try {
      const response = await axios.post(
        config.apiURL + '/ca/advise/payments/advise',
        body
      )
      return {
        adviseIdPayment: response.data
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async adviseAddLines(body) {
    try {
      const response = await axios.post(
        config.apiURL + '/ca/advise/payments/advise/lines',
        body
      )
      return {
        adviseLines: response.data
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async adviseAddDeposit(body) {
    try {
      const response = await axios.post(
        config.apiURL + '/ca/advise/payments/advise/deposit/add',
        body
      )
      return {
        deposit: response.data
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async adviseUpdateDeposit(body) {
    try {
      const response = await axios.put(
        config.apiURL + '/ca/advise/payments/advise/deposit/update',
        body
      )
      return {
        deposit: response.data
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async adviseRemainingDeposit(query) {
    try {
      const response = await axios.get(
        config.apiURL + '/ca/advise/payments/details/deposit/remaining',
        {
          params: query
        }
      )
      return {
        adviseRemainingDeposit: response.data
      }
    } catch (err) {
      throw createError(err)
    }
  }
}
