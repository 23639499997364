import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async isInstalled() {
    try {
      const response = await axios.get(config.apiURL + '/api/admins/installed')

      return {
        installed: response.data.installed
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async createAdmin(data) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/admins/register',
        data
      )

      return {
        user: response.data.user
      }
    } catch (err) {
      throw createError(err)
    }
  }
}
