import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'
export default {
  async shopsBookerPayment(start, end) {
    try {
      const response = await axios.get(
        config.apiURL + '/bookingShops/payment?start=' + start + '&end=' + end
      )
      return { payment: response.data }
    } catch (err) {
      throw createError(err)
    }
  },
  async shopsGiftPayment(start, end) {
    try {
      const response = await axios.get(
        config.apiURL + '/giftShops/payment?start=' + start + '&end=' + end
      )
      return { payment: response.data }
    } catch (err) {
      throw createError(err)
    }
  }
}
