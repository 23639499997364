import axios from 'axios'
import router from '../router'
import Cookies from 'universal-cookie'
import { MKPSDK } from '@/packages/artebeaute-sdk/src'
import i18n from '../i18n'

const cookies = new Cookies()

export default {
  namespaced: true,
  state: () => ({
    token: null,
    user: null,
    company: null,
    companyToken: null,
    users: null,
  }),

  mutations: {
    SET_COMPANY(state, value) {
      state.company = value
    },
    SET_USERS(state, value) {
      state.users = value
    },
    SET_COMPANYTOKEN(state, value) {
      state.companyToken = value
    },
    SET_TOKEN(state, value) {
      state.token = value

      if (value) {
        localStorage.setItem('auth.token', value)
      } else {
        localStorage.removeItem('auth.token')
      }

      if (value) {
        cookies.set('auth.token', value, { path: '/' })
      } else {
        cookies.remove('auth.token', { path: '/' })
      }
    },
    SET_USER(state, value) {
      state.user = value
    },
  },
  actions: {
    async updateCompany({ dispatch }, company) {
      try {
        await MKPSDK.Companies.updateCompany(company.id, company.data)

        dispatch('fetchCompany')
      } catch (err) {
        console.log('err :>> ', err)
      }
    },
    async users({ commit }) {
      try {
        const response = await MKPSDK.CmpUsers.getAllCmpUsers()
        commit('SET_USERS', response.users)
      } catch (err) {
        console.log('impossible de charger les comptes')
      }
    },
    async login({ commit, dispatch }, token) {
      try {
        commit('SET_TOKEN', token)

        axios.defaults.headers.authorization = 'Bearer ' + token

        let response = null
        let user = null

        try {
          response = await MKPSDK.CmpUsers.me(this.token)
          user = response.user
        } catch (err) {
          dispatch('logout')

          return false
        }

        commit('SET_USER', user)

        const resp = await MKPSDK.Companies.getToken(
          response.user.company.nickname
        )

        axios.defaults.headers.company = resp.token

        const meCompResp = await MKPSDK.Companies.me()

        commit('SET_COMPANYTOKEN', resp.token)
        commit('SET_COMPANY', meCompResp.company)

        return {
          token,
          user,
        }
      } catch (err) {
        console.log('err :>> ', err)
        return false
      }
    },
    async autoLogin({ commit, dispatch }) {
      try {
        let token = null

        const urlSearchParams = new URLSearchParams(window.location.search)
        let isAdminLogin = false

        if (urlSearchParams.has('adminLogin')) {
          token = urlSearchParams.get('adminLogin')
          isAdminLogin = true
        }

        if (!token) {
          token = localStorage.getItem('auth.token')
        }

        if (!token) {
          token = cookies.get('auth.token')
        }

        if (!token) {
          dispatch('logout')

          return false
        }

        commit('SET_TOKEN', token)

        axios.defaults.headers.authorization = 'Bearer ' + token

        let response = null
        let user = null

        try {
          response = await MKPSDK.CmpUsers.me(this.token)
          user = response.user
        } catch (err) {
          dispatch('logout')

          return false
        }

        commit('SET_USER', user)

        const resp = await MKPSDK.Companies.getToken(
          response.user.company.nickname
        )

        axios.defaults.headers.company = resp.token

        const meCompResp = await MKPSDK.Companies.me()

        commit('SET_COMPANYTOKEN', resp.token)
        commit('SET_COMPANY', meCompResp.company)

        if (isAdminLogin) {
          router.push('/')
        }

        return {
          token,
          user,
        }
      } catch (err) {
        console.log('err :>> ', err)
        return false
      }
    },
    async fetchCompany({ commit }) {
      const meCompResp = await MKPSDK.Companies.me()

      commit('SET_COMPANY', meCompResp.company)
    },
    logout({ commit, state }) {
      let refresh = false

      if (state.user) {
        refresh = true
      }

      commit('SET_TOKEN', null)
      commit('SET_USER', null)
      commit('SET_COMPANYTOKEN', null)
      commit('SET_COMPANY', null)

      if (refresh) {
        location.reload()
      }
    },
  },
  getters: {
    getShopUserStat(state, getters, rootState, rootGetters) {
      if (state.user.role === 'cmpadmin') {
        return -1
      } else {
        const shopStates = state.user.aclStatsShops
        return shopStates.reduce((prev, cur) => {
          const myIds = rootGetters['shop/abShopIdById'](cur)
          if (myIds !== -1) {
            prev.push(myIds)
          }
          return prev
        }, [])
      }
    },
    getEmptySettings(state) {
      let isEmpty = {
        identite: 0,
        site: 0,
        reservation: 0,
        gift: 0,
        email: 0,
        paiement: 0,
        remboursement: 0,
        connexion: 0,
        legals: 0,
        social: 0,
      }

      if (!state.company || !state.company.themes) {
        isEmpty.identite++
        return isEmpty
      }

      //IDENTITE
      isEmpty.identite = 0
      if (state.company.identity && !state.company.identity.displayName) {
        isEmpty.identite++
      }

      //SITE
      isEmpty.site = 0
      if (
        state.company.booker &&
        state.company.booker.favicon &&
        !state.company.booker.favicon.title
      ) {
        isEmpty.site++
      }
      if (
        state.company.booker &&
        state.company.booker.favicon &&
        (!state.company.booker.favicon.image ||
          !state.company.booker.favicon.image.url)
      ) {
        isEmpty.site++
      }
      if (
        state.company.identity &&
        state.company.identity.logo &&
        state.company.identity.logoSize === 75
      ) {
        isEmpty.site++
      }

      //RESA
      isEmpty.reservation = 0
      if (
        state.company.booker &&
        state.company.booker.emailCopyToList &&
        !state.company.booker.emailCopyList
      ) {
        isEmpty.reservation++
      }

      //GIFT
      isEmpty.gift = 0
      if (
        state.company.booker &&
        state.company.booker.gift &&
        (!state.company.booker.gifts ||
          !state.company.booker.gifts.themes.length)
      ) {
        isEmpty.gift++
      }
      if (
        state.company.booker &&
        state.company.booker.gift &&
        (!state.company.booker.gifts ||
          !state.company.booker.gifts.bcValues.length)
      ) {
        isEmpty.gift++
      }
      if (
        state.company.booker &&
        state.company.booker.giftEmailCopyToList &&
        !state.company.booker.giftEmailCopyList
      ) {
        isEmpty.gift++
      }

      //EMAIL
      isEmpty.email = 0
      if (state.company.mails) {
        Object.keys(state.company.mails).forEach((key) => {
          if (!state.company.mails[key][i18n.locale]) {
            isEmpty.email++
          }
        })
      }

      //PAIEMENT
      isEmpty.paiement = 0
      if (state.company.booker && state.company.booker.payment) {
        if (state.company.booker.payment.provider === 'payzen') {
          if (!state.company.booker.payment.keys.publicKeyPayzen) {
            isEmpty.paiement++
          }
          if (!state.company.booker.payment.keys.user) {
            isEmpty.paiement++
          }
          if (!state.company.booker.payment.keys.password) {
            isEmpty.paiement++
          }
          if (!state.company.booker.payment.keys.decryptKey) {
            isEmpty.paiement++
          }
          if (!state.company.booker.payment.keys.baseURL) {
            isEmpty.paiement++
          }
        } else if (state.company.booker.payment.provider === 'stripe') {
          if (!state.company.booker.payment.keys.publicKeyStripe) {
            isEmpty.paiement++
          }
          if (!state.company.booker.payment.keys.privateKey) {
            isEmpty.paiement++
          }
        } else if (state.company.booker.payment.provider === 'saferpay') {
          if (!state.company.booker.payment.keys.customerSaferpay) {
            isEmpty.paiement++
          }
          if (!state.company.booker.payment.keys.terminalSaferpay) {
            isEmpty.paiement++
          }
          if (!state.company.booker.payment.keys.userSaferpay) {
            isEmpty.paiement++
          }
          if (!state.company.booker.payment.keys.passwordSaferpay) {
            isEmpty.paiement++
          }
          if (!state.company.booker.payment.keys.baseURLSaferpay) {
            isEmpty.paiement++
          }
        }
      }

      //REMBOURSEMENT
      isEmpty.remboursement = 0

      if (
        state.company.booker &&
        state.company.booker.textLegals &&
        !state.company.booker.textLegals.canceling[i18n.locale]
      ) {
        isEmpty.remboursement++
      }

      //CONNEXION
      isEmpty.connexion = 0
      if (
        state.company.booker &&
        state.company.booker.alertMessages &&
        state.company.booker.alertMessages.connexion &&
        state.company.booker.alertMessages.connexion.active &&
        !state.company.booker.alertMessages.connexion.message[i18n.locale]
      ) {
        isEmpty.connexion++
      }
      if (
        state.company.booker &&
        state.company.booker.alertMessages &&
        state.company.booker.alertMessages.registration &&
        state.company.booker.alertMessages.registration.active &&
        !state.company.booker.alertMessages.registration.message[i18n.locale]
      ) {
        isEmpty.connexion++
      }

      //LEGALS
      isEmpty.legals = 0
      if (
        state.company.booker &&
        state.company.booker.textLegals &&
        !state.company.booker.textLegals.rgpd[i18n.locale]
      ) {
        isEmpty.legals++
      }
      if (
        state.company.booker &&
        state.company.booker.textLegals &&
        !state.company.booker.textLegals.mentions[i18n.locale]
      ) {
        isEmpty.legals++
      }
      if (
        state.company.booker &&
        state.company.booker.textLegals &&
        !state.company.booker.textLegals.cgv[i18n.locale]
      ) {
        isEmpty.legals++
      }
      if (
        state.company.booker &&
        state.company.booker.textLegals &&
        !state.company.booker.textLegals.cgu[i18n.locale]
      ) {
        isEmpty.legals++
      }
      if (
        state.company.booker &&
        state.company.booker.textLegals &&
        !state.company.booker.textLegals.cookies[i18n.locale]
      ) {
        isEmpty.legals++
      }

      //SOCIAL
      isEmpty.social = 0
      if (state.company.identity && state.company.identity.socialNetworks) {
        Object.keys(state.company.identity.socialNetworks).forEach((key) => {
          if (
            state.company.identity.socialNetworks[key].active &&
            !state.company.identity.socialNetworks[key].url
          ) {
            isEmpty.social++
          }
        })
      }

      return isEmpty
    },
  },
}
