import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getAllAlerts() {
    try {
      const response = await axios.get(config.apiURL + '/api/alerts')

      return {
        alerts: response.data.alerts
      }
    } catch (err) {
      throw createError(err)
    }
  },

  async createAlert(data) {
    try {
      const response = await axios.post(config.apiURL + '/api/alerts', data)

      return {
        alert: response.data.alert
      }
    } catch (err) {
      throw createError(err)
    }
  },

  async updateAlert(id, data) {
    try {
      const response = await axios.put(
        config.apiURL + '/api/alerts/' + id,
        data
      )

      return {
        alert: response.data.alert
      }
    } catch (err) {
      throw createError(err)
    }
  },

  async deleteAlert(id) {
    try {
      const response = await axios.delete(config.apiURL + '/api/alerts/' + id)

      return {
        alert: response.data.alert
      }
    } catch (err) {
      throw createError(err)
    }
  }
}
