import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getAllPosts() {
    try {
      const response = await axios.get(config.apiURL + '/api/posts')

      return {
        posts: response.data.posts
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getPost(id) {
    try {
      const response = await axios.get(config.apiURL + '/api/posts/' + id)

      return {
        post: response.data.post
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getPostBySlug(slug) {
    try {
      const response = await axios.get(
        config.apiURL + '/api/posts/slug/' + slug
      )

      return {
        post: response.data.post
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async createPost(data) {
    try {
      const response = await axios.post(config.apiURL + '/api/posts', data)

      return {
        post: response.data.post
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async updatePost(id, data) {
    try {
      const response = await axios.put(config.apiURL + '/api/posts/' + id, data)

      return {
        post: response.data.post
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async deletePost(id) {
    try {
      const response = await axios.delete(config.apiURL + '/api/posts/' + id)

      return {
        post: response.data.post
      }
    } catch (err) {
      throw createError(err)
    }
  }
}
