import { PassportSDK } from '@/packages/artebeaute-sdk/src'
import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    publicCollectionSchemas: [],
    collectionSchemas: [],
    collectionItems: {}
  }),
  getters: {
    getPublicCollectionSchemas(state) {
      return state.publicCollectionSchemas
    },
    getCollectionSchemas(state) {
      return state.collectionSchemas
    }
  },
  mutations: {
    SET_PUBLIC_COLLECTION_SCHEMAS(state, value) {
      state.publicCollectionSchemas = value
    },
    SET_COLLECTION_SCHEMAS(state, value) {
      state.collectionSchemas = value
    },
    ADD_COLLECTION_SCHEMA(state, value) {
      state.collectionSchemas.push(value)
    },
    UPDATE_COLLECTION_SCHEMA(state, value) {
      const collectionSchemaIndex = state.collectionSchemas.findIndex(
        cs => cs.id === value.id
      )

      if (collectionSchemaIndex !== -1) {
        Vue.set(state.collectionSchemas, collectionSchemaIndex, value)
      }
    },
    DELETE_COLLECTION_SCHEMA(state, value) {
      const collectionSchemaIndex = state.collectionSchemas.findIndex(
        cs => cs.id === value.id
      )

      if (collectionSchemaIndex !== -1) {
        state.collectionSchemas.splice(collectionSchemaIndex, 1)
      }
    },
    SET_COLLECTION_ITEMS(state, { value, type }) {
      if (!state.collectionItems[type]) {
        Vue.set(state.collectionItems, type, [])
      }

      state.collectionItems[type] = value
    },
    ADD_COLLECTION_ITEM(state, { type, value }) {
      state.collectionItems[type].push(value)
    },
    UPDATE_COLLECTION_ITEM(state, { type, value }) {
      const collectionItemIndex = state.collectionItems[type].findIndex(
        ci => ci.id === value.id
      )

      if (collectionItemIndex !== -1) {
        Vue.set(state.collectionItems[type], collectionItemIndex, value)
      }
    },
    DELETE_COLLECTION_ITEM(state, { type, value }) {
      const collectionItemIndex = state.collectionItems[type].findIndex(
        ci => ci.id === value.id
      )

      if (collectionItemIndex !== -1) {
        state.collectionItems[type].splice(collectionItemIndex, 1)
      }
    }
  },
  actions: {
    async fetchPublicCollectionSchemas({ commit, state }) {
      if (state.publicCollectionSchemas.length) {
        return state.publicCollectionSchemas
      }

      try {
        const response = await PassportSDK.Collections.getAllPublicCollectionSchemas()

        commit('SET_PUBLIC_COLLECTION_SCHEMAS', response.collectionSchemas)
      } catch (err) {
        return []
      }
    },
    async fetchCollectionSchemas({ commit, state }) {
      if (state.collectionSchemas.length) {
        return state.collectionSchemas
      }

      try {
        const response = await PassportSDK.Collections.getAllCollectionSchemas()

        commit('SET_COLLECTION_SCHEMAS', response.collectionSchemas)
      } catch (err) {
        return []
      }
    },
    async publishCollectionSchema({ commit }, publicCollectionSchema) {
      try {
        const response = await PassportSDK.Collections.createCollectionSchema({
          name: publicCollectionSchema.name,
          type: publicCollectionSchema.type,
          fields: publicCollectionSchema.fields
        })

        commit('ADD_COLLECTION_SCHEMA', response.collectionSchema)
      } catch (err) {
        return []
      }
    },
    async unpublishCollectionSchema({ commit }, collectionSchema) {
      try {
        const response = await PassportSDK.Collections.deleteCollectionSchema(
          collectionSchema.id
        )

        commit('DELETE_COLLECTION_SCHEMA', response.collectionSchema)
      } catch (err) {
        return []
      }
    },
    async fetchCollectionItems({ commit, state }, type) {
      if (state.collectionItems[type] && state.collectionItems[type].length) {
        return state.collectionItems[type]
      }

      try {
        const response = await PassportSDK.Collections.getAllCollectionItems(
          type
        )

        commit('SET_COLLECTION_ITEMS', {
          value: response.collectionItems,
          type: type
        })
      } catch (err) {
        return []
      }
    },
    async createCollectionItem({ commit }, { type, data }) {
      try {
        const response = await PassportSDK.Collections.createCollectionItem(
          data
        )

        commit('ADD_COLLECTION_ITEM', {
          value: response.collectionItem,
          type: type
        })
      } catch (err) {
        console.log('err :>> ', err)
        return []
      }
    },
    async updateCollectionItem({ commit }, { type, id, data }) {
      try {
        const response = await PassportSDK.Collections.updateCollectionItem(
          id,
          data
        )

        commit('UPDATE_COLLECTION_ITEM', {
          value: response.collectionItem,
          type: type
        })
      } catch (err) {
        console.log('err :>> ', err)
        return []
      }
    },
    async deleteCollectionItem({ commit }, { type, id }) {
      try {
        const response = await PassportSDK.Collections.deleteCollectionItem(id)

        commit('DELETE_COLLECTION_ITEM', {
          type: type,
          value: response.collectionItem
        })
      } catch (err) {
        console.log('err :>> ', err)
        return []
      }
    }
  }
}
