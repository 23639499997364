import { PassportSDK } from '@/packages/artebeaute-sdk/src'
import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    needles: []
  }),
  getters: {
    getNeedles(state) {
      return state.needles
    }
  },
  mutations: {
    SET_NEEDLES(state, value) {
      state.needles = value
    },
    ADD_NEEDLE(state, value) {
      state.needles.push(value)
    },
    REMOVE_NEEDLE(state, value) {
      const needleIndex = state.needles.findIndex(n => n.id === value.id)
      state.needles.splice(needleIndex, 1)
    },
    UPDATE_NEEDLE(state, value) {
      const needleIndex = state.articles.findIndex(n => n.id === value.id)

      if (needleIndex !== -1) {
        Vue.set(state.needles, needleIndex, value)
      }
    }
  },
  actions: {
    async fetchNeedles({ commit, state }) {
      if (state.needles.length) {
        return state.needles
      }

      try {
        const response = await PassportSDK.Needles.getAllNeedles()

        commit('SET_NEEDLES', response.needles)
      } catch (err) {
        return []
      }
    },
    async updateNeedle({ commit }, data) {
      try {
        const response = await PassportSDK.Needles.updateNeedle(data.id, data)

        commit('UPDATE_NEEDLE', response.needle)

        return JSON.parse(JSON.stringify(response.needle))
      } catch (err) {
        console.log('err :>> ', err)
        return []
      }
    },
    async createNeedle({ commit }, data) {
      try {
        const response = await PassportSDK.Needles.createNeedle(data)

        commit('ADD_NEEDLE', response.needle)
      } catch (err) {
        console.log('err :>> ', err)
        return []
      }
    },
    async deleteNeedle({ commit }, needle) {
      try {
        const response = await PassportSDK.Needles.deleteNeedle(needle.id)

        commit('REMOVE_NEEDLE', response.needle)
      } catch (err) {
        console.log('err :>> ', err)
        return []
      }
    }
  }
}
