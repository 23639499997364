<template>
  <layout-default :links="links">
    <slot />
  </layout-default>
</template>

<script>
import layoutDefault from '../layout-default.vue'

export default {
  components: { layoutDefault },
  computed: {
    links() {
      return [
        {
          id: 1,
          label: this.$t('layout.dashboard'),
          to: '/database'
        },
        {
          id: 2,
          label: this.$t('layout.shops'),
          to: '/database/shops',
          links: [
            {
              to: '/database/shops',
              label: this.$t('layout.shops')
            }
          ],
          acl: []
        },
        {
          id: 3,
          label: this.$t('database.articles'),
          to: '/database/articles',
          links: [
            {
              to: '/database/articles',
              label: this.$t('database.articles')
            }
            // {
            //   to: '/database/categories',
            //   label: 'Catégories'
            // },
            // {
            //   to: '/database/resources',
            //   label: 'Ressources'
            // }
          ],
          acl: []
        },
        {
          id: 4,
          label: this.$t('database.blog'),
          to: '/database/blog/posts',

          links: [
            {
              to: '/database/blog/categories',
              label: this.$t('database.categories')
            },
            {
              to: '/database/blog/posts',
              label: this.$t('database.articles')
            }
            // {
            //   to: '/database/categories',
            //   label: 'Catégories'
            // },
            // {
            //   to: '/database/resources',
            //   label: 'Ressources'
            // }
          ]
        }
        // {
        //   id: 4,
        //   label: 'PassBeauté',
        //   links: [
        //     // {
        //     //   to: '/passport/needles',
        //     //   label: 'Aiguilles'
        //     // },
        //     // {
        //     //   to: '/passport/pigments',
        //     //   label: 'Pigments'
        //     // },
        //     {
        //       to: '/database/consentements',
        //       label: 'Consentements'
        //     },
        //     {
        //       to: '/database/collections',
        //       label: 'Collections'
        //     }
        //   ]
        // }
      ]
    }
  }
}
</script>

<style></style>
