import { MKPSDK } from '@/packages/artebeaute-sdk/src'
import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    alerts: []
  }),
  getters: {
    getAlerts(state) {
      return state.alerts.sort((a, b) => {
        return new Date(a.end) - new Date(b.end)
      })
    }
  },
  mutations: {
    SET_ALERTS(state, data) {
      state.alerts = data
    },
    UPDATE_ALERT(state, { id, data }) {
      const index = state.alerts.findIndex(e => e.id === id)

      if (index >= 0) {
        Vue.set(state.alerts, index, data)
      }
    },
    DELETE_ALERT(state, { id }) {
      const index = state.alerts.findIndex(e => e.id === id)

      if (index >= 0) {
        Vue.delete(state.alerts, index)
      }
    }
  },
  actions: {
    async fetchAlerts({ commit, state }) {
      if (state.alerts.length) {
        return state.alerts
      }

      try {
        const response = await MKPSDK.Alert.getAllAlerts()

        commit('SET_ALERTS', response.alerts)
      } catch (err) {
        return []
      }
    },

    async createAlert({ state }, data) {
      try {
        const response = await MKPSDK.Alert.createAlert(data)

        state.alerts.push(response.alert)
        return true
      } catch (err) {
        return false
      }
    },

    async updateAlert({ commit }, { id, data }) {
      try {
        const response = await MKPSDK.Alert.updateAlert(id, data)

        commit('UPDATE_ALERT', { id, data: response.alert })
        return true
      } catch (err) {
        return false
      }
    },

    async deleteAlert({ commit }, { alert }) {
      try {
        await MKPSDK.Alert.deleteAlert(alert.id)

        commit('DELETE_ALERT', { id: alert.id })
        return true
      } catch (err) {
        return false
      }
    }
  }
}
