import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async preRegister(email) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/users/register/steps/1',
        {
          email
        }
      )

      return { user: response.data.user }
    } catch (err) {
      throw createError(err)
    }
  },
  async register(email, password) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/users/register/steps/2',
        {
          email,
          password
        }
      )

      return response.data.user
    } catch (err) {
      throw createError(err)
    }
  }
}
