import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getAllCategories() {
    try {
      const response = await axios.get(config.apiURL + '/api/categories')

      return {
        categories: response.data.categories
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getCategory(id) {
    try {
      const response = await axios.get(config.apiURL + '/api/categories/' + id)

      return {
        category: response.data.category
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getCategoryBySlug(slug) {
    try {
      const response = await axios.get(
        config.apiURL + '/api/categories/slug/' + slug
      )

      return {
        category: response.data.category
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async createCategory(data) {
    try {
      const response = await axios.post(config.apiURL + '/api/categories', data)

      return {
        category: response.data.category
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async updateCategory(id, data) {
    try {
      const response = await axios.put(
        config.apiURL + '/api/categories/' + id,
        data
      )

      return {
        category: response.data.category
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async updateOrders(data) {
    try {
      await axios.put(config.apiURL + '/api/categories/sort', data)

      return {
        success: true
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async deleteCategory(id) {
    try {
      const response = await axios.delete(
        config.apiURL + '/api/categories/' + id
      )

      return {
        category: response.data.category
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async linkArticle(categoryId, articleId) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/categories/link/article/' + categoryId,
        {
          articleId
        }
      )

      return {
        category: response.data.category,
        article: response.data.article
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async unlinkArticle(categoryId, articleId) {
    try {
      const response = await axios.delete(
        config.apiURL + '/api/categories/link/article/' + categoryId,
        {
          data: {
            articleId
          }
        }
      )

      return {
        category: response.data.category,
        article: response.data.article
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async linkArticles(categoryId, articlesId) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/categories/link/article/' + categoryId,
        {
          articlesId
        }
      )

      return {
        category: response.data.category
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async unlinkArticles(categoryId, articlesId) {
    try {
      const response = await axios.delete(
        config.apiURL + '/api/categories/link/article/' + categoryId,
        {
          articlesId
        }
      )

      return {
        category: response.data.category
      }
    } catch (err) {
      throw createError(err)
    }
  }
}
