import { ArteBeauteSDK } from '@/packages/artebeaute-sdk/src'
import slugify from 'slugify'
import Vue from 'vue'
import store from '../index'
import axiosErrorCode from '@/helpers/axios'

export default {
  namespaced: true,
  state: () => ({
    abShops: [],
    shops: [],
    abResource: [],
    resource: [],
    loaded: { abShops: null, shops: null, abResources: null, resources: null },
    statsSelectedShops: [],
    newOnlineShop: [],
  }),
  getters: {
    abShopNameById: (state) => (id) => {
      const shop = state.abShops.find((s) => s.id === id)
      if (!shop) {
        return ''
      }
      return shop.name
    },
    abShopIdById: (state, getters) => (id) => {
      const shop = getters['fusionShop']().find((s) => s.id === id)
      if (!shop) {
        return -1
      }
      return shop.abId
    },
    abShopById: (state) => (id) => {
      const shop = state.abShops.find((s) => s.id === id)
      if (!shop) {
        return ''
      }
      return shop
    },
    stateLoaded: (state) => {
      let tabLoaded = []
      tabLoaded.push({
        chargement: 'Boutiques Artebeaute',
        status: state.loaded.abShops,
      })
      tabLoaded.push({
        chargement: 'Boutiques Liées',
        status: state.loaded.shops,
      })
      return tabLoaded
    },
    fusionShop:
      (state) =>
      (filtered = null) => {
        let rep = []

        for (const s of state.abShops) {
          const myshop = {
            abId: s.id,
            imageUrl: null,
            id: null,
            nickName: null,
            slug: null,
            online: { active: false },
            name: s.name,
            mail: s.email,
            address: s.address,
            abName: s.name,
            zipcode: s.zipcode,
            city: s.city,
            country: s.country,
            society: s.society,
            phone: s.phone,
            abPhone: s.phone,
            nii: s.nii,
            ri: s.ri,
            ape: s.ape,
            siret: s.siret,
            grp: { id: s.grp.id, name: s.grp.name },
            resources: null,
            openingHours: null,
            booker: null,
            passport: null,
            geo: null,
            dayOff: null,
            localisation: {
              coordonate: {
                lat: null,
                lng: null,
              },
              tooltip: true,
              markerperso: null,
            },
            draft: false,
          }
          const sh = state.shops.find(
            (elt) =>
              elt.artebeauteId === myshop.abId || elt.abId === myshop.abId
          )
          if (sh) {
            myshop.id = sh.id
            myshop.online.active = sh.active
            myshop.country = sh.country ? sh.country : myshop.country
            myshop.geoGroupment = sh.geoGroupment
            if (sh.publicInfo && Object.keys(sh.publicInfo).length) {
              myshop.name = sh.publicInfo.name
              myshop.address = sh.publicInfo.address.address
              myshop.city = sh.publicInfo.address.city
              myshop.zipcode = sh.publicInfo.address.zipcode
              myshop.country = sh.publicInfo.address.country
              myshop.phone = sh.publicInfo.phone
              myshop.email = sh.publicInfo.email
              myshop.phoneDetails = sh.publicInfo.phoneDetails
            }
            myshop.slug = sh.slug
            myshop.openingHours = sh.openingHours
            myshop.booker = sh.booker
            myshop.passport = sh.passport
            myshop.imageUrl = sh.images
            myshop.dayOff = sh.dayOff
            myshop.localisation = sh.localisation
            myshop.articles = sh.articles
            myshop.publicInfo = sh.publicInfo
            myshop.draft = sh.draft
          }

          if (filtered && filtered.active) {
            if ((filtered.isOnline && !sh) || (!filtered.isOnline && sh)) {
              continue
            } else if (filtered.isOnline) {
              const booker = filtered.onLineType.findIndex((elt) => elt === 0)
              const passport = filtered.onLineType.findIndex((elt) => elt === 1)
              if (
                (booker >= 0 && !sh.booker.active) ||
                (passport >= 0 && !sh.passport.active)
              ) {
                continue
              }
            }
          }
          rep.push(myshop)
        }
        return rep
      },
    getNewOnlineShop: (state) => {
      state.newOnlineShop.forEach((shop) => {
        if (
          state.shops.find(
            (a) => a.artebeauteId === shop.abId || a.abId === shop.abId
          ).geoGroupment
        ) {
          shop.geoGroupment = state.shops.find(
            (a) => a.artebeauteId === shop.abId || a.abId === shop.abId
          ).geoGroupment
        } else {
          delete shop.geoGroupment
        }
      })

      return state.newOnlineShop
    },
  },
  mutations: {
    SET_ABSHOPLOADED(state, value) {
      state.loaded.abShops = value
    },
    SET_SHOPLOADED(state, value) {
      state.loaded.shops = value
    },
    SET_ABRESOURCESLOADED(state, value) {
      state.loaded.abResources = value
    },
    SET_RESOURCESLOADED(state, value) {
      state.loaded.resources = value
    },
    SET_ABSHOPS(state, value) {
      state.abShops = value
    },
    SET_SHOPS(state, value) {
      state.shops = value
    },
    PUSH_SHOP(state, value) {
      state.shops.push(value)
    },
    UPDATE_SHOP(state, value) {
      const i = state.shops.findIndex((elt) => elt.id === value.id)
      if (i >= 0) {
        //debugger;
        //state.shops[i] = value;
        Vue.set(state.shops, i, value)
      }
    },
    SPLICE_SHOP(state, value) {
      state.shops.splice(
        state.shops.findIndex((s) => s.id === value.id),
        1
      )
    },
    SET_ABRESOURCES(state, value) {
      state.abResources = value
    },
    SET_RESOURCES(state, value) {
      state.resources = value
    },
    SET_STATS_SELECTED_SHOPS(state, value) {
      state.statsSelectedShops = value
    },
    UPDATE_NEWONLINESHOP(state, { value, isDelete, getters }) {
      if (isDelete) {
        if (state.newOnlineShop.find((s) => s.abId === value.artebeauteId)) {
          state.newOnlineShop.splice(
            state.newOnlineShop.findIndex((s) => s.abId === value.artebeauteId),
            1
          )
        }
      } else if (getters) {
        const shops = getters.fusionShop({
          active: true,
          isOnline: true,
          onLineType: [],
        })

        const shopToPush = shops.find((s) => s.abId === value.artebeauteId)

        const index = state.newOnlineShop.findIndex((n) => n.id === value.id)

        if (index >= 0) {
          Vue.set(state.newOnlineShop, index, shopToPush)
        } else {
          state.newOnlineShop.push(shopToPush)
        }
      } else {
        const index = state.newOnlineShop.findIndex((n) => n.id === value.id)
        Vue.set(state.newOnlineShop, index, value)
      }
    },
  },
  actions: {
    async setStatsSelectedShops({ commit }, shops) {
      await commit('SET_STATS_SELECTED_SHOPS', shops)
    },
    async getAbShops({ commit }) {
      if (!store.state.auth.company.artebeaute.active) {
        commit('SET_ABSHOPS', [])
        commit('SET_ABSHOPLOADED', 'loaded')
        return
      }

      commit('SET_ABSHOPLOADED', -1)
      const response = await ArteBeauteSDK.Shops.getAllAbShops()

      /* for (const myshop of myshops) {
        commit("SET_ABSHOPLOADED", myshop.name);
        const resources = await ArteBeauteSDK.Resources.getAllAbCollaborators(
          myshop.id
        );
        myshop.resources = resources;
      } */
      commit('SET_ABSHOPS', response.shops)
      commit('SET_ABSHOPLOADED', 'loaded')
    },
    async getShops({ commit }) {
      commit('SET_SHOPLOADED', -1)
      const response = await ArteBeauteSDK.Shops.getAllShops()
      commit('SET_SHOPS', response.shops)
      commit('SET_SHOPLOADED', 'loaded')
    },
    async getAbResource({ commit }, payload) {
      commit('SET_ABRESOURCESLOADED', -1)
      const resources = await ArteBeauteSDK.Resources.getAllAbCollaborators(
        payload
      )

      commit('SET_ABRESOURCES', resources)
      commit('SET_ABRESOURCESLOADED', 'loaded')
      //const myresource = await ArteBeauteSDK.Shops.get
    },
    async updateShop({ state, commit, getters }, shop) {
      try {
        const response = await ArteBeauteSDK.Shops.updateShop(
          shop.id,
          shop.data
        )
        if (response) {
          commit('UPDATE_SHOP', response.shop)
          if (state.newOnlineShop.find((n) => n.id === response.shop.id)) {
            commit('UPDATE_NEWONLINESHOP', { value: response.shop, getters })
          }
          return { success: true }
        }
      } catch (e) {
        console.log(e)
        return {
          error: true,
          message:
            'Une erreur est survenue lors de la sauvegarde de la boutique.',
          code: axiosErrorCode(e),
        }
      }
    },
    async publishShop({ commit, getters }, shop) {
      try {
        const response = await ArteBeauteSDK.Shops.createShop({
          artebeauteId: shop.abId,
          slug: slugify(shop.name).toLowerCase(),
          images: [],
          openingHours: {
            monday: {
              day: 'monday',
              hours: [
                { start: '09:00', end: '12:00' },
                { start: '14:00', end: '19:00' },
              ],
              status: 'closed',
            },
            tuesday: {
              day: 'tuesday',
              hours: [
                { start: '09:00', end: '12:00' },
                { start: '14:00', end: '19:00' },
              ],
              status: 'closed',
            },
            wednesday: {
              day: 'wednesday',
              hours: [
                { start: '09:00', end: '12:00' },
                { start: '14:00', end: '19:00' },
              ],
              status: 'closed',
            },
            thursday: {
              day: 'thursday',
              hours: [
                { start: '09:00', end: '12:00' },
                { start: '14:00', end: '19:00' },
              ],
              status: 'closed',
            },
            friday: {
              day: 'friday',
              hours: [
                { start: '09:00', end: '12:00' },
                { start: '14:00', end: '19:00' },
              ],
              status: 'closed',
            },
            saturday: {
              day: 'saturday',
              hours: [
                { start: '09:00', end: '12:00' },
                { start: '14:00', end: '19:00' },
              ],
              status: 'closed',
            },
            sunday: {
              day: 'sunday',
              hours: [
                { start: '09:00', end: '12:00' },
                { start: '14:00', end: '19:00' },
              ],
              status: 'closed',
            },
          },
          publicInfo: {
            name: shop.name,
            email: null,
            phone: shop.phone,
            siret: shop.siret,
            society: shop.society,
            address: {
              address: null,
              city: shop.city,
              country: shop.country,
              zipcode: shop.zipcode,
            },
          },
          daysOff: {},
          localisation: {
            coordonate: {
              lat: null,
              lng: null,
            },
            tooltip: true,
            markerperso: null,
          },
          booker: {
            active: true,
            appointment: { receiveEmailCopy: true },
          },
          passport: {
            active: false,
          },
          active: true,
        })

        commit('PUSH_SHOP', response.shop)

        commit('UPDATE_NEWONLINESHOP', { value: response.shop, getters })

        return true
      } catch (err) {
        return false
      }
    },
    async unpublishShop({ commit }, localShop) {
      try {
        await ArteBeauteSDK.Shops.deleteShop(localShop.id)

        commit('SPLICE_SHOP', localShop)

        commit('UPDATE_NEWONLINESHOP', { value: localShop, isDelete: true })

        return true
      } catch (err) {
        return false
      }
    },
    async undraftShop({ commit }, shop) {
      try {
        await ArteBeauteSDK.Shops.undraftShop(shop.id)

        commit('UPDATE_SHOP', {
          ...shop,
          draft: false,
        })
        commit('UPDATE_NEWONLINESHOP', { value: { ...shop, draft: false } })
      } catch (err) {
        console.log('err :>> ', err)
        return []
      }
    },
    async draftShop({ commit }, shop) {
      try {
        await ArteBeauteSDK.Shops.draftShop(shop.id)

        commit('UPDATE_SHOP', {
          ...shop,
          draft: true,
        })
        commit('UPDATE_NEWONLINESHOP', { value: { ...shop, draft: true } })
      } catch (err) {
        console.log('err :>> ', err)
        return []
      }
    },
    async linkArticle({ commit }, { shop, article }) {
      try {
        const response = await ArteBeauteSDK.Shops.linkArticle(
          shop.id,
          article.id
        )
        commit('UPDATE_SHOP', response.shop)
        commit('articles/UPDATE_ARTICLE', response.article, { root: true })

        return response
      } catch (err) {
        console.log('err :>> ', err)
        return false
      }
    },
    async unlinkArticle({ commit }, { shop, article }) {
      try {
        const response = await ArteBeauteSDK.Shops.unlinkArticle(
          shop.id,
          article.id
        )

        commit('UPDATE_SHOP', response.shop)
        commit('articles/UPDATE_ARTICLE', response.article, { root: true })

        return response
      } catch (err) {
        console.log('err :>> ', err)
        return false
      }
    },
  },
}
