import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getAllTransactions(query) {
    try {
      const response = await axios.get(config.apiURL + '/api/transactions', {
        params: query
      })

      return {
        transactions: response.data.transactions
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getAllFails() {
    try {
      const response = await axios.get(
        config.apiURL + '/api/transactions/fails'
      )

      return {
        transactions: response.data.transactions
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async resolveFails(id, data) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/transactions/fails/' + id,
        data
      )

      return response.data
    } catch (err) {
      throw createError(err)
    }
  },
  async getAllGifts(query) {
    try {
      const response = await axios.get(
        config.apiURL + '/api/transactions/gifts',
        {
          params: query
        }
      )

      return {
        gifts: response.data.gifts
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getAllStripe(data) {
    try {
      const response = await axios.get(
        config.apiURL + '/api/transactions/stripe',
        {
          params: data
        }
      )

      return {
        intents: response.data.intents,
        transactions: response.data.transactions
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async fixStripe(data, isTest, mail, phone) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/transactions/fix-stripe',
        {
          data,
          isTest,
          mail,
          phone
        }
      )

      return response.data
    } catch (err) {
      throw createError(err)
    }
  }
}
