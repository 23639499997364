import { ArteBeauteSDK } from '@/packages/artebeaute-sdk/src'

export default {
  namespaced: true,
  state: () => ({
    transactions: [],
    gifts: [],
    pages: {},
  }),
  mutations: {
    SET_TRANSACTIONS(state, value) {
      state.transactions = value
    },
    SET_GIFTS(state, value) {
      state.gifts = value
    },
    SET_PAGES(state, value) {
      state.pages = value
    },
  },
  actions: {
    async fetchTransactions({ commit, state }, { force = false, query }) {
      if (!force && state.transactions.length) {
        return state.transactions
      }

      try {
        const response = await ArteBeauteSDK.Transactions.getAllTransactions(
          query
        )

        commit('SET_TRANSACTIONS', response.transactions.data)
        commit('SET_PAGES', response.transactions.pages)
      } catch (err) {
        return []
      }
    },
    async fetchGifts(
      { commit, state },
      { force = false, query, clean = true }
    ) {
      if (!force && state.gifts.length) {
        return state.gifts
      }

      try {
        const response = await ArteBeauteSDK.Transactions.getAllGifts(query)

        if (query.page) {
          if (clean) {
            commit('SET_GIFTS', response.gifts.data)
          } else {
            commit('SET_GIFTS', [...state.gifts, ...response.gifts.data])
          }
        } else {
          commit('SET_PAGES', response.gifts.pages)
        }
      } catch (err) {
        return []
      }
    },
  },
}
