import { ArteBeauteSDK } from '@/packages/artebeaute-sdk/src'

export default {
  namespaced: true,
  state: () => ({
    customers: [],

    loaded: {
      status: false,
      currentPage: 0,
      nbPages: 0,
      stop: false
    }
  }),
  mutations: {
    SET_CUSTOMERS(state, value) {
      state.customers = value
    },
    SET_LOADED_STOP(state, value) {
      state.loaded.stop = value
    },
    SET_LOADED_STATUS(state, value) {
      state.loaded.status = value
    },
    SET_LOADED_CURRENTPAGE(state, value) {
      state.loaded.currentPage = value
    },
    SET_LOADED_PAGES(state, value) {
      state.loaded.nbPages = value
    }
  },
  actions: {
    async fetchStop({ commit }) {
      commit('SET_LOADED_STOP', true)
    },
    async fetchCustomersAll({ commit, state }, payLoad) {
      if (!payLoad) {
        // First Lancement
        commit('SET_LOADED_STATUS', true)
        commit('SET_LOADED_STOP', false)
        let nbpages = 0
        let results = []
        const rep = await ArteBeauteSDK.Customers.getAllAbCustomers(
          null,
          100000
        )
        if (rep.customers && rep.customers.success) {
          commit('SET_LOADED_PAGES', rep.customers.data.nbpage)
          nbpages = rep.customers.data.nbpage
          for (let i = 1; i <= nbpages; i++) {
            commit('SET_LOADED_CURRENTPAGE', i)

            const custom = await ArteBeauteSDK.Customers.getAllAbCustomers(
              i,
              100000
            )

            if (custom.customers && custom.customers.success) {
              results = [...results, ...custom.customers.data]
            }
            if (state.loaded.stop) {
              break
            }
          }
        }
        commit('SET_CUSTOMERS', results)
        commit('SET_LOADED_STATUS', false)
      }
    },
    async fetchCustomersSearch({ commit, state }, payLoad) {
      if (payLoad) {
        // First Lancement
        commit('SET_LOADED_STATUS', true)
        commit('SET_LOADED_STOP', false)
        let nbpages = 0
        let results = []
        const rep = await ArteBeauteSDK.Customers.getAllAbCustomersSearch(
          payLoad,
          null,
          10000
        )
        if (rep.customers && rep.customers.success) {
          commit('SET_LOADED_PAGES', rep.customers.data.nbpage)
          nbpages = rep.customers.data.nbpage
          for (let i = 1; i <= nbpages; i++) {
            commit('SET_LOADED_CURRENTPAGE', i)
            const custom = await ArteBeauteSDK.Customers.getAllAbCustomersSearch(
              payLoad,
              i,
              10000
            )
            if (custom.customers && custom.customers.success) {
              results = [...results, ...custom.customers.data]
            }
            if (state.loaded.stop) {
              break
            }
          }
        }
        commit('SET_CUSTOMERS', results)
        commit('SET_LOADED_STATUS', false)
      }
    }
  }
}
