import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getAllClosedDays() {
    try {
      const response = await axios.get(config.apiURL + '/api/closeddays')

      return {
        closedDays: response.data.closedDays,
      }
    } catch (err) {
      throw createError(err)
    }
  },

  async createClosedDay(data) {
    try {
      const response = await axios.post(config.apiURL + '/api/closeddays', data)

      return {
        closedDay: response.data.closedDay,
      }
    } catch (err) {
      throw createError(err)
    }
  },

  // async updateEvent(id, data) {
  //   try {
  //     const response = await axios.put(
  //       config.apiURL + '/api/events/' + id,
  //       data
  //     )
  //
  //     return {
  //       event: response.data.event
  //     }
  //   } catch (err) {
  //     throw createError(err)
  //   }
  // },

  async deleteClosedDay(id) {
    try {
      const response = await axios.delete(
        config.apiURL + '/api/closeddays/' + id
      )

      return {
        closedDay: response.data.closedDay,
      }
    } catch (err) {
      throw createError(err)
    }
  },
}
