import needles from './needles/needles'
import pigments from './pigments/pigments'
import prestations from './prestations/prestations'
import access from './access/access'
import consentements from './consentements/consentements'
import dossiers from './dossiers/dossiers'
import collections from './collections/collections'
import config from './config'

export default {
  Config: config,
  Needles: {
    ...needles
  },
  Pigments: {
    ...pigments
  },
  Prestations: {
    ...prestations
  },
  Access: {
    ...access
  },
  Consentements: {
    ...consentements
  },
  Dossiers: {
    ...dossiers
  },
  Collections: {
    ...collections
  },
  initialize(config) {
    Object.keys(config).forEach(key => {
      this.Config[key] = config[key]
    })
  }
}
