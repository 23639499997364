<template>
  <div class="content-loading">
    <div class="load-bar-content">
      <div class="load-bar" :style="{ width: loadAdvanced }" />
    </div>
    <div class="current-loading-content">
      <div class="current-loading">
        <span>{{ loadAdvanced }}</span>
        <v-progress-circular
          :size="20"
          color="rgb(100, 100, 100)"
          indeterminate
        />
      </div>
    </div>
  </div>
</template>

<script>
// import layoutEmpty from "../../layouts/layout-empty.vue";
// import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      progress: 0
    }
  },
  // data: () => {
  //   return {
  //     canGoHome: true
  //   }
  // },
  // created() {
  //   console.log('Je suis dans Starter')
  //   // this.$emit("update:layout", layoutEmpty);
  //   this.$store.subscribe((mutation, state) => {
  //     // console.log(mutation.type);
  //     // console.log(state.shop.loaded);
  //     if (
  //       mutation.type === 'articles/SET_ABARTICLELOAD' ||
  //       mutation.type === 'articles/SET_ARTICLELOAD' ||
  //       mutation.type === 'shop/SET_ABSHOPLOADED' ||
  //       mutation.type === 'shop/SET_SHOPLOADED' ||
  //       mutation.type === 'auth/SET_USERS'
  //     ) {
  //       // console.log("je passe dans mutations ");
  //       if (
  //         state.shop.loaded.shops === 'loaded' &&
  //         state.shop.loaded.abShops === 'loaded' &&
  //         state.articles.loaded.abArticles === 'loaded' &&
  //         state.articles.loaded.articles === 'loaded' &&
  //         state.auth.users
  //       ) {
  //         if (this.canGoHome) {
  //           this.canGoHome = false
  //           this.$emit('ready')
  //           // console.log('Go to HOME')
  //           // return this.$router.push('/')
  //         }
  //       }
  //     }
  //   })
  // },
  computed: {
    // ...mapGetters({
    //   stateShopLoaded: 'shop/stateLoaded',
    //   stateArticleLoaded: 'articles/stateLoaded'
    // }),
    // stateLoaded() {
    //   return [...this.stateShopLoaded, ...this.stateArticleLoaded]
    // },
    // currentLoad() {
    //   const res = this.stateLoaded.filter(item => item.status === -1)[0]
    //   return res ? res.chargement : '100%'
    // },
    loadAdvanced() {
      return parseInt((this.progress * 100) / 6) + '%'
    }
  },

  async mounted() {
    const result = await Promise.all([
      this.$store
        .dispatch('articles/fetchAbArticles')
        .then(() => this.progress++),
      this.$store
        .dispatch('articles/fetchArticles')
        .then(() => this.progress++),
      this.$store.dispatch('shop/getShops').then(() => this.progress++),
      this.$store.dispatch('shop/getAbShops').then(() => this.progress++),
      this.$store.dispatch('auth/users').then(() => this.progress++),
      this.$store
        .dispatch('releases/fetchAllReleases')
        .then(() => this.progress++)
    ])
    this.$store.dispatch('cashier/isReadyToCharge')
    if (result) {
      this.$emit('ready')
    }
    // console.log("je passe");
  },
  methods: {}
}
</script>

<style>
.content-loading {
  min-height: 100vh;
  padding-top: 45vh;
}

.load-bar-content,
.load-bar {
  height: 15px;
  border-radius: 30px;
}

.load-bar-content {
  margin: 0 auto;
  max-width: 300px;
  background-color: rgb(220, 220, 220);
}

.load-bar {
  width: 0;
  background-color: #54c797;
  transition: 0.5s;
}

.current-loading-content {
  width: 100%;
}

.current-loading {
  width: fit-content;
  margin: 0 auto;
  padding-top: 30px;
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.current-loading > span {
  font-size: 1rem;
  font-weight: 700;
  color: rgb(100, 100, 100);
}
</style>
