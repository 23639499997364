import axios from 'axios'
import { createError } from '../../artebeaute/helpers/errors'
import config from '../config'

export default {
  async reglementByShop(query) {
    try {
      const response = await axios.get(config.apiURL + '/ca/regByShop', {
        params: query
      })
      return {
        reglements: response.data.bookersCaRegByShop
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async bookerListByIdShop(query) {
    try {
      const response = await axios.get(config.apiURL + '/ca/bookersByIdShop', {
        params: query
      })
      return {
        list: response.data.bookerListByIdShop
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async giftListByIdShop(query) {
    try {
      const response = await axios.get(config.apiURL + '/ca/giftsByIdShop', {
        params: query
      })
      return {
        list: response.data.giftListByIdShop
      }
    } catch (err) {
      throw createError(err)
    }
  }
}
