<template>
  <v-app>
    <component :is="$route.meta.layout ? $route.meta.layout : layout">
      <transition name="page">
        <router-view />
      </transition>
    </component>
  </v-app>
</template>

<script>
import DefaultLayout from '@/layouts/layout-default.vue'

export default {
  components: {},
  data() {
    return {
      layout: DefaultLayout,
    }
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'SmartBooker Manager',
  },
} //
</script>

<style scoped>
/* .container {
  max-width: 768px;
} */

/*
 * page
 */

.page-enter-active {
  animation: page-in 0.4s;
}
.page-leave-active {
  animation: page-out 0.2s;
}

@keyframes page-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes page-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
