<template>
  <v-card>
    <v-card-title>
      <icon-display v-if="icon" :small="false" :icon="icon" />
      <h3>{{ title }}</h3>
      <v-spacer />
      <icon-display @actionClick="close" :small="false" :icon="$icones.close" />
    </v-card-title>
    <v-divider :class="icon ? icon.color : ''" />
    <v-card-text>
      <slot name="simpleModalContent" />
    </v-card-text>
  </v-card>
</template>

<script>
import iconDisplay from './iconDisplay.vue'
export default {
  name: 'simpleModal',
  components: { iconDisplay },
  props: {
    title: { type: String, default: 'Titre' },
    icon: { type: Object, default: null }
  },

  methods: {
    close() {
      this.$emit('closeModal', true)
    }
  }
}
</script>

<style></style>
