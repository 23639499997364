import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getAllGeogroupments() {
    try {
      const response = await axios.get(config.apiURL + '/api/geogroupments')

      return {
        geogroupments: response.data.geoGroupments,
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getGeogroupment(id) {
    try {
      const response = await axios.get(
        config.apiURL + '/api/geogroupments/' + id
      )

      return {
        geogroupment: response.data.geoGroupment,
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async createGeogroupment(data) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/geogroupments',
        data
      )

      return {
        geogroupment: response.data.geoGroupment,
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async updateGeogroupment(id, data) {
    try {
      const response = await axios.put(
        config.apiURL + '/api/geogroupments/' + id,
        data
      )

      return {
        geogroupment: response.data.geoGroupment,
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async deleteGeogroupment(id) {
    try {
      const response = await axios.delete(
        config.apiURL + '/api/geogroupments/' + id
      )

      return {
        geogroupment: response.data.geoGroupment,
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async linkGeogroupmentToShop(geogroupmentId, shopId) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/geogroupments/link/shop/' + geogroupmentId,
        {
          shopId,
        }
      )

      return {
        shop: response.data.shop,
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async unlinkGeogroupmentToShop(geogroupmentId, shopId) {
    try {
      const response = await axios.delete(
        config.apiURL + '/api/geogroupments/link/shop/' + geogroupmentId,
        {
          data: {
            shopId,
          },
        }
      )

      return {
        shop: response.data.shop,
      }
    } catch (err) {
      throw createError(err)
    }
  },
}
