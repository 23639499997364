import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'
export default {
  async appointmentOldChangeStatus(ideorder, status) {
    try {
      const requestURL = config.apiURL + '/bookingOrder/changeStatus'
      const response = await axios.put(requestURL, {
        m_ideorder: ideorder,
        m_status: status
      })
      return { refundAsk: response.data }
    } catch (e) {
      console.log('e :>> ', e)
      throw createError(e)
    }
  },
  async appointmentOldCreditRefund(ideorder, customerId, amount) {
    try {
      const body = {
        appointment: {
          ideorder: ideorder,
          customerId: customerId,
          amount: amount
        }
      }
      const requestURL = config.apiURL + '/bookingOrder/oldCredit/refund'
      const response = await axios.post(requestURL, body)
      return { refundAsk: response.data }
    } catch (e) {
      console.log('e :>> ', e)
      throw createError(e)
    }
  },
  async appointmentPartialRefund(ideorder, customerId, amount) {
    try {
      const body = {
        appointment: {
          ideorder: ideorder,
          customerId: customerId,
          amount: amount
        }
      }
      const requestURL = config.apiURL + '/bookingOrder/partialRefund'
      const response = await axios.post(requestURL, body)
      return { refundAsk: response.data }
    } catch (e) {
      console.log('e :>> ', e)
      throw createError(e)
    }
  },
  async orderById(id, withDetails = '') {
    try {
      const response = await axios.get(
        config.apiURL +
          '/bookingOrder/ideorder?idEorder=' +
          id +
          '&withDetails=' +
          withDetails
      )
      return { eorder: response.data.booker }
    } catch (err) {
      throw createError(err)
    }
  },
  async orderByCustomerId(id, status, creditUsed) {
    try {
      const response = await axios.get(
        config.apiURL +
          '/bookingOrder/customerId?customerId=' +
          id +
          '&status=' +
          status +
          '&creditUsed=' +
          creditUsed
      )
      return { eorder: response.data.booker }
    } catch (err) {
      throw createError(err)
    }
  },
  async customerRemainingAmount(id) {
    try {
      const response = await axios.get(
        config.apiURL + '/customers/remainingAmount?artebeauteId=' + id
      )
      return { customerRemaining: response.data }
    } catch (err) {
      throw createError(err)
    }
  },
  async orderOldCreditById(id) {
    try {
      const response = await axios.get(
        config.apiURL + '/bookingOrder/oldCredit/ideorder?idEorder=' + id
      )
      return { eorder: response.data.booker }
    } catch (err) {
      throw createError(err)
    }
  },
  async orderUse(ideorder) {
    try {
      const response = await axios.get(
        config.apiURL + '/bookingOrder/eorder/use?ideorder=' + ideorder
      )
      return { use: response.data.booker }
    } catch (err) {
      throw createError(err)
    }
  },
  async orderHistory(
    page,
    itemPerPage,
    sort,
    sortDesc,
    periodSearch,
    start,
    end,
    customerLastName,
    customerFirstName,
    customerMail,
    transactionId,
    bookerOrigin,
    bookerStatus,
    shops
  ) {
    try {
      const response = await axios.get(
        config.apiURL +
          '/bookingOrder/history?page=' +
          page +
          '&itemPerPage=' +
          itemPerPage +
          '&periodSearch=' +
          periodSearch +
          '&sortBy=' +
          sort +
          '&sortDesc=' +
          sortDesc +
          '&start=' +
          start +
          '&end=' +
          end +
          '&customerLastName=' +
          customerLastName +
          '&customerFirstName=' +
          customerFirstName +
          '&customerMail=' +
          customerMail +
          '&transactionId=' +
          transactionId +
          '&bookerOrigin=' +
          bookerOrigin +
          '&bookerStatus=' +
          bookerStatus +
          '&shops=' +
          shops
      )

      return {
        bookingHistory: response.data.bookersHistory.data
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async orderAggregate(
    periodSearch = '',
    start,
    end,
    customerLastName = '',
    customerFirstName = '',
    customerMail = '',
    transactionId = '',
    bookerOrigin = '',
    bookerStatus = ''
  ) {
    try {
      const response = await axios.get(
        config.apiURL +
          '/bookingOrder/aggregate?' +
          '&periodSearch=' +
          periodSearch +
          '&start=' +
          start +
          '&end=' +
          end +
          '&customerLastName=' +
          customerLastName +
          '&customerFirstName=' +
          customerFirstName +
          '&customerMail=' +
          customerMail +
          '&transactionId=' +
          transactionId +
          '&bookerOrigin=' +
          bookerOrigin +
          '&bookerStatus=' +
          bookerStatus
      )

      return {
        bookingAggregate: response.data.bookersAggregate.data
      }
    } catch (err) {
      throw createError(err)
    }
  }
}
