import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getAllPigments() {
    try {
      const response = await axios.get(config.apiURL + '/api/pigments')

      return {
        pigments: response.data.pigments
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getPigment(id) {
    try {
      const response = await axios.get(config.apiURL + '/api/pigments/' + id)

      return {
        pigment: response.data.pigment
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async createPigment(data) {
    try {
      const response = await axios.post(config.apiURL + '/api/pigments', data)

      return {
        pigment: response.data.pigment
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async updatePigment(id, data) {
    try {
      const response = await axios.put(
        config.apiURL + '/api/pigments/' + id,
        data
      )

      return {
        pigment: response.data.pigment
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async deletePigment(id) {
    try {
      const response = await axios.delete(config.apiURL + '/api/pigments/' + id)

      return {
        pigment: response.data.pigment
      }
    } catch (err) {
      throw createError(err)
    }
  }
}
