export function createError(err) {
  const error = {}

  if (err.response) {
    error.code = err.response.data.errors.code
    error.message = err.response.data.errors.message
  } else {
    error.code = 503
    error.message = 'Le service est indisponible.'
  }

  return error
}
