import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getAllShops() {
    try {
      const response = await axios.get(config.apiURL + '/shops')

      return {
        shops: response.data.shops
      }
    } catch (err) {
      throw createError(err)
    }
  }
}
