<template>
  <layout-default :links="links">
    <slot />
  </layout-default>
</template>

<script>
import layoutDefault from '../layout-default.vue'

export default {
  components: { layoutDefault },
  computed: {
    links() {
      return [
        {
          id: 1,
          label: this.$t('layout.dashboard'),
          to: '/communications'
        },
        {
          id: 2,
          label: this.$t('communications.myEvents'),
          to: '/communications/events',
          links: [
            {
              to: '/communications/events',
              label: this.$t('communications.myEvents')
            }
          ],
          acl: []
        },
        {
          id: 3,
          label: this.$t('communications.myAlerts'),
          to: '/communications/alerts',
          links: [
            {
              to: '/communications/alerts',
              label: this.$t('communications.myAlerts')
            }
          ],
          acl: []
        }
      ]
    }
  }
}
</script>

<style></style>
