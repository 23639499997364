<template>
  <span>
    <template v-if="type === 'icon'">
      <span v-if="icon.custom" v-html="icon.icon"></span>
      <span v-else>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :small="small"
              :color="color || icon.color"
              v-bind="attrs"
              v-on="on"
              @click.prevent="clickEvent"
              >{{ icon.icon }}
            </v-icon>
          </template>
          <span v-if="toolTip">{{ toolTip }}</span>
          <span v-else>{{ icon.toolTip }}</span>
        </v-tooltip>
      </span>
    </template>
    <template v-else-if="type === 'flat'">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            :color="color || icon.color"
            elevation="0"
            @click.prevent="clickEvent"
            v-bind="attrs"
            v-on="on"
            ><v-icon :color="icon.color" left small>{{ icon.icon }}</v-icon>
            <span v-if="strong"
              ><strong>{{ icon.title }}</strong></span
            ><span v-else>{{ icon.title }} </span>
          </v-btn>
        </template>
        <span v-if="toolTip">{{ toolTip }}</span>
        <span v-else>{{ icon.toolTip }}</span>
      </v-tooltip>
    </template>

    <template v-else-if="type === 'img'">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <img
            :src="img"
            class="img-icon"
            alt="edit"
            @click.prevent="clickEvent"
            v-ripple
            v-on="on"
          />
        </template>
        <span>{{ toolTip }}</span>
      </v-tooltip>
    </template>

    <template v-else>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :outlined="outlined"
            tile
            :text="text"
            elevation="0"
            @click.prevent="clickEvent"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon :color="color || icon.color" left small>
              {{ icon.icon }}
            </v-icon>
            <slot>{{ icon.title }}</slot>
          </v-btn>
        </template>
        <span v-if="toolTip">{{ toolTip }}</span>
        <span v-else>{{ icon.toolTip }}</span>
      </v-tooltip>
    </template>
  </span>
</template>
<script>
export default {
  name: 'iconDisplay',
  props: {
    icon: { type: Object, default: null },
    small: { type: Boolean, default: true },
    toolTip: { type: String, default: null },
    type: { type: String, default: 'icon' },
    strong: { type: Boolean, default: false },
    outlined: { type: Boolean, default: true },
    text: { type: Boolean, default: true },
    img: { type: String, default: '' },
    color: { type: String, default: null }
  },
  methods: {
    clickEvent() {
      this.$emit('actionClick')
    }
  }
}
</script>

<style>
.img-icon {
  height: 22px;
  cursor: pointer;
}
</style>
