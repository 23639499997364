import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getAllDossiers(type = null) {
    try {
      let url = config.apiURL + '/api/dossiers'

      if (type) {
        url += '?type=' + type
      }

      const response = await axios.get(url)

      return {
        dossiers: response.data.dossiers
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getDossier(id) {
    try {
      const response = await axios.get(config.apiURL + '/api/dossiers/' + id)

      return {
        dossier: response.data.dossier
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async createDossier(data) {
    try {
      const response = await axios.post(config.apiURL + '/api/dossiers', data)

      return {
        dossier: response.data.dossier
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async updateDossier(id, data) {
    try {
      const response = await axios.put(
        config.apiURL + '/api/dossiers/' + id,
        data
      )

      return {
        dossier: response.data.dossier
      }
    } catch (err) {
      throw createError(err)
    }
  }
  // async deleteDossier(id) {
  //   try {
  //     await axios.delete(config.apiURL + "/api/dossiers/" + id);
  //
  //     return true;
  //   } catch (err) {
  //     throw createError(err);
  //   }
  // },
}
