<template>
  <div
    class="mx-n4 mb-n12"
    style="
      height: calc(100vh - 30px);
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #eeeeee;
    "
  >
    <div
      style="
        width: 600px;
        max-width: 90vw;
        background-color: #ffffff;
        border: #cccccc;
        padding: 1rem;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
      "
    >
      <v-responsive max-width="700" class="mx-auto text-center">
        <h2 class="text-h3 text-lg-h2 mb-4">Connexion</h2>
      </v-responsive>
      <v-card class="pa-2 mx-auto" max-width="600" color="transparent" flat>
        <v-card-text>
          <v-alert type="error" v-if="error">
            {{ error }}
          </v-alert>
          <form @submit.prevent="onLogin">
            <v-text-field
              v-model="email"
              type="text"
              label="Adresse email ou nom de compte"
              solo
              outlined
            ></v-text-field>
            <v-text-field
              v-model="password"
              type="password"
              hide-details
              label="Mot de passe"
              solo
              outlined
            ></v-text-field>
            <v-btn type="submit" block class="primary mt-9" x-large
              >se Connecter</v-btn
            >
          </form>
        </v-card-text>
      </v-card>
    </div>
    <!-- <h1>Connexion</h1>
    <v-alert type="error" v-if="error">
      {{ error }}
    </v-alert>

    <form @submit.prevent="onLogin">
      <div>
        <v-text-field v-model="email" type="email" label="Adresse email" />
      </div>
      <div>
        <v-text-field v-model="password" type="password" label="Mot de passe" />
      </div>
      <div>
        <v-btn type="submit" color="dark" class="light&#45;&#45;text" block
          >Connexion</v-btn
        >
      </div>
    </form>-->
  </div>
</template>

<script>
import ABSDK, { MKPSDK } from '@/packages/artebeaute-sdk/src'

export default {
  name: 'LoginPage',
  data() {
    return {
      error: null,
      email: null,
      password: null,
    }
  },
  created() {},
  async mounted() {
    if (this.$store.state.auth.user) {
      await this.$store.dispatch('auth/logout')
    }
    // TODO: vider le store
  },
  methods: {
    async onLogin() {
      this.error = null
      try {
        const tokenResponse = await MKPSDK.CmpUsers.login(
          this.email,
          this.password
        )

        const token = tokenResponse.token

        await this.$store.dispatch('auth/login', token)

        ABSDK.setHeaders({
          authorization: 'Bearer ' + token,
        })

        this.$router.push('/')
      } catch (err) {
        this.error = 'Identifiants invalides.'
      }
    },
  },
}
</script>
