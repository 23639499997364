import { ArteBeauteSDK } from '@/packages/artebeaute-sdk/src'
import slugify from 'slugify'
import Vue from 'vue'
import store from '../index'
import axiosErrorCode from '@/helpers/axios'

export default {
  namespaced: true,
  state: () => ({
    abArticles: [],
    articles: [],
    loaded: { abArticles: null, articles: null },
    newOnlineArticle: [],
  }),
  getters: {
    stateLoaded: (state) => {
      let tabLoaded = []
      tabLoaded.push({
        chargement: 'Article Artebeaute',
        status: state.loaded.abArticles,
      })
      tabLoaded.push({
        chargement: 'Articles Liés',
        status: state.loaded.articles,
      })
      return tabLoaded
    },
    getAbArticlesById: (state) => (id) => {
      return state.abArticles.find((elt) => elt.id === id)
    },
    getAbArticles(state) {
      return state.abArticles
    },
    getArticles(state) {
      return state.articles
    },
    fusionArticle:
      (state) =>
      (filtered = null) => {
        let art = []

        for (const a of state.abArticles) {
          const myArt = {
            id: null,
            artebeauteId: a.id,
            company: null,
            artebeauteName: a.name,
            region: a.region,
            family: a.family,
            name: null,
            art_type: a.art_type,
            description: null,
            images: [],
            booker: {
              active: false,
              artebeauteLink: a.booker.articles_link,
              cabinCapacity: a.booker.cabin_capacity,
              duration: a.booker.duration,
              pause: a.booker.pause,
            },
            priceTime: a.priceTime.enabled,
            minPack: a.minpack.enabled,
            passport: { active: false },
            active: true,
            prices: a.price,
            retouche: false,
            categories: [],
            shops: [],
          }
          const ar = state.articles.find(
            (elt) => elt.artebeauteId === myArt.artebeauteId
          )
          if (ar) {
            myArt.id = ar.id
            myArt.company = ar.company
            myArt.name = ar.name
            myArt.passport = ar.passport
            myArt.images = ar.images
            myArt.shops = ar.shops
            myArt.description = ar.description
            myArt.categories = ar.categories
            myArt.booker = ar.booker
            myArt.prices = ar.prices
            myArt.retouche = ar.retouche
            myArt.slug = ar.slug
            myArt.order = ar.order
            myArt.draft = ar.draft
          }
          if (filtered && filtered.active) {
            if (filtered.art_type) {
              if (parseInt(filtered.art_type) !== parseInt(myArt.art_type)) {
                continue
              }
            }
            if (filtered.isOnline === true && !myArt.id) {
              continue
            } else if (filtered.isOnline === false && myArt.id) {
              continue
            }
            if (filtered.isOnline && !myArt) {
              continue
            } else if (filtered.isOnline) {
              const booker = filtered.onLineType.findIndex((elt) => elt === 0)
              const passport = filtered.onLineType.findIndex((elt) => elt === 1)
              if (
                (booker >= 0 && !myArt.booker.active) ||
                (passport >= 0 && !myArt.passport.active)
              ) {
                continue
              }
            }
          }
          art.push(myArt)
        }

        return art
      },
    removedArticles:
      (state) =>
      (filtered = null) => {
        let art = []

        const removedArticles = state.articles
          .filter((a) => {
            return (
              state.abArticles.findIndex((aba) => aba.id === a.artebeauteId) ===
              -1
            )
          })
          .filter((a) => !a.draft)

        for (const a of removedArticles) {
          const myArt = {
            id: a.id,
            artebeauteId: null,
            company: null,
            artebeauteName: a.name.fr,
            region: '[SUPPRIMES] ARTICLES NON EXISTANT DANS AB',
            family: '[SUPPRIMES] ARTICLES NON EXISTANT DANS AB',
            name: a.name,
            art_type: a.type,
            description: null,
            images: [],
            booker: {
              active: true,
            },
            passport: { active: false },
            active: true,
            prices: a.prices,
            retouche: false,
            categories: [],
            shops: [],
            draft: a.draft,
          }

          if (filtered && filtered.active) {
            if (filtered.art_type) {
              if (parseInt(filtered.art_type) !== parseInt(myArt.art_type)) {
                continue
              }
            }
            if (filtered.isOnline === true && !myArt.id) {
              continue
            } else if (filtered.isOnline === false && myArt.id) {
              continue
            }
            if (filtered.isOnline && !myArt) {
              continue
            } else if (filtered.isOnline) {
              const booker = filtered.onLineType.findIndex((elt) => elt === 0)
              const passport = filtered.onLineType.findIndex((elt) => elt === 1)
              if (
                (booker >= 0 && !myArt.booker.active) ||
                (passport >= 0 && !myArt.passport.active)
              ) {
                continue
              }
            }
          }
          art.push(myArt)
        }

        return art
      },
    getNewOnlineArticle: (state) => {
      state.newOnlineArticle.forEach((article) => {
        article.categories = state.articles.find(
          (a) => a.artebeauteId === article.artebeauteId
        ).categories
      })

      return state.newOnlineArticle
    },
  },
  mutations: {
    SET_ABARTICLELOAD(state, value) {
      state.loaded.abArticles = value
    },
    SET_ARTICLELOAD(state, value) {
      state.loaded.articles = value
    },
    SET_AB_ARTICLES(state, value) {
      state.abArticles = value
    },
    SET_ARTICLES(state, value) {
      state.articles = value
    },
    ADD_ARTICLE(state, value) {
      state.articles.push(value)
    },
    REMOVE_ARTICLE(state, value) {
      const articleIndex = state.articles.findIndex((a) => a.id === value.id)
      state.articles.splice(articleIndex, 1)
    },
    UPDATE_ARTICLE(state, value) {
      const articleIndex = state.articles.findIndex((a) => a.id === value.id)

      if (articleIndex !== -1) {
        const article = state.articles[articleIndex]

        Vue.set(state.articles, articleIndex, {
          ...article,
          ...value,
        })
      }
    },
    UPDATE_CATEGORY_ARTICLES(state, { value, toDelete }) {
      state.articles.map((art) => {
        if (art.categories && art.categories.length) {
          art.categories.map((cat) => {
            if (cat.id === value.id) {
              const indexArt = state.articles.findIndex((a) => a.id === art.id)
              const indexCat = art.categories.findIndex(
                (c) => c.id === value.id
              )

              if (!toDelete) {
                Vue.set(state.articles[indexArt].categories, indexCat, value)
              } else {
                state.articles[indexArt].categories.splice(indexCat, 1)
              }
            }
          })
        }
      })
    },
    SORT_ARTICLES(state, sortData) {
      state.articles.forEach((article) => {
        if (sortData.find((s) => s.id === article.id)) {
          article.order = sortData.find((s) => s.id === article.id).order
        }
      })
    },
    UPDATE_NEWONLINEARTICLE(state, { value, isDelete, getters }) {
      if (isDelete) {
        if (
          state.newOnlineArticle.find(
            (s) => s.artebeauteId === value.artebeauteId
          )
        ) {
          state.newOnlineArticle.splice(
            state.newOnlineArticle.findIndex(
              (s) => s.artebeauteId === value.artebeauteId
            ),
            1
          )
        }
      } else {
        const articles = getters.fusionArticle()

        const articleToPush = articles.find(
          (a) => a.artebeauteId === value.artebeauteId
        )
        state.newOnlineArticle.push(articleToPush)
      }
    },
  },
  actions: {
    async fetchAbArticles({ commit, state }) {
      if (!store.state.auth.company.artebeaute.active) {
        commit('SET_AB_ARTICLES', [])
        commit('SET_ABARTICLELOAD', 'loaded')
        return
      }

      if (state.abArticles.length) {
        return state.abArticles
      }

      try {
        commit('SET_ABARTICLELOAD', -1)
        const response = await ArteBeauteSDK.Articles.getAllAbArticles()

        commit('SET_AB_ARTICLES', response.articles)
        commit('SET_ABARTICLELOAD', 'loaded')
      } catch (err) {
        commit('SET_ABARTICLELOAD', 'error')
        return []
      }
    },
    async fetchArticles({ commit, state }) {
      if (state.articles.length) {
        return state.articles
      }

      try {
        commit('SET_ARTICLELOAD', -1)
        const response = await ArteBeauteSDK.Articles.getAllArticles()

        commit('SET_ARTICLES', response.articles)
        commit('SET_ARTICLELOAD', 'loaded')
      } catch (err) {
        commit('SET_ARTICLELOAD', 'error')
        return []
      }
    },
    async updateArticle({ commit }, { article, data }) {
      try {
        const response = await ArteBeauteSDK.Articles.updateArticle(
          article.id,
          data
        )

        commit('UPDATE_ARTICLE', response.article)

        return JSON.parse(JSON.stringify(response.article))
      } catch (err) {
        console.log('err :>> ', err)
        return {
          error: true,
          message:
            'Une erreur est survenue lors de la sauvegarde de la boutique.',
          code: axiosErrorCode(err),
        }
      }
    },
    async publishArticle({ commit, dispatch, getters }, abArticle) {
      try {
        const response = await ArteBeauteSDK.Articles.createArticle({
          artebeauteId: abArticle.id,
          name: { fr: abArticle.name },
          slug: slugify(abArticle.name).toLowerCase(),
          type: abArticle.art_type.toString(),
          description: { short: {}, long: {} },
          booker: {
            active: abArticle.booker.enabled,
            articles_link: abArticle.booker.articles_link,
            duration: abArticle.booker.duration,
            cabin_capacity: abArticle.booker.cabin_capacity,
            pause: abArticle.booker.pause,
            appointment: {
              nbCustomerRequired: 1,
              nbEmployeeRequired: 1,
              percent: 100,
              showAlerts: false,
            },
          },
          passport: { active: false },
          images: [],
          active: true,
          prices: parseFloat(abArticle.price),
          retouche: false,
        })

        commit('ADD_ARTICLE', { ...response.article, draft: true })
        dispatch('shop/getShops', null, { root: true })
        dispatch('shop/getAbShops', null, { root: true })

        commit('UPDATE_NEWONLINEARTICLE', {
          value: { ...response.article, draft: true },
          getters,
        })
      } catch (err) {
        console.log('err :>> ', err)
        return []
      }
    },
    async unpublishArticle({ commit }, article) {
      try {
        const response = await ArteBeauteSDK.Articles.deleteArticle(article.id)

        commit('REMOVE_ARTICLE', response.article)

        commit('UPDATE_NEWONLINEARTICLE', { value: article, isDelete: true })
      } catch (err) {
        console.log('err :>> ', err)
        return []
      }
    },
    async undraftArticle({ commit }, article) {
      try {
        await ArteBeauteSDK.Articles.undraftArticle(article.id)

        commit('UPDATE_ARTICLE', {
          ...article,
          draft: false,
        })
      } catch (err) {
        console.log('err :>> ', err)
        return []
      }
    },
    async draftArticle({ commit }, article) {
      try {
        await ArteBeauteSDK.Articles.draftArticle(article.id)

        commit('UPDATE_ARTICLE', {
          ...article,
          draft: true,
        })
      } catch (err) {
        console.log('err :>> ', err)
        return []
      }
    },
    async linkAllShops({ commit, rootState }, article) {
      try {
        const promises = await Promise.all(
          rootState.shop.shops.map((shop) =>
            ArteBeauteSDK.Shops.linkArticle(shop.id, article.id)
          )
        )

        promises.forEach((response) => {
          commit('UPDATE_ARTICLE', response.article)
          commit('shop/UPDATE_SHOP', response.shop, { root: true })
        })

        // for (let i = 0; i < rootState.shop.shops.length; i++) {
        //   const response = await ArteBeauteSDK.Shops.linkArticle(
        //     rootState.shop.shops[i].id,
        //     article.id
        //   )

        // }
      } catch (err) {
        console.log('err :>> ', err)
      }
    },
    async unlinkAllShops({ commit, rootState }, article) {
      try {
        const promises = await Promise.all(
          rootState.shop.shops.map((shop) =>
            ArteBeauteSDK.Shops.unlinkArticle(shop.id, article.id)
          )
        )

        promises.forEach((response) => {
          commit('UPDATE_ARTICLE', response.article)
          commit('shop/UPDATE_SHOP', response.shop, { root: true })
        })

        // for (let i = 0; i < rootState.shop.shops.length; i++) {
        //   const response = await ArteBeauteSDK.Shops.unlinkArticle(
        //     rootState.shop.shops[i].id,
        //     article.id
        //   )

        //   console.log('response.article :>> ', response.article)

        //   commit('UPDATE_ARTICLE', response.article)
        //   commit('shop/UPDATE_SHOP', response.shop, { root: true })
        // }
      } catch (err) {
        console.log('err :>> ', err)
      }
    },
  },
}
