import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getAllConsentements() {
    try {
      const response = await axios.get(config.apiURL + '/api/consentements')

      return {
        consentements: response.data.consentements
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getConsentement(id) {
    try {
      const response = await axios.get(
        config.apiURL + '/api/consentements/' + id
      )

      return {
        consentement: response.data.consentement
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async createConsentement(data) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/consentements',
        data
      )

      return {
        consentement: response.data.consentement
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async updateConsentement(id, data) {
    try {
      const response = await axios.put(
        config.apiURL + '/api/consentements/' + id,
        data
      )

      return {
        consentement: response.data.consentement
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async deleteConsentement(id) {
    try {
      await axios.delete(config.apiURL + '/api/consentements/' + id)

      return true
    } catch (err) {
      throw createError(err)
    }
  }
}
