import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getToken(nickname) {
    try {
      const response = await axios.get(
        config.apiURL + '/api/companies/token/' + nickname
      )

      return {
        token: response.data.token,
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async me() {
    try {
      const response = await axios.get(config.apiURL + '/api/companies/me')

      return {
        company: response.data.company,
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getAllCompanies() {
    try {
      const response = await axios.get(config.apiURL + '/api/companies')

      return {
        companies: response.data.companies,
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getCompany(id) {
    try {
      const response = await axios.get(config.apiURL + '/api/companies/' + id)

      return {
        company: response.data.company,
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async createCompany(data) {
    try {
      const response = await axios.post(config.apiURL + '/api/companies', data)

      return {
        company: response.data.company,
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async updateCompany(id, data) {
    try {
      const response = await axios.put(
        config.apiURL + '/api/companies/' + id,
        data
      )

      return {
        company: response.data.company,
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async deleteCompany(id) {
    try {
      const response = await axios.delete(
        config.apiURL + '/api/companies/' + id
      )

      return {
        company: response.data.company,
      }
    } catch (err) {
      throw createError(err)
    }
  },
}
