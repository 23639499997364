import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getAllPublicCollectionSchemas() {
    try {
      const response = await axios.get(
        config.apiURL + '/api/collections/schemas/public'
      )

      return {
        collectionSchemas: response.data.collectionSchemas
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getPublicCollectionSchema(id) {
    try {
      const response = await axios.get(
        config.apiURL + '/api/collections/schemas/public/' + id
      )

      return {
        collectionSchema: response.data.collectionSchema
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async createPublicCollectionSchema(data) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/collections/schemas/public',
        data
      )

      return {
        collectionSchema: response.data.collectionSchema
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async updatePublicCollectionSchema(id, data) {
    try {
      const response = await axios.put(
        config.apiURL + '/api/collections/schemas/public/' + id,
        data
      )

      return {
        collectionSchema: response.data.collectionSchema
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async deletePublicCollectionSchema(id) {
    try {
      const response = await axios.delete(
        config.apiURL + '/api/collections/schemas/public/' + id
      )

      return {
        collectionSchema: response.data.collectionSchema
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getAllCollectionSchemas() {
    try {
      const response = await axios.get(
        config.apiURL + '/api/collections/schemas'
      )

      return {
        collectionSchemas: response.data.collectionSchemas
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getCollectionSchema(id) {
    try {
      const response = await axios.get(
        config.apiURL + '/api/collections/schemas/' + id
      )

      return {
        collectionSchema: response.data.collectionSchema
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async createCollectionSchema(data) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/collections/schemas',
        data
      )

      return {
        collectionSchema: response.data.collectionSchema
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async updateCollectionSchema(id, data) {
    try {
      const response = await axios.put(
        config.apiURL + '/api/collections/schemas/' + id,
        data
      )

      return {
        collectionSchema: response.data.collectionSchema
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async deleteCollectionSchema(id) {
    try {
      const response = await axios.delete(
        config.apiURL + '/api/collections/schemas/' + id
      )

      return {
        collectionSchema: response.data.collectionSchema
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getAllCollectionItems(type) {
    try {
      const response = await axios.get(
        config.apiURL + '/api/collections/items/type/' + type
      )

      return {
        collectionItems: response.data.collectionItems
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getCollectionItem(id) {
    try {
      const response = await axios.get(
        config.apiURL + '/api/collections/items/' + id
      )

      return {
        collectionItem: response.data.collectionItem
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async createCollectionItem(data) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/collections/items',
        data
      )

      return {
        collectionItem: response.data.collectionItem
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async updateCollectionItem(id, data) {
    try {
      const response = await axios.put(
        config.apiURL + '/api/collections/items/' + id,
        data
      )

      return {
        collectionItem: response.data.collectionItem
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async deleteCollectionItem(id) {
    try {
      const response = await axios.delete(
        config.apiURL + '/api/collections/items/' + id
      )

      return {
        collectionItem: response.data.collectionItem
      }
    } catch (err) {
      throw createError(err)
    }
  }
}
