import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getAllPostCategories() {
    try {
      const response = await axios.get(config.apiURL + '/api/postcategories')

      return {
        postCategories: response.data.postCategories
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getPostCategory(id) {
    try {
      const response = await axios.get(
        config.apiURL + '/api/postcategories/' + id
      )

      return {
        postCategory: response.data.postCategory
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async createPostCategory(data) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/postcategories',
        data
      )

      return {
        postCategory: response.data.postCategory
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async updatePostCategory(id, data) {
    try {
      const response = await axios.put(
        config.apiURL + '/api/postcategories/' + id,
        data
      )

      return {
        postCategory: response.data.postCategory
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async deletePostCategory(id) {
    try {
      const response = await axios.delete(
        config.apiURL + '/api/postcategories/' + id
      )

      return {
        postCategory: response.data.postCategory
      }
    } catch (err) {
      throw createError(err)
    }
  }
}
