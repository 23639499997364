export default function() {
  return {
    values: [
      // {
      //   title: 'Recherche',
      //   img: require('../assets/icons/zoom.svg')
      // },
      {
        title: 'Accueil',
        img: require('../assets/icons/home.svg'),
        to: '/',
        icon: 'mdi-home'
      },
      {
        title: 'Compte',
        img: require('../assets/icons/user.svg'),
        action: 'auth',
        icon: 'mdi-account'
      },
      {
        title: 'Paramètres',
        img: require('../assets/icons/settings.svg'),
        to: '/parametrages',
        icon: 'mdi-cog',
        acl: []
      }
      // {
      //   title: 'Notifications',
      //   img: require('../assets/icons/bell.svg'),
      //   action: 'notifications',
      //   icon: 'mdi-bell'
      // }
      // {
      //   title: 'Menu',
      //   img: require('../assets/icons/points-burger.svg'),
      //   action: 'menu',
      //   responsive: true
      // }
    ],
    getIcons() {
      return this.values
    }
  }
}
