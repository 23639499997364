// import axios from "axios";
// import { createError } from "../helpers/errors";
// import config from "../config";

export default {
  // TODO: REMOVE THIS FILE
  // async getCompany(id) {
  //   try {
  //     const response = await axios.get(
  //       config.apiURL + "/api/admin/companies/" + id
  //     );
  //     return {
  //       company: response.data.company,
  //     };
  //   } catch (err) {
  //     throw createError(err);
  //   }
  // },
  // async updateCompany(id, data) {
  //   try {
  //     const response = await axios.put(
  //       config.apiURL + "/api/admin/companies/" + id,
  //       data
  //     );
  //     return {
  //       company: response.data.company,
  //     };
  //   } catch (err) {
  //     throw createError(err);
  //   }
  // },
  // async deleteCompany(id) {
  //   try {
  //     const response = await axios.delete(
  //       config.apiURL + "/api/admin/companies/" + id
  //     );
  //     return {
  //       company: response.data.company,
  //     };
  //   } catch (err) {
  //     throw createError(err);
  //   }
  // },
}
