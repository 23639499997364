<template>
  <v-card>
    <v-sheet color="primary" class="titleFixed">
      <v-card-title>
        <h3 class="white--text">{{ title }}</h3>
        <v-spacer />
      </v-card-title>
      <v-divider />
    </v-sheet>
    <v-card-text>
      <slot name="actionModalContent" />
    </v-card-text>
    <v-sheet color="#f0f0f0" class="actionFixed">
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <icon-display @actionClick="close" type="flat" :icon="$icones.cancel" />
        <icon-display
          :strong="true"
          @actionClick="validModal"
          type="flat"
          :icon="$icones.submit"
        />
      </v-card-actions>
    </v-sheet>
  </v-card>
</template>

<script>
import iconDisplay from './iconDisplay.vue'
import icones from '@/helpers/icones'

export default {
  name: 'actionModal',
  components: { iconDisplay },
  props: {
    title: { type: String, default: 'Titre' },
    icon: { type: Object, default: null },
    fullScreen: { type: Boolean, default: false },
  },
  computed: {
    $icones() {
      return icones().getIcones()
    },
  },
  methods: {
    validModal() {
      this.$emit('actionModal', true)
    },
    close() {
      this.$emit('actionModal', false)
    },
  },
}
</script>

<style>
.titleFixed {
  position: sticky;
  background-color: #f0f0f0;
  top: 0;
  z-index: 999999;
}
.actionFixed {
  position: sticky;
  background-color: #f0f0f0;
  bottom: 0;
}
</style>
