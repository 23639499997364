import login from './auth/login'
import register from './auth/register'
import users from './auth/users'
import links from './links/links'
import profiles from './profiles/profiles'
import admins from './admin/admins'
import adminCompanies from './admin/companies'
import adminCmpUsers from './admin/cmp-users'
import companies from './companies/companies'
import databases from './databases/databases'
import cmpUsers from './cmp-users/cmp-users'
import servers from './servers/servers'
import blogPostCategories from './blog/postcategories'
import blogPosts from './blog/posts'
import payment from './payment/payment'
import bookerShop from './booker/eshop'
import remises from './remises/remises'
import gift from './gift/gift'
import cart from './cart/cart'
import config from './config'
import releases from './releases/releases'
import maintenance from './maintenance/maintenance'
import event from './events/events'
import alert from './alerts/alerts'
import closedDays from './closeddays/closeddays'
import coupons from './coupons/coupons'

export default {
  Config: config,
  Auth: {
    ...login,
    ...register,
    ...users,
  },
  Links: {
    ...links,
  },
  Profiles: {
    ...profiles,
  },
  Admin: {
    ...admins,
    ...adminCompanies,
    ...adminCmpUsers,
  },
  Companies: {
    ...companies,
  },
  CmpUsers: {
    ...cmpUsers,
  },
  Servers: {
    ...servers,
  },
  Databases: {
    ...databases,
  },
  Blog: {
    ...blogPostCategories,
    ...blogPosts,
  },
  Payment: {
    ...payment,
  },
  Booker: {
    ...bookerShop,
  },
  Remises: {
    ...remises,
  },
  Gift: {
    ...gift,
  },
  Cart: {
    ...cart,
  },
  Releases: {
    ...releases,
  },
  Maintenance: {
    ...maintenance,
  },
  Event: {
    ...event,
  },
  Alert: {
    ...alert,
  },
  ClosedDays: {
    ...closedDays,
  },
  Coupons: {
    ...coupons,
  },
  initialize(config) {
    Object.keys(config).forEach((key) => {
      this.Config[key] = config[key]
    })
  },
}
