import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getAllGifts() {
    try {
      const response = await axios.get(config.apiURL + '/api/maintenance/gifts')

      return response.data
    } catch (err) {
      throw createError(err)
    }
  },
  async resolveGift(cartId) {
    if (!confirm('Etes-vous sûr ?')) {
      return
    }

    try {
      const response = await axios.post(config.apiURL + '/api/maintenance/gifts/' + cartId)

      return response.data
    } catch (err) {
      throw createError(err)
    }
  },
}
