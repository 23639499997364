import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async createLink(data) {
    try {
      const response = await axios.post(config.apiURL + '/api/links', data)

      return response.data.link
    } catch (err) {
      throw createError(err)
    }
  },
  async getLinkByUserId(userId) {
    try {
      const response = await axios.get(
        config.apiURL + '/api/links/get?userId=' + userId
      )

      return response.data.link
    } catch (err) {
      throw createError(err)
    }
  }
  // async linksByAuth(authID) {
  //   try {
  //     const response = await axios.get(
  //       config.apiURL + "/api/links/get?authID=" + authID
  //     );

  //     return response.data.link;
  //   } catch (err) {
  //     throw createError(err);
  //   }
  // },
  // async createLink(profile, authID, status = "complete", profiles = []) {
  //   try {
  //     const response = await axios.post(config.apiURL + "/api/links", {
  //       customerID: profile.id.toString(),
  //       authID: authID.toString(),
  //       status,
  //       profiles,
  //       info: profile,
  //     });

  //     return response.data.link;
  //   } catch (err) {
  //     throw createError(err);
  //   }
  // },
}
