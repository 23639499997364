import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getAllEvents() {
    try {
      const response = await axios.get(config.apiURL + '/api/events')

      return {
        events: response.data.events
      }
    } catch (err) {
      throw createError(err)
    }
  },

  async createEvent(data) {
    try {
      const response = await axios.post(config.apiURL + '/api/events', data)

      return {
        event: response.data.event
      }
    } catch (err) {
      throw createError(err)
    }
  },

  async updateEvent(id, data) {
    try {
      const response = await axios.put(
        config.apiURL + '/api/events/' + id,
        data
      )

      return {
        event: response.data.event
      }
    } catch (err) {
      throw createError(err)
    }
  },

  async deleteEvent(id) {
    try {
      const response = await axios.delete(config.apiURL + '/api/events/' + id)

      return {
        event: response.data.event
      }
    } catch (err) {
      throw createError(err)
    }
  }
}
