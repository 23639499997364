import statbooking from './statistiques/booking'
import statgift from './statistiques/gift'
import statShops from './statistiques/shops'

import config from './config'

export default {
  Config: config,
  StatBooking: {
    ...statbooking
  },
  StatShops: {
    ...statShops
  },
  StatGift: {
    ...statgift
  },
  initialize(config) {
    Object.keys(config).forEach(key => {
      this.Config[key] = config[key]
    })
  }
}
