import { ArteBeauteSDK } from '@/packages/artebeaute-sdk/src'
import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    categories: []
  }),
  getters: {
    getCategories(state) {
      return state.categories
    }
  },
  mutations: {
    SET_CATEGORIES(state, value) {
      state.categories = value
    },
    ADD_CATEGORY(state, value) {
      state.categories.push(value)
    },
    UPDATE_CATEGORY(state, { value, fromUpdate }) {
      const categoryIndex = state.categories.findIndex(c => c.id === value.id)

      if (categoryIndex !== -1) {
        if (fromUpdate) {
          Vue.set(state.categories, categoryIndex, {
            ...value,
            articles: state.categories[categoryIndex].articles
          })
        } else {
          Vue.set(state.categories, categoryIndex, value)
        }
      }
    },
    DELETE_CATEGORY(state, value) {
      const categoryIndex = state.categories.findIndex(c => c.id === value.id)

      if (categoryIndex !== -1) {
        state.categories.splice(categoryIndex, 1)
      }
    },
    SET_ORDER_CATEGORIES(state, value) {
      state.categories.map((cat, index) => {
        const indexData = value.findIndex(v => v.id === cat.id)
        Vue.set(state.categories, index, {
          ...cat,
          order: value[indexData].order
        })
      })
    }
  },
  actions: {
    async fetchCategories({ commit, state }) {
      if (state.categories.length) {
        return state.categories
      }

      try {
        const response = await ArteBeauteSDK.Categories.getAllCategories()

        commit('SET_CATEGORIES', response.categories)
      } catch (err) {
        return []
      }
    },
    async createCategory({ commit }, data) {
      try {
        const response = await ArteBeauteSDK.Categories.createCategory(data)

        commit('ADD_CATEGORY', response.category)

        return true
      } catch (err) {
        return false
      }
    },

    async updateCategory({ commit }, { id, data }) {
      try {
        const response = await ArteBeauteSDK.Categories.updateCategory(id, data)
        commit('UPDATE_CATEGORY', {
          value: response.category,
          fromUpdate: true
        })
        commit(
          'articles/UPDATE_CATEGORY_ARTICLES',
          { value: response.category, toDelete: false },
          {
            root: true
          }
        )

        return response
      } catch (err) {
        return false
      }
    },
    async deleteCategory({ commit }, { id }) {
      try {
        const response = await ArteBeauteSDK.Categories.deleteCategory(id)
        commit('DELETE_CATEGORY', response.category)
        commit(
          'articles/UPDATE_CATEGORY_ARTICLES',
          { value: response.category, toDelete: true },
          {
            root: true
          }
        )

        return true
      } catch (err) {
        return false
      }
    },
    async linkToArticle({ commit }, { category, article }) {
      try {
        const response = await ArteBeauteSDK.Categories.linkArticle(
          category.id,
          article.id
        )

        response.category.articles.push(article)

        commit('UPDATE_CATEGORY', { value: response.category })
        commit('articles/UPDATE_ARTICLE', response.article, { root: true })

        return response
      } catch (err) {
        console.log('err :>> ', err)
        return false
      }
    },
    async unlinkFromArticle({ commit }, { category, article }) {
      try {
        const response = await ArteBeauteSDK.Categories.unlinkArticle(
          category.id,
          article.id
        )

        const indexArt = response.category.articles.findIndex(
          a => article.id === a.id
        )

        response.category.articles.splice(indexArt, 1)

        commit('UPDATE_CATEGORY', { value: response.category })
        commit('articles/UPDATE_ARTICLE', response.article, { root: true })

        return response
      } catch (err) {
        console.log('err :>> ', err)
        return false
      }
    },

    async updateOrders({ commit }, { data }) {
      const result = await ArteBeauteSDK.Categories.updateOrders(data)

      if (result) {
        commit('SET_ORDER_CATEGORIES', data)
      } else {
        console.log('error update orders categories')
      }
    }
  }
}
