import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async me(token = null) {
    try {
      const headers = {}

      if (token) {
        headers.authorization = 'Bearer ' + token
      }

      const response = await axios.get(config.apiURL + '/api/users/me', {
        headers
      })

      return { user: response.data.user }
    } catch (err) {
      throw createError(err)
    }
  },
  async getAllUsers() {
    try {
      const response = await axios.get(config.apiURL + '/api/users')

      return { users: response.data.users }
    } catch (err) {
      throw createError(err)
    }
  },
  async getPaginatedUsers(options) {
    try {
      const response = await axios.get(config.apiURL + '/api/users/paginated', {
        params: options
      })

      return {
        users: response.data.users,
        total: response.data.total,
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getByToken(token) {
    try {
      const response = await axios.get(
        config.apiURL + '/api/users/token/' + token
      )

      return { user: response.data.user }
    } catch (err) {
      throw createError(err)
    }
  },
  async updatePassword(data) {
    try {
      const response = await axios.put(
        config.apiURL + '/api/users/password',
        data
      )

      return { user: response.data.user }
    } catch (err) {
      throw createError(err)
    }
  },
  async forgotPassword(data) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/users/password/reset',
        data
      )

      return { user: response.data.user }
    } catch (err) {
      throw createError(err)
    }
  },
  async resetPassword(token, data) {
    try {
      const response = await axios.put(
        config.apiURL + '/api/users/token/' + token,
        data
      )

      return { user: response.data.user }
    } catch (err) {
      throw createError(err)
    }
  },
  async linkProvider(data) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/users/link/provider',
        data
      )

      return { user: response.data.user }
    } catch (err) {
      throw createError(err)
    }
  },
  async unlinkProvider(data) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/users/unlink/provider',
        data
      )

      return { user: response.data.user }
    } catch (err) {
      throw createError(err)
    }
  }
}
