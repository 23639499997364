import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { icones } from '@/helpers/icones.js'
import VueMeta from 'vue-meta'

import VueLazyLoad from 'vue-lazyload'
import { MazPhoneNumberInput } from 'maz-ui'

import 'leaflet/dist/leaflet.css'
import i18n from './i18n'

import VueApexCharts from 'vue-apexcharts'

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false
Vue.prototype.$icones = icones

Vue.use(VueLazyLoad)
Vue.use(MazPhoneNumberInput)
Vue.use(VueMeta)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
