import axios from 'axios'
import _MKPSDK from './mkp/sdk'
import _PassportSDK from './passport/sdk'
import _ArteBeauteSDK from './artebeaute/sdk'
import _StorageSDK from './storage/sdk'
import _VaultSDK from './vault/sdk'
import _StatsSDK from './stats/sdk'
import _CaSDK from './ca/sdk'

export const MKPSDK = _MKPSDK
export const PassportSDK = _PassportSDK
export const ArteBeauteSDK = _ArteBeauteSDK
export const StorageSDK = _StorageSDK
export const VaultSDK = _VaultSDK
export const StatsSDK = _StatsSDK
export const CaSDK = _CaSDK

export default {
  setHeaders(headers) {
    Object.keys(headers).forEach(key => {
      axios.defaults.headers[key] = headers[key]
    })
  }
}
