import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getMediaLibrary() {
    try {
      const response = await axios.get(config.apiURL + '/medias')

      return {
        uploads: response.data.uploads
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async uploadImage(formData, directory) {
    try {
      let url = config.apiURL + '/upload/image'

      if (directory) {
        url += '?dir=' + directory
      }
      const response = await axios.post(url, formData)

      return {
        url: response.data.url
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async uploadImages(formData, directory) {
    try {
      let url = config.apiURL + '/upload/images'

      if (directory) {
        url += '?dir=' + directory
      }
      const response = await axios.post(url, formData)

      return {
        data: response.data
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getBanque() {
    try {
      const response = await axios.get(config.apiURL + '/banque')

      return {
        data: response.data.data
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getBanqueByNickname(nickname) {
    try {
      const response = await axios.get(config.apiURL + '/banque/' + nickname)

      return {
        data: response.data.data
      }
    } catch (err) {
      throw createError(err)
    }
  }
}
