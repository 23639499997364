import {CaSDK} from '@/packages/artebeaute-sdk/src'
import axiosErrorCode from '@/helpers/axios'
import {Indicator} from '../../helpers/statObject'
import dayjs from "dayjs";
require('dayjs/locale/fr')
dayjs.locale('fr')
export default {
    namespaced: true,
    state: () => ({
        indicator: {
            day: {
                current: new Indicator(),
                previousDay: new Indicator(),
                previousWeek: new Indicator(),
                previousMonth: new Indicator(),
                previousYear: new Indicator(),
            },
            week: {
                current: new Indicator(),
                previousWeek: new Indicator(),
                previousMonth: new Indicator(),
                previousYear: new Indicator(),
            },
            Month: {
                current: new Indicator(),
                previousWeek: new Indicator(),
                previousMonth: new Indicator(),
                previousYear: new Indicator(),
            }
        },
        realized: [],
        currentRange: {
            periode: {
                start:dayjs().locale('fr').format('YYYY-MM-DD'),
                end:dayjs().locale('fr').format('YYYY-MM-DD')
            },
            compareTo:{
                active: false,
                start: dayjs().locale('fr').subtract(1,'day').format('YYYY-MM-DD'),
                end: dayjs().locale('fr').subtract(1,'day').format('YYYY-MM-DD'),
            }
        }
    }),
    getters: {
        getIndicatorCharged: state => value => {
            return state.indicator[value.moment][value.name].charged
        },
        getIndicatorTotalTtc: state => value => {
            return state.indicator[value.moment][value.name].totalTTC
        },
        getIsChargedR : state=> value=>{
            const indic = state.realized.find((e)=>{
                return e.start === value.start && e.end===value.end && e.shops === value.shops && e.chargedR === true
            })
            if(indic){
                return true
            }else {
                return false
            }
        },
        getIsChargedC : state=> value=>{
            const indic = state.realized.find((e)=>{
                return e.start === value.start && e.end===value.end && e.shops === value.shops  && e.chargedC === true
            })
            if(indic){
                return true
            }else {
                return false
            }
        },
        getRealized : state=> value=>{
            const indic = state.realized.find((e)=>{
                return e.start === value.start && e.end===value.end  && e.shops === value.shops && e[value.charge] === true
            })
            if(indic){
                return indic[value.value]
            }else {
                return null
            }
        },
        getCash : state=> value=>{
            const indic = state.realized.find((e)=>{
                return e.start === value.start && e.end===value.end && e.shops === value.shops && e[value.charge] === true
            })
            if(indic){
                return indic[value.value]
            }else {
                return null
            }
        }
    },
    mutations: {
        GET_REALIZED(state, value) {
            const i = state.realized.findIndex((e)=>{
                return e.start === value.start && e.end===value.end && e.type === value.type && e.shops === value.shops
            })
            if(i === -1){
                const indic = new Indicator()
                indic.chargedR = true
                indic.type = value.type
                indic.start = value.start
                indic.end = value.end
                indic.totalHT = value.data.m_total.m_totalHt
                indic.totalTTC = value.data.m_total.m_totalTtc
                indic.detailsR = value.data.tab_ca
                indic.shops = value.shops
                state.realized.push(indic)
            }else {
                state.realized[i].type=value.type
                state.realized[i].chargedR= true
                state.realized[i].totalHT=value.data.m_total.m_totalHt
                state.realized[i].totalTTC=value.data.m_total.m_totalTtc
                state.realized[i].detailsR=value.data.tab_ca
            }
        },
        GET_CASH(state, value) {
            const i = state.realized.findIndex((e)=>{
                return e.start === value.start && e.end===value.end && e.type === value.type && e.shops === value.shops
            })
            if(i === -1){
                const indic = new Indicator()
                indic.chargedC = true
                indic.type = value.type
                indic.start = value.start
                indic.end = value.end
                indic.cash = value.data.m_total
                indic.shops = value.shops
                indic.detailsC = value.data.tab_cashop
                state.realized.push(indic)
            }else {
                state.realized[i].type=value.type
                state.realized[i].chargedC= true
                state.realized[i].cash=value.data.m_total
                state.realized[i].detailsC=value.data.tab_cashop

            }
        },
        GET_CURRENTRANGE(state,value){
            state.currentRange = value
        }
    },
    actions: {
        getCurrentRange({commit},payload){
            commit('GET_CURRENTRANGE',payload)
        },
        async getRealized({commit}, payload) {
            try {
                const rep = await CaSDK.Indicator.indicatorRealizedByShop(
                    {
                        start: payload.start,
                        end: payload.end,
                        shopIds: payload.shopIds
                    }
                )
                commit('GET_REALIZED', {
                    data: rep.indRealized,
                    type: payload.type,
                    start: payload.start,
                    end: payload.end,
                    name: payload.name,
                    shops: payload.shopIds
                })
            } catch (e) {
                return {
                    error: true,
                    message:
                        'Une erreur est survenue la récupération du ca réalisé',
                    code: axiosErrorCode(e)
                }
            }
        },
        async getCash({commit}, payload) {
            try {
                const rep = await CaSDK.Indicator.indicatorCashByShop(
                    {
                        start: payload.start,
                        end: payload.end,
                        shopIds: payload.shopIds
                    }
                )
                console.log(rep.indCash)
                commit('GET_CASH', {
                    data: rep.indCash,
                    type: payload.type,
                    start: payload.start,
                    end: payload.end,
                    name: payload.name,
                    shops: payload.shopIds
                })
            } catch (e) {
                return {
                    error: true,
                    message:
                        'Une erreur est survenue la récupération du ca encaissé',
                    code: axiosErrorCode(e)
                }
            }
        },
    }
}