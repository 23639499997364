import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getAllAccess() {
    try {
      const response = await axios.get(config.apiURL + '/api/access')

      return {
        accesses: response.data.accesses
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async register(data) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/access/u/register',
        data
      )

      return {
        access: response.data.access
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async update(id, data) {
    try {
      const response = await axios.put(
        config.apiURL + '/api/access/u/register/' + id,
        data
      )

      return {
        access: response.data.access
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async login(credentials) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/access/login',
        credentials
      )

      return {
        token: response.data.token
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async deleteAccess(id) {
    try {
      await axios.delete(config.apiURL + '/api/access/' + id)

      return true
    } catch (err) {
      throw createError(err)
    }
  },
  async me(token) {
    try {
      const response = await axios.get(
        config.apiURL + '/api/access/me/' + token
      )

      return response.data.data
    } catch (err) {
      throw createError(err)
    }
  }
}
