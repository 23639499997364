import reglement from './reglement/reglement'
import advise from './advise/advise'
import aggregate from './aggregates/aggregates'
import indicator from './indicator/indicator'
import cashier from './cashier/cashier'

import config from './config'

export default {
  Config: config,
  Reglement: {
    ...reglement
  },
  Advise: {
    ...advise
  },
  Aggregate: {
    ...aggregate
  },
  Indicator: {
    ...indicator
  },
  Cashier: {
    ...cashier
  },
  initialize(config) {
    Object.keys(config).forEach(key => {
      this.Config[key] = config[key]
    })
  }
}
