export default {
  namespaced: true,
  state: () => ({
    links: []
  }),
  mutations: {
    SET_LINKS(state, value) {
      state.links = value
    }
  },
  actions: {
    async setLinks({ commit }, links) {
      try {
        commit('SET_LINKS', links)
      } catch (err) {
        return []
      }
    }
  }
}
