// import colors from 'vuetify/lib/util/colors'
import i18n from '../i18n'

export default function() {
  return {
    values: [
      {
        index: 0,
        title: i18n.t('database.yourShops'),
        icon: 'mdi-store',
        description: i18n.t('database.shopsManagement'),
        // color: colors.pink.accent1,
        color: '#1D4ED8',
        to: '/database/shops',
        category: i18n.t('database.shops'),
        navBottom: [
          {
            to: '/',
            icon: 'mdi-home',
            tooltip: i18n.t('database.backToHome')
          }
        ]
        // acl: []
      },
      {
        title: i18n.t('database.articles'),
        icon: 'mdi-alpha-a-circle-outline',
        description: i18n.t('database.articlesManagement'),
        // color: colors.blue.accent1,
        color: '#B45309',
        to: '/database/articles',
        category: i18n.t('database.articles'),
        navBottom: [
          {
            to: '/',
            icon: 'mdi-home',
            tooltip: i18n.t('database.backToHome')
          }
        ],
        acl: []
      },
      {
        title: i18n.t('database.categories'),
        icon: 'mdi-alpha-c-circle-outline',
        description: i18n.t('database.resourcesManagement'),
        // color: colors.blue.accent1,
        color: '#B45309',
        to: '/database/categories',
        category: i18n.t('database.articles'),
        navBottom: [
          {
            to: '/',
            icon: 'mdi-home',
            tooltip: i18n.t('database.backToHome')
          }
        ],
        commingSoon: true,
        acl: []
      },
      {
        title: i18n.t('database.resources'),
        icon: 'mdi-alpha-r-circle-outline',
        description: i18n.t('database.articlesManagement'),
        // color: colors.blue.accent1,
        color: '#B45309',
        to: '/database/ressources',
        category: i18n.t('database.articles'),
        navBottom: [
          {
            to: '/',
            icon: 'mdi-home',
            tooltip: i18n.t('database.backToHome')
          }
        ],
        commingSoon: true,
        acl: []
      },
      {
        title: i18n.t('database.needles'),
        icon: 'mdi-needle',
        description: i18n.t('database.needlesManagement'),
        // color: colors.blue.accent1,
        color: '#BE185D',
        to: '/database/aiguilles',
        category: i18n.t('database.passport'),
        navBottom: [
          {
            to: '/',
            icon: 'mdi-home',
            tooltip: i18n.t('database.backToHome')
          }
        ],
        commingSoon: true,
        acl: []
      },
      {
        title: i18n.t('database.pigments'),
        icon: 'mdi-alpha-p-circle-outline',
        description: i18n.t('database.pigmentsManagement'),
        // color: colors.blue.accent1,
        color: '#BE185D',
        to: '/database/pigments',
        category: i18n.t('database.passport'),
        navBottom: [
          {
            to: '/',
            icon: 'mdi-home',
            tooltip: i18n.t('database.backToHome')
          }
        ],
        commingSoon: true,
        acl: []
      },
      {
        title: i18n.t('database.consents'),
        icon: 'mdi-clipboard-arrow-down-outline',
        description: i18n.t('database.consentsManagement'),
        // color: colors.blue.accent1,
        color: '#BE185D',
        to: '/database/consentements',
        category: i18n.t('database.passport'),
        navBottom: [
          {
            to: '/',
            icon: 'mdi-home',
            tooltip: i18n.t('database.backToHome')
          }
        ],
        commingSoon: true,
        acl: []
      },
      // {
      //   title: i18n.t('database.blogCategories'),
      //   icon: 'mdi-folder-text',
      //   description: i18n.t('database.blogCategoriesManagement'),
      //   // color: colors.brown.darken1,
      //   color: '#047857',
      //   to: '/database/blog/categories',
      //   category: i18n.t('database.blog'),
      //   navBottom: [
      //     {
      //       to: '/',
      //       icon: 'mdi-home',
      //       tooltip: i18n.t('database.backToHome'),
      //     },
      //   ],
      // },
      {
        title: i18n.t('database.blogArticles'),
        icon: 'mdi-post',
        description: i18n.t('database.blogArticlesManagement'),
        // color: colors.brown.darken1,
        color: '#047857',
        to: '/database/blog/posts',
        category: i18n.t('database.blog'),
        navBottom: [
          {
            to: '/',
            icon: 'mdi-home',
            tooltip: i18n.t('database.backToHome')
          }
        ]
      },
      {
        title: i18n.t('database.collections'),
        icon: 'mdi-toy-brick',
        description: i18n.t('database.collectionsManagement'),
        // color: colors.orange.accent1,
        color: '#BE185D',
        to: '/database/collections',
        category: i18n.t('database.passport'),
        navBottom: [
          {
            to: '/',
            icon: 'mdi-home',
            tooltip: i18n.t('database.backToHome')
          }
        ],
        commingSoon: true,
        acl: []
      }
      /* {
        title: i18n.t('database.settings'),
        icon: 'mdi-cog-box',
        description: i18n.t('database.companySettings'),
        color: colors.grey.accent1,
        to: '/database/parametrages',
        navBottom: [
          {
            to: '/',
            icon: 'mdi-home',
            tooltip: i18n.t('database.backToHome')
          },
        ],
      }, */
    ],
    getBlocks() {
      return this.values
    },
    getBlockByTo(val) {
      const blk = this.values.filter(block => block.to === val)
      return blk ? blk[0] : null
    },
    getBlockSortByCategory() {
      return this.values.reduce((all, curr) => {
        const already = all.findIndex(({ title }) => title === curr.category)
        if (already !== -1) {
          all[already].items.push(curr)
        } else {
          all.push({
            title: curr.category,
            items: [curr]
          })
        }
        return all
      }, [])
    }
  }
}
