import axios from 'axios'
import { createError } from '../../artebeaute/helpers/errors'
import config from '../config'

export default {
  async ventilationByShop(data) {
    try {
      console.log('ventilation')

      const response = await axios.get(
        config.apiURL + '/ca/aggregates/ventilation',
        { params: data }
      )

      return {
        ventilationByShop: response.data
      }
    } catch (e) {
      throw createError(e)
    }
  },
  async cashDispatch(data) {
    try {
      console.log('ventilation')

      const response = await axios.get(
        config.apiURL + '/ca/aggregates/cashDispatch',
        { params: data }
      )

      return {
        cashDispatch: response.data.cashDispatch
      }
    } catch (e) {
      throw createError(e)
    }
  },
  async appointmentByShop(data) {
    try {
      console.log('appointmentByShop')

      const response = await axios.get(
        config.apiURL + '/ca/aggregates/appointmentByShop',
        { params: data }
      )

      return {
        appointmentByShop: response.data.appointmentByShop
      }
    } catch (e) {
      throw createError(e)
    }
  },
  async workHoursByShop(data) {
    try {
      console.log('workHoursByShop')

      const response = await axios.get(
        config.apiURL + '/ca/aggregates/workHoursByShop',
        { params: data }
      )

      return {
        workHoursByShop: response.data.workHoursByShop
      }
    } catch (e) {
      throw createError(e)
    }
  }
}
