import { MKPSDK } from '@/packages/artebeaute-sdk/src'
import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    events: []
  }),
  getters: {
    getEvents(state) {
      return state.events.sort((a, b) => {
        return new Date(a.end) - new Date(b.end)
      })
    }
  },
  mutations: {
    SET_EVENTS(state, data) {
      state.events = data
    },
    UPDATE_EVENT(state, { id, data }) {
      const index = state.events.findIndex(e => e.id === id)

      if (index >= 0) {
        Vue.set(state.events, index, data)
      }
    },
    DELETE_EVENT(state, { id }) {
      const index = state.events.findIndex(e => e.id === id)

      if (index >= 0) {
        Vue.delete(state.events, index)
      }
    }
  },
  actions: {
    async fetchEvents({ commit, state }) {
      if (state.events.length) {
        return state.events
      }

      try {
        const response = await MKPSDK.Event.getAllEvents()

        commit('SET_EVENTS', response.events)
      } catch (err) {
        return []
      }
    },

    async createEvent({ state }, data) {
      try {
        const response = await MKPSDK.Event.createEvent(data)

        state.events.push(response.event)
        return true
      } catch (err) {
        return false
      }
    },

    async updateEvent({ commit }, { id, data }) {
      try {
        const response = await MKPSDK.Event.updateEvent(id, data)

        commit('UPDATE_EVENT', { id, data: response.event })
        return true
      } catch (err) {
        return false
      }
    },

    async deleteEvent({ commit }, { event }) {
      try {
        await MKPSDK.Event.deleteEvent(event.id)

        commit('DELETE_EVENT', { id: event.id })
        return true
      } catch (err) {
        return false
      }
    }
  }
}
