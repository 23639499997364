import { ArteBeauteSDK } from '@/packages/artebeaute-sdk/src'
import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    geogroupments: []
  }),
  getters: {
    getGeogroupments(state) {
      return state.geogroupments
    }
  },
  mutations: {
    SET_GEOGROUPMENTS(state, value) {
      state.geogroupments = value
    },
    ADD_GEOGROUPMENT(state, value) {
      state.geogroupments.push(value)
    },
    UPDATE_GEOGROUPMENT(state, { value, shop, toDelete }) {
      const geogroupmentIndex = state.geogroupments.findIndex(
        g => g.id === value.id
      )

      if (geogroupmentIndex >= 0) {
        if (!shop) {
          Vue.set(state.geogroupments, geogroupmentIndex, {
            ...value,
            shops: state.geogroupments[geogroupmentIndex].shops
          })
        } else {
          if (!toDelete) {
            state.geogroupments.forEach(geo => {
              geo.shops.map((s, index) => {
                if (s.id === shop.id) {
                  geo.shops.splice(index, 1)
                }
              })
            })
            state.geogroupments[geogroupmentIndex].shops.push(shop)
          } else {
            const indexShop = state.geogroupments[
              geogroupmentIndex
            ].shops.findIndex(s => s.id === shop.id)
            state.geogroupments[geogroupmentIndex].shops.splice(indexShop, 1)
          }
        }
      }
    },
    DELETE_GEOGROUPMENT(state, value) {
      const index = state.geogroupments.findIndex(geo => geo.id === value.id)
      state.geogroupments.splice(index, 1)
    }
  },
  actions: {
    async fetchGeogroupments({ commit, state }) {
      if (state.geogroupments.length) {
        return state.geogroupments
      }

      try {
        const response = await ArteBeauteSDK.Geogroupments.getAllGeogroupments()

        commit('SET_GEOGROUPMENTS', response.geogroupments)
      } catch (err) {
        return []
      }
    },
    async createGeogroupment({ commit }, data) {
      try {
        const response = await ArteBeauteSDK.Geogroupments.createGeogroupment(
          data
        )

        commit('ADD_GEOGROUPMENT', response.geogroupment)

        return true
      } catch (err) {
        return false
      }
    },
    async updateGeogroupment({ commit }, { geogroupment, data }) {
      try {
        const response = await ArteBeauteSDK.Geogroupments.updateGeogroupment(
          geogroupment.id,
          data
        )

        commit('UPDATE_GEOGROUPMENT', { value: response.geogroupment })

        return true
      } catch (err) {
        return false
      }
    },
    async linkGeogroupment({ state, commit }, { geogroupment, shop }) {
      try {
        const response = await ArteBeauteSDK.Geogroupments.linkGeogroupmentToShop(
          geogroupment.id,
          shop.id
        )

        commit('shop/UPDATE_SHOP', response.shop, { root: true })

        const geogroupmentUpdate = state.geogroupments.find(
          g => g.id === geogroupment.id
        )

        const shopUpdate = {
          active: response.shop.active,
          artebeauteId: response.shop.artebeauteId,
          booker: response.shop.booker,
          daysOff: response.shop.daysOff,
          id: response.shop.id,
          images: response.shop.images,
          localisation: response.shop.localisation,
          openingHours: response.shop.openingHours,
          passport: response.shop.passport,
          publicInfo: response.shop.publicInfo,
          slug: response.shop.slug
        }

        commit('UPDATE_GEOGROUPMENT', {
          value: geogroupmentUpdate,
          shop: shopUpdate
        })

        return response.shop
      } catch (err) {
        return false
      }
    },
    async unlinkGeogroupment({ state, commit }, { geogroupment, shop }) {
      try {
        const response = await ArteBeauteSDK.Geogroupments.unlinkGeogroupmentToShop(
          geogroupment.id,
          shop.id
        )

        commit('shop/UPDATE_SHOP', response.shop, { root: true })

        const geogroupmentUpdate = state.geogroupments.find(
          g => g.id === geogroupment.id
        )

        const shopUpdate = {
          id: response.shop.id
        }

        commit('UPDATE_GEOGROUPMENT', {
          value: geogroupmentUpdate,
          shop: shopUpdate,
          toDelete: true
        })

        return response.shop
      } catch (err) {
        console.log('err :>> ', err)
        return false
      }
    },
    async deleteGeogroupment({ commit, rootState }, { id }) {
      try {
        const response = await ArteBeauteSDK.Geogroupments.deleteGeogroupment(
          id
        )

        commit('DELETE_GEOGROUPMENT', response.geogroupment)

        rootState.shop.shops.forEach((shop, index) => {
          if (shop.geoGroupment && shop.geoGroupment.id) {
            Vue.delete(rootState.shop.shops[index], 'geoGroupment')
          }
        })

        return true
      } catch (err) {
        return false
      }
    }
  }
}
