import axios from 'axios'
import { createError } from '../../artebeaute/helpers/errors'
import config from '../config'

export default  {
    async indicatorRealizedByShop(data) {
        try {

            const response = await axios.get(
                config.apiURL + '/ca/indicator/realized',
                { params: data }
            )

            return {
                indRealized: response.data.indRealized.data
            }
        } catch (e) {
            throw createError(e)
        }
    },
    async indicatorCashByShop(data) {
        try {

            const response = await axios.get(
                config.apiURL + '/ca/indicator/cash',
                { params: data }
            )

            return {
                indCash: response.data.indCash.data
            }
        } catch (e) {
            throw createError(e)
        }
    }
}