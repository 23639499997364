import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getAppointmentPeriodeByShopService(abShopId, abServiceId, start, end) {
    try {
      let requestURL = config.apiURL + '/api/appointments'
      if (abShopId && abServiceId && start && end) {
        requestURL +=
          '?shopid=' +
          abShopId +
          '&serviceid=' +
          abServiceId +
          '&start=' +
          start +
          '&end=' +
          end
        const response = await axios.get(requestURL)
        return {
          appointments: response.data.appointments
        }
      } else {
        throw createError('param manquants')
      }
    } catch (err) {
      throw createError(err)
    }
  },

  async appointmentAdd(myAppointment) {
    try {
      const requestURL = config.apiURL + '/api/appointments'
      if (myAppointment) {
        const response = await axios.post(requestURL, {
          myAppointment
        })
        return { appointments: response.data.appointments }
      } else {
        throw createError('param manquants')
      }
    } catch (e) {
      console.log('e :>> ', e)
      throw createError(e)
    }
  },

  async appointmentOldRefundAsk() {
    try {
      const requestURL = config.apiURL + '/api/appointments/old/refundask'
      const response = await axios.get(requestURL)
      return { refundAsk: response.data }
    } catch (e) {
      console.log('e :>> ', e)
      throw createError(e)
    }
  }
}
