import axios from 'axios'
import { createError } from '../helpers/errors'
// import config from '@/packages/artebeaute-sdk/src/artebeaute/config'

export default {
  async orderHistory(weburl, token, page, sort, start, end) {
    try {
      let requestURL =
        weburl +
        '/api/stat/booking' +
        '?page=' +
        page +
        '&start=' +
        start +
        '&end=' +
        end
      const response = await axios.get(requestURL, {
        headers: {
          'X-Authorization': 'Bearer ' + token
        }
      })
      return {
        booking: response.data
      }
    } catch (err) {
      throw createError(err)
    }
  }
}
