// import axios from "axios";
// import { createError } from "../helpers/errors";
// import config from "../config";

export default {
  // TODO: REMOVE THIS FILE
  // async searchCmpUser(search) {
  //   try {
  //     const url = config.apiURL + "/api/admin/cmp-users/search?";
  //     const searchParams = [];
  //     Object.keys(search).forEach((key) => {
  //       searchParams.push(key + "=" + search[key]);
  //     });
  //     const searchQuery = searchParams.join("&");
  //     const response = await axios.get(url + searchQuery);
  //     return {
  //       user: response.data.user,
  //     };
  //   } catch (err) {
  //     throw createError(err);
  //   }
  // },
  // async resetActivationLink(email) {
  //   try {
  //     const response = await axios.post(
  //       config.apiURL + "/api/admin/cmp-users/token/reset",
  //       {
  //         email,
  //       }
  //     );
  //     return {
  //       token: response.data.token,
  //     };
  //   } catch (err) {
  //     throw createError(err);
  //   }
  // },
}
