import { ArteBeauteSDK } from '@/packages/artebeaute-sdk/src'
import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    abResources: {},
    resources: [],
  }),
  getters: {
    getResources(state) {
      return state.resources
    },
  },
  mutations: {
    ADD_AB_RESOURCES(state, { shopId, type, value }) {
      state.abResources[shopId + '-' + type] = value
    },
    SET_RESOURCES(state, value) {
      state.resources = value
    },
    PUSH_RESOURCE(state, value) {
      state.resources.push(value)
    },
    SPLICE_RESOURCES(state, value) {
      state.resources.splice(
        state.resources.findIndex((s) => s.id === value.id),
        1
      )
    },
    UPDATE_RESOURCE(state, value) {
      const resourceIndex = state.resources.findIndex((r) => r.id === value.id)

      if (resourceIndex >= 0) {
        Vue.set(state.resources, resourceIndex, value)
      }
    },
  },
  actions: {
    async fetchAbResourcesForShop({ commit, state }, { shopId, type }) {
      if (state.abResources[shopId + '-' + type]) {
        return state.abResources[shopId + '-' + type]
      }

      try {
        const response = await ArteBeauteSDK.Resources.getAllAbResources(
          shopId,
          type
        )

        commit('ADD_AB_RESOURCES', {
          shopId,
          type,
          value: response.resources,
        })
      } catch (err) {
        return []
      }
    },
    async fetchAllResources({ commit, state }) {
      if (state.resources.length) {
        return state.resources
      }

      try {
        const response = await ArteBeauteSDK.Resources.getAllResources()

        commit('SET_RESOURCES', response.resources)
      } catch (err) {
        return []
      }
    },
    async publishResource({ commit }, { resource, localShop }) {
      try {
        const response = await ArteBeauteSDK.Resources.createResource({
          name: {
            fr: resource.fullname,
          },
          shopId: localShop.id,
          artebeauteId: resource.id,
          type: resource.type,
          booker: resource.booker,
          passport: {
            active: false,
          },
          empower: {},
          active: true,
        })

        commit('PUSH_RESOURCE', response.resource)

        return true
      } catch (err) {
        return false
      }
    },
    async unpublishResource({ commit }, localResource) {
      try {
        await ArteBeauteSDK.Resources.deleteResource(localResource.id)

        commit('SPLICE_RESOURCES', localResource)

        return true
      } catch (err) {
        return false
      }
    },
    async updateResource({ commit }, { resource, data }) {
      try {
        const updatedResource = await ArteBeauteSDK.Resources.updateResource(
          resource.id,
          data
        )

        commit('UPDATE_RESOURCE', updatedResource)

        return true
      } catch (err) {
        return false
      }
    },
  },
}
