import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async me(token = null) {
    try {
      const headers = {}

      if (token) {
        headers.authorization = 'Bearer ' + token
      }

      const response = await axios.get(config.apiURL + '/api/cmpusers/me', {
        headers
      })

      return { user: response.data.user }
    } catch (err) {
      throw createError(err)
    }
  },
  async login(email, password) {
    try {
      const response = await axios.post(config.apiURL + '/api/cmpusers/login', {
        email,
        password
      })

      return { token: response.data.token }
    } catch (err) {
      throw createError(err)
    }
  },
  async getAllCmpUsers() {
    try {
      const response = await axios.get(config.apiURL + '/api/cmpusers')

      return {
        users: response.data.users
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getCmpUser(userId) {
    try {
      const response = await axios.get(
        config.apiURL + '/api/cmpusers/' + userId
      )

      return {
        user: response.data.user
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async createCmpUser(data) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/cmpusers/register',
        data
      )

      return {
        user: response.data.user
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async updateCmpUser(userId, data) {
    try {
      const response = await axios.put(
        config.apiURL + '/api/cmpusers/' + userId,
        data
      )

      return {
        user: response.data.user
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async deleteCmpUser(userId) {
    try {
      const response = await axios.delete(
        config.apiURL + '/api/cmpusers/' + userId
      )

      return {
        user: response.data.user
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async sendActivationLink(userId) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/cmpusers/link/' + userId
      )

      return {
        user: response.data.user
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getByToken(token) {
    try {
      const response = await axios.get(
        config.apiURL + '/api/cmpusers/token/' + token
      )

      return {
        user: response.data.user
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async updateByToken(token, data) {
    try {
      const response = await axios.put(
        config.apiURL + '/api/cmpusers/token/' + token,
        data
      )

      return {
        user: response.data.user
      }
    } catch (err) {
      throw createError(err)
    }
  }
  // async updateVaultAccess(id, data) {
  //   try {
  //     const response = await axios.put(
  //       config.apiURL + '/api/cmp-users/vault/access/' + id,
  //       data
  //     )

  //     return { user: response.data.user }
  //   } catch (err) {
  //     throw createError(err)
  //   }
  // },
  // async revokeCmpUser(id) {
  //   try {
  //     await axios.delete(config.apiURL + '/api/cmp-users/' + id)

  //     return true
  //   } catch (err) {
  //     throw createError(err)
  //   }
  // },
  // async enableCmpUser(id) {
  //   try {
  //     await axios.post(config.apiURL + '/api/cmp-users/' + id)

  //     return true
  //   } catch (err) {
  //     throw createError(err)
  //   }
  // },
  // async getUserByToken(token) {
  //   try {
  //     const response = await axios.get(
  //       config.apiURL + '/api/cmp-users/token/' + token
  //     )

  //     return { user: response.data.user }
  //   } catch (err) {
  //     throw createError(err)
  //   }
  // },
  // async updateAccountByToken(token, data) {
  //   try {
  //     const response = await axios.put(
  //       config.apiURL + '/api/cmp-users/token/' + token,
  //       data
  //     )

  //     return { user: response.data.user }
  //   } catch (err) {
  //     throw createError(err)
  //   }
  // },
  // async getAllUsers() {
  //   try {
  //     const response = await axios.get(config.apiURL + '/api/cmp-users')

  //     return { users: response.data.users }
  //   } catch (err) {
  //     throw createError(err)
  //   }
  // },
}
