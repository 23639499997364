import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async giftHistory(
    page,
    itemPerPage,
    sort,
    sortDesc,
    start,
    end,
    code,
    firstname,
    lastname,
    mail,
    transaction
  ) {
    try {
      const response = await axios.get(
        config.apiURL +
          '/giftOrders/history?page=' +
          page +
          '&itemPerPage=' +
          itemPerPage +
          '&start=' +
          start +
          '&end=' +
          end +
          '&sortBy=' +
          sort +
          '&sortDesc=' +
          sortDesc +
          '&code=' +
          code +
          '&customerbuyfirstname=' +
          firstname +
          '&customerbuylastname=' +
          lastname +
          '&customerMail=' +
          mail +
          '&transactionId=' +
          transaction
      )
      return { giftHistory: response.data.giftHistory.data }
    } catch (err) {
      throw createError(err)
    }
  },
  async giftUpdateValidAt(idegift, newdate) {
    try {
      const requestURL = config.apiURL + '/giftOrders/updatevalideat'
      const response = await axios.put(requestURL, {
        idgift: idegift,
        newDate: newdate
      })
      return { changeDate: response.data }
    } catch (e) {
      console.log('e :>> ', e)
      throw createError(e)
    }
  },

  async giftDelete(idgift) {
    try {
      const requestURL = config.apiURL + '/giftOrders/delete?idgift=' + idgift
      const response = await axios.delete(requestURL)
      return { giftdelete: response.data }
    } catch (e) {
      throw createError(e)
    }
  },
  async giftAggregate(start, end) {
    try {
      const response = await axios.get(
        config.apiURL +
          '/giftOrders/aggregate?' +
          '&start=' +
          start +
          '&end=' +
          end
      )

      return response.data.giftAggregate.data
    } catch (err) {
      throw createError(err)
    }
  }
}
