import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getAllServers() {
    try {
      const response = await axios.get(config.apiURL + '/api/servers')

      return {
        servers: response.data.servers
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getServer(id) {
    try {
      const response = await axios.get(config.apiURL + '/api/servers/' + id)

      return {
        server: response.data.server
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async createServer(data) {
    try {
      const response = await axios.post(config.apiURL + '/api/servers', data)

      return {
        server: response.data.server
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async updateServer(id, data) {
    try {
      const response = await axios.put(
        config.apiURL + '/api/servers/' + id,
        data
      )

      return {
        server: response.data.server
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async deleteServer(id) {
    try {
      const response = await axios.delete(config.apiURL + '/api/servers/' + id)

      return {
        server: response.data.server
      }
    } catch (err) {
      throw createError(err)
    }
  }
}
