import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async me() {
    try {
      const response = await axios.get(config.apiURL + '/api/users/me')

      return { user: response.data.user }
    } catch (err) {
      throw createError(err)
    }
  },
  async login(email, password) {
    try {
      const response = await axios.post(config.apiURL + '/api/users/login', {
        email,
        password
      })

      return { token: response.data.token }
    } catch (err) {
      throw createError(err)
    }
  },
  async providerLogin(data, provider) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/users/login/provider',
        {
          data,
          provider
        }
      )

      return { token: response.data.token }
    } catch (err) {
      throw createError(err)
    }
  }
}
