<template>
  <v-app>
    <v-app-bar
      color="dark lighten-2"
      dark
      app
      v-if="$store.state.pageLoading.ready && $store.state.auth.token"
    >
      <template v-slot:img="{ props }">
        <v-img v-bind="props"></v-img>
      </template>

      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="d-sm-none"
      ></v-app-bar-nav-icon>

      <v-toolbar-title>
        <router-link
          to="/"
          style="text-decoration: none"
          class="d-flex align-center white--text"
        >
          <v-img
            width="50"
            contain
            src="/SB.png"
            class="mr-4 d-none d-sm-inline-block"
          />
          <v-img
            width="25"
            contain
            src="/SB.png"
            class="mr-4 d-inline-block d-sm-none"
          />
          Smart <span>Booker</span>
        </router-link>
      </v-toolbar-title>

      <v-spacer />
      <div style="font-size: 1.3rem" class="d-none d-sm-inline-block">
        {{ getTitle }}
      </div>
      <v-spacer />

      <a
        class="text-decoration-none d-none d-sm-inline-block"
        :href="$store.state.auth.company.identity.websiteURL"
        target="_blank"
      >
        <v-btn icon>
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </a>

      <v-btn
        icon
        v-for="(icon, index) in icons.filter((i) => haveAccess(i))"
        :key="'icon-' + index"
        @click="iconClick(icon.action, icon.to)"
        class="d-none d-sm-inline-block"
      >
        <v-icon>{{ icon.icon }}</v-icon>
      </v-btn>

      <v-menu offset-y bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-badge
              v-if="notifications.length"
              color="red"
              :content="calculNbrNotifications"
              overlap
            >
              <v-icon>mdi-bell</v-icon>
            </v-badge>

            <v-icon v-else>mdi-bell</v-icon>
          </v-btn>
        </template>
        <v-list>
          <template v-if="notifications.length">
            <v-list-item
              v-for="notification in notifications"
              :key="notification.objectId"
              @click="
                $route.path !== notification.page
                  ? $router.push(notification.page)
                  : ''
              "
            >
              <v-list-item-title>
                <v-badge
                  class="ma-0"
                  color="red"
                  inline
                  :content="notification.nbr"
                >
                  {{ notification.text }}
                </v-badge>
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-else>
            <v-list-item>
              <v-list-item-title>
                {{ $t('layout.noNotification') }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>

      <v-menu>
        <template #activator="{ on, attrs }">
          <v-btn dark v-bind="attrs" v-on="on" text class="font-weight-bold">
            {{ $i18n.locale }}
          </v-btn>
        </template>
        <v-list dense tile>
          <v-list-item
            v-for="(lang, id) in langs"
            :key="id"
            class="language"
            @click="$i18n.locale = lang.value"
          >
            <v-list-item-title>
              {{ lang.text }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-dialog v-model="authDialog" max-width="400">
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar class="mt-3" color="primary">
                <span class="white--text headline">{{ firstChar }}</span>
              </v-avatar>

              <h3 class="mt-3">
                <span v-if="isLoggin.firstname">
                  {{ isLoggin.firstname }} {{ isLoggin.lastname }}
                </span>
              </h3>
              <p class="caption mt-1">{{ isLoggin.email }}</p>

              <v-btn @click="getAction(1)" class="mb-4" text outlined rounded>
                {{ $t('layout.manageAccount') }}
              </v-btn>
              <div v-if="$store.state.auth.user.role === 'cmpadmin'">
                <v-divider></v-divider>
                <v-btn @click="getAction(2)" text class="mt-2 mb-2">
                  <v-icon>mdi-account</v-icon>
                  <span class="ml-2">{{ $t('layout.manageAllAccounts') }}</span>
                </v-btn>
              </div>
              <v-divider></v-divider>
              <v-btn @click="getAction(3)" class="mt-2" text outlined>
                {{ $t('layout.logout') }}
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-dialog>

      <v-dialog max-width="400" v-model="editAuth">
        <user-form v-model="editAuth" :currentAuth="$store.state.auth.user" />
      </v-dialog>
      <v-dialog max-width="80vw" v-model="listAuth">
        <user-list v-model="listAuth" />
      </v-dialog>

      <template v-slot:extension>
        <v-tabs
          align-with-title
          v-if="links.filter((i) => haveAccess(i)).length"
          class="mx-3"
        >
          <template v-for="link in links.filter((i) => haveAccess(i))">
            <v-tab :to="link.to" v-if="link.to" :key="'navl-' + link.id">
              {{ link.label }}
            </v-tab>
            <v-menu offset-y :key="'navl-' + link.id" v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-tab v-bind="attrs" v-on="on">
                  {{ link.label }}
                </v-tab>
              </template>
              <v-list>
                <v-list-item
                  v-for="link in link.links"
                  :key="link.to"
                  :to="link.to"
                >
                  <v-list-item-title>{{ link.label }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-tabs>
      </template>
    </v-app-bar>
    <v-navigation-drawer
      app
      temporary
      v-model="drawer"
      v-if="$store.state.pageLoading.ready"
    >
      <v-list>
        <v-list-item
          to="$store.state.auth.company.identity.websiteURL"
          target="_blank"
        >
          <v-list-item-icon>
            <v-icon> mdi-open-in-new </v-icon>
          </v-list-item-icon>
          <v-list-item-title> Site Web </v-list-item-title>
        </v-list-item>

        <v-list-item
          icon
          v-for="(icon, index) in icons.filter((i) => haveAccess(i))"
          :key="'icon-' + index"
          @click="iconClick(icon.action, icon.to)"
        >
          <v-list-item-icon>
            <v-icon>
              {{ icon.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ icon.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!--    <v-app-bar-->
    <!--      app-->
    <!--      absolute-->
    <!--      color="primary"-->
    <!--      dark-->
    <!--      fade-img-on-scroll-->
    <!--      flat-->
    <!--      v-if="!$store.state.auth.token"-->
    <!--    >-->
    <!--      <template v-slot:img="{ props }">-->
    <!--        <v-img v-bind="props"></v-img>-->
    <!--      </template>-->

    <!--      <v-toolbar-title>-->
    <!--        <router-link-->
    <!--          to="/"-->
    <!--          style="text-decoration: none"-->
    <!--          class="d-flex align-center white&#45;&#45;text"-->
    <!--        >-->
    <!--          <v-img width="50" contain src="/SB.png" class="mr-4" />-->
    <!--          Smart <span>Booker</span>-->
    <!--        </router-link>-->
    <!--      </v-toolbar-title>-->

    <!--      <v-spacer />-->
    <!--    </v-app-bar>-->

    <v-main>
      <v-container fluid class="pt-0 mb-8" v-if="!$store.state.auth.token">
        <slot />
      </v-container>
      <v-container
        fluid
        class="pt-0 mb-8"
        v-else-if="$store.state.pageLoading.ready"
      >
        <slot />
      </v-container>
      <v-container
        fluid
        class="pt-0 mb-8"
        v-else-if="$store.state.auth.companyToken"
      >
        <starter @ready="$store.dispatch('pageLoading/setReady', true)" />
      </v-container>
      <v-container fluid class="pt-0 mb-8" v-else> </v-container>
    </v-main>

    <v-footer app> ArteBeauté - Biremont Multimedia </v-footer>
  </v-app>
</template>

<script>
import swal from 'sweetalert'
import iconsHead from '@/helpers/iconsHeader'
import UserForm from '@/components/users/form'
import UserList from '@/components/users/list'
import Starter from '@/components/starter'
import { mapGetters } from 'vuex'

export default {
  components: {
    UserForm,
    UserList,
    Starter,
  },
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      drawer: false,
      authDialog: false,
      editAuth: false,
      listAuth: false,
      icons: iconsHead().getIcons(),
      langs: [
        { text: 'Français', value: 'fr' },
        { text: 'English', value: 'en' },
        { text: 'Deutsch', value: 'de' },
      ],
      allnews: [
        { title: 'titre1', content: 'contenu1' },
        { title: 'titre2', content: 'contenu2' },
        { title: 'titre3', content: 'contenu3' },
      ],
      notifications: [],
    }
  },
  computed: {
    ...mapGetters('auth', {
      detectEmptySettings: 'getEmptySettings',
    }),
    isLoggin() {
      return this.$store.state.auth.user
    },
    firstChar() {
      return this.$store.state.auth.user.firstname
        ? this.$store.state.auth.user.firstname.charAt(0)
        : '?'
    },
    getTitle() {
      return this.$store.state.auth.company.identity.displayName
    },
    calculNbrNotifications() {
      let nbr = 0
      this.notifications.forEach((n) => {
        nbr += n.nbr
      })
      return nbr
    },
  },
  mounted() {
    this.setNotifications()
  },
  methods: {
    showNotifications() {
      if (this.allnews.length >= 0) {
        return 'Aucune notification pour linstant'
      }
    },
    haveAccess(i) {
      if (!i.acl) {
        return true
      }

      if (this.$store.state.auth.user.role === 'cmpadmin') {
        return true
      }

      return i.acl.includes(this.$store.state.auth.user.role)
    },
    iconClick(key = 'redirection', to) {
      this.drawer = false
      switch (key) {
        case 'redirection':
          this.redirect(to)
          break
        case 'auth':
          this.authDialog = true
          break
      }
    },
    getAction(val) {
      if (val === 2) {
        this.authDialog = false
      }
      this.authAction(val)
    },
    redirect(to) {
      if (this.$route.path !== to) this.$router.push(to)
    },
    setNotifications() {
      if (this.detectEmptySettings) {
        let nbr = 0
        Object.keys(this.detectEmptySettings).forEach((key) => {
          nbr += this.detectEmptySettings[key]
        })
        if (nbr > 0) {
          const notification = {
            nbr,
            text: this.$t('layout.missingRequiredSettings'),
            page: '/parametrages',
          }

          if (this.notifications.find((n) => n.text === notification.text)) {
            if (this.$route.path === notification.page) {
              this.notifications.splice(
                this.notifications.findIndex(
                  (n) => n.text === notification.text
                ),
                1
              )
            } else {
              this.notifications[
                this.notifications.findIndex(
                  (n) => n.text === notification.text
                )
              ].nbr = notification.nbr
            }
          } else {
            this.notifications.push(notification)
          }
        }
      }
    },
    async authAction(val) {
      if (val === 1) {
        this.editAuth = true
      } else if (val === 2) {
        this.$router.push('/users')
        // this.listAuth = true
      } else if (val === 3) {
        // this.$router.push("/login");
        const logOut = await swal({
          buttons: {
            cancel: this.$t('layout.cancel'),
            disconnect: this.$t('layout.signOut'),
          },
          title: this.$t('layout.login'),
          text: this.$t('layout.areYouSure'),

          dangerMode: true,
        })

        if (logOut === 'disconnect') {
          await this.$store.dispatch('pageLoading/setReady', false)
          await this.$store.dispatch('auth/logout')
          this.authDialog = false
          this.editAuth = false
          this.listAuth = false
          this.$router.push('/login')
        }
      }
    },
  },

  watch: {
    detectEmptySettings: {
      handler() {
        this.setNotifications()
      },
      deep: true,
    },
  },
}
</script>

<style>
.v-app-bar.v-app-bar--fixed {
  z-index: 15;
}
.v-navigation-drawer {
  z-index: 998;
}
</style>

<style scoped>
.container {
  min-height: 100%;
}
.language {
  cursor: pointer;
}
.language:hover {
  background-color: #cccccc;
}
</style>
