import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getAllNeedles() {
    try {
      const response = await axios.get(config.apiURL + '/api/needles')

      return {
        needles: response.data.needles
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getNeedle(id) {
    try {
      const response = await axios.get(config.apiURL + '/api/needles/' + id)

      return {
        needle: response.data.needle
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async createNeedle(data) {
    try {
      const response = await axios.post(config.apiURL + '/api/needles', data)

      return {
        needle: response.data.needle
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async updateNeedle(id, data) {
    try {
      const response = await axios.put(
        config.apiURL + '/api/needles/' + id,
        data
      )

      return {
        needle: response.data.needle
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async deleteNeedle(id) {
    try {
      const response = await axios.delete(config.apiURL + '/api/needles/' + id)

      return {
        needle: response.data.needle
      }
    } catch (err) {
      throw createError(err)
    }
  }
}
