import axios from 'axios'
import { createError } from '../../artebeaute/helpers/errors'
import config from '../config'

export default  {
    async getLastDayOpen(data) {
        try {
            debugger
            console.log(config.apiURL + '/ca/cashier/lastOpenDay')
            const response = await axios.get(
                config.apiURL +  '/ca/cashier/lastOpenDay',
                { params: data }
            )
            console.log('getLastDayOpen', response.data)
            return {
                getLastDayOpen: response.data.lastOpenCashier
            }
        } catch (e) {
            throw createError(e)
        }
    },
    async caRealized(data) {
        try {
            debugger
            console.log(config.apiURL + '/ca/cashier/caRealized')
            const response = await axios.get(
                config.apiURL +  '/ca/cashier/caRealized',
                { params: data }
            )

            return {
                caRealized: response.data.caRealized.data
            }
        } catch (e) {
            throw createError(e)
        }
    }
}