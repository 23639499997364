import axios from "axios";
import { createError } from "../helpers/errors";
import config from "../config";

export default {
  async getAllReleases() {
    try {
      const response = await axios.get(config.apiURL + "/api/releases");

      return {
        releases: response.data.releases
      };
    } catch (err) {
      throw createError(err);
    }
  },
  async getRelease(id) {
    try {
      const response = await axios.get(config.apiURL + "/api/releases/" + id);

      return {
        release: response.data.release
      };
    } catch (err) {
      throw createError(err);
    }
  },
  async createRelease(data) {
    try {
      const response = await axios.post(config.apiURL + "/api/releases", data);

      return {
        release: response.data.release
      };
    } catch (err) {
      throw createError(err);
    }
  },
  async updateRelease(id, data) {
    try {
      const response = await axios.put(
        config.apiURL + "/api/releases/" + id,
        data
      );

      return {
        release: response.data.release
      };
    } catch (err) {
      throw createError(err);
    }
  },
  async deleteRelease(id) {
    try {
      const response = await axios.delete(
        config.apiURL + "/api/releases/" + id
      );

      return {
        release: response.data.release
      };
    } catch (err) {
      throw createError(err);
    }
  }
};
