import uploads from './uploads/uploads'
import config from './config'

export default {
  Config: config,
  Upload: {
    ...uploads
  },
  initialize(config) {
    Object.keys(config).forEach(key => {
      this.Config[key] = config[key]
    })
  }
}
