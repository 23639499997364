import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth'
import shop from './structure/shop'
import resources from './structure/resources'
import geogroupments from './structure/geogroupments'
import articles from './structure/articles'
import categories from './structure/categories'
import customers from './structure/customers'
import needles from './structure/needles'
import collections from './structure/collections'
import blog from './structure/blog'
import transactions from './structure/transactions'
import blocks from './blocks'
import pageLoading from './pageLoading'
import menus from './menus'
import statistiques from './structure/statistiques'
import releases from './structure/releases'
import events from './structure/events'
import alerts from './structure/alerts'
import cashier from './stat/cashier'
import indicator from './stat/indicator'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    shop,
    resources,
    geogroupments,
    articles,
    categories,
    customers,
    needles,
    collections,
    blog,
    blocks,
    pageLoading,
    transactions,
    menus,
    statistiques,
    releases,
    events,
    alerts,
    cashier,
    indicator
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {}
})
