<template>
  <div>
    <div v-if="fromManager">
      <v-alert v-if="success" type="success">
        {{ success }}
      </v-alert>
      <v-alert v-if="error" type="error">
        {{ error }}
      </v-alert>
      <v-form @submit.prevent="actionForm">
        <v-text-field
          outlined
          type="text"
          v-model="email"
          :label="$t('layout.emailAddressOrAccountName')"
        />
        <v-text-field
          type="text"
          outlined
          v-model="firstname"
          :label="$t('other.firstName')"
          v-if="!isEmail || currentAuth"
        />
        <v-text-field
          type="text"
          outlined
          v-model="lastname"
          :label="$t('other.name')"
          v-if="!isEmail || currentAuth"
        />
        <v-btn
          v-if="!updatePassword && currentAuth"
          @click="updatePassword = true"
          class="elevation-0"
          small
        >
          Modifier le mot de passe
        </v-btn>

        <v-text-field
          type="password"
          outlined
          autocomplete="new-password"
          v-model="password"
          :label="$t('layout.password')"
          v-if="(!isEmail && !currentAuth) || (currentAuth && updatePassword)"
        />
        <v-text-field
          type="password"
          outlined
          autocomplete="new-password"
          v-model="passwordConfirmation"
          :label="$t('layout.passwordConfirm')"
          v-if="(!isEmail && !currentAuth) || (currentAuth && updatePassword)"
        />
        <v-select
          v-model="role"
          outlined
          placeholder="Role"
          v-if="!currentAuth"
          :items="rolesItems"
        />
        <v-btn
          v-if="currentAuth && updatePassword"
          @click="updatePassword = false"
          small
          class="elevation-0"
        >
          Annuler la modification du mot de passe
        </v-btn>
        <v-divider class="mt-4" />
        <div>
          <h3 class="mt-4 mb-2">Accès</h3>
          <v-card tile>
            <div class="pb-5">
              <v-list-item dense>
                <v-list-item-icon>
                  <v-checkbox
                    v-model="acl"
                    value="can_access_shops"
                    dense
                    hide-details
                    class="my-0 mt-1"
                    label="Accès aux boutiques"
                  />
                </v-list-item-icon>
              </v-list-item>
              <v-list-item dense v-if="acl.includes('can_access_shops')">
                <v-list-item-content>
                  <v-card tile color="grey lighten-3">
                    <v-text-field
                      prepend-inner-icon="mdi-magnify"
                      placeholder="Rechercher par nom"
                      v-model="searchShop"
                      outlined
                      dense
                      style="background-color: white"
                      hide-details
                    />
                    <div
                      v-if="
                        !$store.state.shop.shops.filter(
                          (s) =>
                            !searchShop ||
                            s.publicInfo.name
                              .toLowerCase()
                              .includes(searchShop.toLowerCase())
                        ).length
                      "
                      class="text-center mb-5"
                    >
                      Pas de résultat
                    </div>
                    <div
                      style="
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        max-height: 400px;
                        overflow-y: auto;
                      "
                      class="py-3"
                    >
                      <v-list-item
                        v-for="shop in $store.state.shop.shops.filter(
                          (s) =>
                            !searchShop ||
                            s.publicInfo.name
                              .toLowerCase()
                              .includes(searchShop.toLowerCase())
                        )"
                        :key="shop.id"
                        dense
                      >
                        <v-list-item-content>
                          <v-checkbox
                            v-model="aclShops"
                            :value="shop.id"
                            dense
                            hide-details
                            class="my-0 mt-1"
                            :label="shop.publicInfo.name"
                          />
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-card>
                </v-list-item-content>
              </v-list-item>
              <v-list-item dense>
                <v-list-item-icon>
                  <v-checkbox
                    v-model="acl"
                    value="can_access_stats"
                    dense
                    hide-details
                    class="my-0 mt-1"
                    label="Accès aux statistiques"
                  />
                </v-list-item-icon>
              </v-list-item>
              <v-list-item dense v-if="acl.includes('can_access_stats')">
                <v-list-item-content>
                  <v-card tile color="grey lighten-3">
                    <v-text-field
                      prepend-inner-icon="mdi-magnify"
                      placeholder="Rechercher par nom"
                      v-model="searchShop"
                      outlined
                      dense
                      style="background-color: white"
                      hide-details
                    />
                    <div
                      v-if="
                        !$store.state.shop.shops.filter(
                          (s) =>
                            !searchShop ||
                            s.publicInfo.name
                              .toLowerCase()
                              .includes(searchShop.toLowerCase())
                        ).length
                      "
                      class="text-center mb-5"
                    >
                      Pas de résultat
                    </div>
                    <div
                      style="
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        max-height: 400px;
                        overflow-y: auto;
                      "
                      class="py-3"
                    >
                      <v-list-item
                        v-for="shop in $store.getters[
                          'shop/fusionShop'
                        ]().filter(
                          (s) =>
                            !searchShop ||
                            s.name
                              .toLowerCase()
                              .includes(searchShop.toLowerCase())
                        )"
                        :key="shop.abId"
                        dense
                      >
                        <v-list-item-content>
                          <v-checkbox
                            v-model="aclStatsShops"
                            :value="shop.abId"
                            dense
                            hide-details
                            class="my-0 mt-1"
                            :label="shop.name"
                          />
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-card>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-card>
        </div>
        <v-divider class="mt-4" />
        <div class="d-flex align-center justify-space-between mt-4">
          <v-btn to="/users" text> Annuler </v-btn>
          <v-spacer />
          <v-btn type="submit" color="primary"> Enregistrer </v-btn>
        </div>
      </v-form>
    </div>
    <action-modal
      v-else
      :icon="$icones.account"
      :title="$t('layout.userAccount')"
      @actionModal="actionForm"
    >
      <template v-slot:actionModalContent>
        <v-form>
          <v-text-field
            outlined
            type="text"
            v-model="email"
            :label="$t('layout.emailAddressOrAccountName')"
          />
          <v-text-field
            type="text"
            outlined
            v-model="firstname"
            :label="$t('other.firstName')"
            v-if="!isEmail || currentAuth"
          />
          <v-text-field
            type="text"
            outlined
            v-model="lastname"
            :label="$t('other.name')"
            v-if="!isEmail || currentAuth"
          />
          <v-btn
            v-if="!updatePassword && currentAuth"
            @click="updatePassword = true"
            text
          >
            {{ $t('layout.changePassword') }}
          </v-btn>

          <v-text-field
            type="password"
            outlined
            autocomplete="new-password"
            v-model="password"
            :label="$t('layout.password')"
            v-if="(!isEmail && !currentAuth) || (currentAuth && updatePassword)"
          />
          <v-text-field
            type="password"
            outlined
            autocomplete="new-password"
            v-model="passwordConfirmation"
            :label="$t('layout.passwordConfirm')"
            v-if="(!isEmail && !currentAuth) || (currentAuth && updatePassword)"
          />
          <v-select
            v-model="role"
            outlined
            placeholder="Role"
            v-if="!currentAuth"
            :items="rolesItems"
          />
          <v-btn
            v-if="currentAuth && updatePassword"
            @click="updatePassword = false"
            text
          >
            {{ $t('layout.cancelPasswordChange') }}
          </v-btn>
        </v-form>
      </template>
    </action-modal>
    <!-- <v-card>
      <v-card-title>
        <h2>
          <span v-if="currentAuth">Gérer votre Compte</span>
          <span v-else>Créer un Compte</span>
        </h2>
      </v-card-title>

      <v-alert type="error" v-if="error">{{ error }}</v-alert>

      <v-form @submit.prevent="handleForm">
        <v-card-text>
          <v-text-field type="text" v-model="email" placeholder="Adresse email ou nom de compte" />
          <v-text-field
            type="text"
            v-model="firstname"
            placeholder="Prénom"
            v-if="!isEmail || currentAuth"
          />
          <v-text-field
            type="text"
            v-model="lastname"
            placeholder="Nom"
            v-if="!isEmail || currentAuth"
          />
          <v-btn
            v-if="!updatePassword && currentAuth"
            @click="updatePassword=true"
            text
          >modifier votre mot de passe</v-btn>

          <v-text-field
            type="password"
            v-model="password"
            placeholder="Mot de passe"
            v-if="(!isEmail && !currentAuth) || (currentAuth && updatePassword)"
          />
          <v-text-field
            type="password"
            v-model="passwordConfirmation"
            placeholder="Mot de passe (confirmez)"
            v-if="(!isEmail && !currentAuth) || (currentAuth && updatePassword)"
          />
          <v-btn
            v-if="currentAuth && updatePassword"
            @click="updatePassword=false"
            text
          >Annuler la modification de votre mot de passe</v-btn>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text large @click="cancelForm">Annuler</v-btn>
          <v-btn text color="success" large type="submit">Valider</v-btn>
        </v-card-actions>
      </v-form>
    </v-card> -->
  </div>
</template>

<script>
import { MKPSDK } from '@/packages/artebeaute-sdk/src'
import actionModal from '@/components/helpers/actionModal.vue'

export default {
  components: { actionModal },
  props: {
    currentAuth: { type: Object, default: null },
    fromManager: { type: Boolean, default: false },
  },
  data() {
    return {
      success: null,
      error: null,
      email: this.currentAuth ? this.currentAuth.email : null,
      firstname: this.currentAuth ? this.currentAuth.firstname : null,
      lastname: this.currentAuth ? this.currentAuth.lastname : null,
      password: null,
      passwordConfirmation: null,
      updatePassword: false,
      role: null,
      searchShop: '',
      acl: [],
      aclShops: [],
      aclStatsShops: [],
    }
  },
  computed: {
    isEmail() {
      const email = this.email
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    rolesItems() {
      return [
        {
          value: 'cmpadmin',
          text: 'Administrateur',
        },
        {
          value: 'cmpsubuser',
          text: 'Sous utilisateur',
        },
      ]
    },
  },
  watch: {
    currentAuth: function (val) {
      this.email = val ? val.email : null
      this.firstname = val ? val.firstname : null
      this.lastname = val ? val.lastname : null
      this.updatePassword = false
    },
  },
  created() {
    this.acl =
      this.currentAuth && this.currentAuth.acl ? this.currentAuth.acl : []
    this.aclShops =
      this.currentAuth && this.currentAuth.aclShops
        ? this.currentAuth.aclShops
        : []
    this.aclStatsShops =
      this.currentAuth && this.currentAuth.aclStatsShops
        ? this.currentAuth.aclStatsShops
        : []
  },
  methods: {
    actionForm(e) {
      if (e) {
        this.handleForm()
      } else {
        this.cancelForm()
      }
    },
    cancelForm() {
      this.$emit('input', false)
    },
    async handleForm() {
      if (!this.currentAuth) {
        if (!this.email) {
          return
        }
        if (!this.role) {
          return
        }

        if (!this.isEmail && !this.password) {
          return
        }
        let response = null
        try {
          if (this.isEmail) {
            response = await MKPSDK.CmpUsers.createCmpUser({
              email: this.email,
              role: this.role,
              acl: this.acl,
              aclShops: this.aclShops,
              aclStatsShops: this.aclStatsShops,
            })
          } else {
            response = await MKPSDK.CmpUsers.createCmpUser({
              email: this.email,
              firstname: this.firstname,
              lastname: this.lastname,
              password: this.password,
              role: this.role,
              acl: this.acl,
              aclShops: this.aclShops,
              aclStatsShops: this.aclStatsShops,
            })
          }

          this.success = 'Compte créé avec succès.'
          if (response) {
            this.$store.state.auth.users.push(response.user)
            this.$emit('input', false)
          }

          if (this.fromManager) {
            await this.$router.push('/users')
          }
        } catch (err) {
          console.log('err :>> ', err)
          this.error = 'Impossible de créer le compte'
        }
      } else {
        if (!this.email) {
          return
        }
        let data = {}
        if (this.updatePassword) {
          if (!this.password || this.password !== this.passwordConfirmation) {
            return
          }
          data = {
            email: this.email,
            firstname: this.firstname,
            lastname: this.lastname,
            password: this.password,
            acl: this.acl,
            aclShops: this.aclShops,
            aclStatsShops: this.aclStatsShops,
          }
        } else {
          data = {
            email: this.email,
            firstname: this.firstname,
            lastname: this.lastname,
            acl: this.acl,
            aclShops: this.aclShops,
            aclStatsShops: this.aclStatsShops,
          }
        }
        const response = await MKPSDK.CmpUsers.updateCmpUser(
          this.currentAuth.id,
          data
        )
        if (response.user) {
          // this.$store.state.auth.user = response.user
          this.$emit('input', false)

          if (this.fromManager) {
            await this.$router.push('/users')
          }
        }
      }
    },
  },
}
</script>

<style></style>
