import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify)

export default new Vuetify({
  customVariables: ['~/assets/variables.scss'],
  treeShake: true,
  customProperties: true,
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: '#CBBA95',
        secondary: '#706159',
        accent: '#e91e63',
        info: '#00CAE3',
        success: '#4CAF50',
        danger: colors.red,
        warning: '#FB8C00',
        error: '#FF5252',
        gold: '#B9A04E'
      },
      light: {
        primary: '#CBBA95',
        secondary: '#ff0000',
        accent: '#ff0000',
        light: '#FFFFFF',
        dark: '#2B2B2B',
        info: '#00CAE3',
        success: '#4CAF50',
        danger: colors.red,
        warning: '#FB8C00',
        error: '#FF5252',
        gold: '#B9A04E'
      }
    }
  }
})
