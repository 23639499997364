import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getAllRemises() {
    try {
      const response = await axios.get(config.apiURL + '/api/remises')

      return {
        remises: response.data.remises
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async createRemise(data) {
    try {
      const response = await axios.post(config.apiURL + '/api/remises', data)

      return {
        remise: response.data.remise
      }
    } catch (err) {
      throw createError(err)
    }
  }
}
