export class DataStat {
    constructor(value = null, lastCharge = null) {
        this._value = value
        this._lastCharge = lastCharge
    }

    get value() {
        return this._value
    }

    get lastCharge() {
        return this._lastCharge
    }

    set value(val) {
        this._value = val
    }

    set lastCharge(val) {
        this._lastCharge = val
    }
}

export class Indicator {
    constructor(shops = '', start = null, end = null, type = 'current', m_totalHT = 0, m_totalTTC = 0, m_cash = 0) {
        this._totalHT = m_totalHT
        this._totalTTC = m_totalTTC
        this._cash = m_cash
        this._chargedR = false
        this._chargedC = false
        this._start = start
        this._end = end
        this._type = type
        this._shops = shops
        this._detailsR = []
        this._detailsC = []
    }

    get totalHT() {
        return this._totalHT
    }

    get totalTTC() {
        return this._totalTTC
    }

    get cash() {
        return this._cash
    }

    get chargedR() {
        return this._chargedR
    }

    get chargedC() {
        return this._chargedC
    }

    get start() {
        return this._start
    }

    get end() {
        return this._end
    }

    get type() {
        return this._type
    }

    get shops() {
        return this._shops
    }

    get detailsR(){
        return this._detailsR
    }
    get detailsC(){
        return this._detailsC
    }

    set totalHT(val) {
        this._totalHT = val
    }

    set totalTTC(val) {
        this._totalTTC = val
    }

    set cash(val) {
        this._cash = val
    }

    set chargedR(val) {
        this._chargedR = val
    }

    set chargedC(val) {
        this._chargedC = val
    }

    set start(val) {
        this._start = val
    }

    set end(val) {
        this._end = val
    }

    set type(val) {
        this._type = val
    }

    set shops(val) {
        this._shops = val
    }

    set detailsR(val){
        this._detailsR = JSON.stringify(val)
    }

    set detailsC(val){
        this._detailsC = JSON.stringify(val)
    }
}