import blocksDB from '@/helpers/blocksDataBase'

export default {
  namespaced: true,
  state: () => ({
    blocks: blocksDB().getBlockSortByCategory()
  }),
  mutations: {
    SET_BLOCKS(state, value) {
      state.blocks = value
    }
  },
  actions: {
    async setBlocks({ commit }, data) {
      try {
        commit('SET_BLOCKS', data)
      } catch (err) {
        return []
      }
    }
  }
}
