import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getProfilesByPhone(phoneNumber, phoneCountry) {
    try {
      const response = await axios.get(
        config.apiURL +
          '/api/profiles/phone?phone=' +
          phoneNumber +
          '&country=' +
          phoneCountry
      )

      return response.data.profiles
    } catch (err) {
      throw createError(err)
    }
  },
  async getProfilesByCode(code) {
    try {
      const response = await axios.get(
        config.apiURL + '/api/profiles/code?code=' + code
      )

      return response.data.profile
    } catch (err) {
      throw createError(err)
    }
  },
  async createProfile(data) {
    try {
      const response = await axios.post(config.apiURL + '/api/profiles', data)

      return response.data.profile
    } catch (err) {
      throw createError(err)
    }
  },
  async updateProfile(data) {
    try {
      const response = await axios.put(config.apiURL + '/api/profiles', data)

      return { user: response.data.user, profile: response.data.profile }
    } catch (err) {
      throw createError(err)
    }
  }
}
