import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getAllAbShops() {
    try {
      const response = await axios.get(config.apiURL + '/api/shops/artebeaute')

      return {
        shops: response.data.shops
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async updateAbShop(artebeauteId, webId) {
    try {
      const response = await axios.put(
        config.apiURL + '/api/shops/artebeaute',
        {
          artebeauteId,
          webId
        }
      )

      return {
        shops: response.data.shops
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getAllShops() {
    try {
      const response = await axios.get(config.apiURL + '/api/shops')

      return {
        shops: response.data.shops
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getShop(id) {
    try {
      const response = await axios.get(config.apiURL + '/api/shops/' + id)

      return {
        shop: response.data.shop
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getShopBySlug(slug) {
    try {
      const response = await axios.get(
        config.apiURL + '/api/shops/slug/' + slug
      )

      return {
        shop: response.data.shop
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async createShop(data) {
    try {
      const response = await axios.post(config.apiURL + '/api/shops', data)

      return {
        shop: response.data.shop
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async updateShop(id, data) {
    try {
      const response = await axios.put(config.apiURL + '/api/shops/' + id, data)

      return {
        shop: response.data.shop
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async deleteShop(id) {
    try {
      const response = await axios.delete(config.apiURL + '/api/shops/' + id)

      return {
        shop: response.data.shop
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async linkArticle(shopId, articleId) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/shops/link/article/' + shopId,
        {
          articleId
        }
      )

      return {
        shop: response.data.shop,
        article: response.data.article
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async unlinkArticle(shopId, articleId) {
    try {
      const response = await axios.delete(
        config.apiURL + '/api/shops/link/article/' + shopId,
        {
          data: {
            articleId
          }
        }
      )

      return {
        shop: response.data.shop,
        article: response.data.article
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async undraftShop(id) {
    try {
      const response = await axios.delete(
        config.apiURL + '/api/shops/' + id + '/draft'
      )

      return {
        shop: response.data.shop
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async draftShop(id) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/shops/' + id + '/draft'
      )

      return {
        shop: response.data.shop
      }
    } catch (err) {
      throw createError(err)
    }
  }
}
