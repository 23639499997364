import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getAllAbResources(shopId, type = null) {
    try {
      let requestURL =
        config.apiURL + '/api/resources/artebeaute?shopId=' + shopId

      if (type) {
        requestURL += '&type=' + type
      }

      const response = await axios.get(requestURL)

      return {
        resources: response.data.resources
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getAllAbCollaborators(shopId) {
    try {
      const requestURL =
        config.apiURL +
        '/api/resources/artebeaute?shopId=' +
        shopId +
        '&type=100'

      const response = await axios.get(requestURL)

      return {
        resources: response.data.resources
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getAllResources() {
    try {
      const response = await axios.get(config.apiURL + '/api/resources')

      return {
        resources: response.data.resources
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getResource(id) {
    try {
      const response = await axios.get(config.apiURL + '/api/resources/' + id)

      return {
        resource: response.data.resource
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async createResource(data) {
    try {
      const response = await axios.post(config.apiURL + '/api/resources', data)

      return {
        resource: response.data.resource
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async updateResource(id, data) {
    try {
      const response = await axios.put(
        config.apiURL + '/api/resources/' + id,
        data
      )

      return {
        resource: response.data.resource
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async deleteResource(id) {
    try {
      const response = await axios.delete(
        config.apiURL + '/api/resources/' + id
      )

      return {
        resource: response.data.resource
      }
    } catch (err) {
      throw createError(err)
    }
  }
}
