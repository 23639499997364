
// import store from '../index'
import { CaSDK } from '@/packages/artebeaute-sdk/src'
import axiosErrorCode from '@/helpers/axios'
import {DataStat} from '../../helpers/statObject'
import dayjs from 'dayjs'

export default {
    namespaced : true,
    state: () => ({
        lastDayOpen: new DataStat(),
        indicRealized: {
            today: new DataStat(),
            yesterday: new DataStat()
        },
        isChargeFromStat: false,
    }),
    mutations: {
        getLastDayOpen(state,value) {
            state.lastDayOpen.value = value.data
            state.lastDayOpen.lastCharge = dayjs()
            console.log('getLastDayOpen',value)
        },
        isReadyToCharge(state,value){
            state.isChargeFromStat = value
        }
    },
    actions: {
        isReadyToCharge({commit}){
          commit('isReadyToCharge')
        },
        async getLastDayOpen({commit,rootGetters}){
            try{
                const rep = await CaSDK.Cashier.getLastDayOpen(rootGetters["auth/getShopUserStat"])
                console.log('je passe',rep)
                commit('getLastDayOpen',rep.getLastDayOpen)
                console.log(rep)
            } catch (e) {
                console.log('erreur',e)
                return {
                    error: true,
                    message:
                        'Une erreur est survenue la récupération de la dernière journée.',
                    code: axiosErrorCode(e)
                }
            }
        },
        /*async getIndicRealizedToday({commit, rootGetters},type) {
          try {
              const dper1 = dayjs().day()
              let periode = {}
              if(type === 'ytd') {
                  periode = {
                      per1 : {
                          start : dayjs().format('YYYYMMDD'),
                          end : dayjs().format('YYYYMMDD')
                      },
                      per2 : {
                          start : dayjs().subtract(1,'year').day(dper1).format('YYYYMMDD'),
                          end :dayjs().subtract(1,'year').day(dper1).format('YYYYMMDD')
                      }
                  }
              }else if(type === 'yesterday') {
                  periode = {
                      per1 : {
                          start : dayjs().format('YYYYMMDD'),
                          end : dayjs().format('YYYYMMDD')
                      },
                      per2 : {
                          start : dayjs().subtract(1,'day').format('YYYYMMDD'),
                          end :dayjs().subtract(1,'day').format('YYYYMMDD')
                      }
                  }
              } else if(type === 'lastWeekOfDay') {
                  periode = {
                      per1 : {
                          start : dayjs().format('YYYYMMDD'),
                          end : dayjs().format('YYYYMMDD')
                      },
                      per2 : {
                          start : dayjs().subtract(1,'week').day(dper1).format('YYYYMMDD'),
                          end :dayjs().subtract(1,'week').day(dper1).format('YYYYMMDD')
                      }
                  }
              }

          } catch (e) {
              console.log('erreur',e)
          }
        }*/
    }
}