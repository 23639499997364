import axios from 'axios'
import { createError } from '../helpers/errors'
import config from '../config'

export default {
  async getAllPrestations() {
    try {
      const response = await axios.get(config.apiURL + '/api/prestations')

      return {
        prestations: response.data.prestations
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async getPrestation(id) {
    try {
      const response = await axios.get(config.apiURL + '/api/prestations/' + id)

      return {
        prestation: response.data.prestation
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async createPrestation(data) {
    try {
      const response = await axios.post(
        config.apiURL + '/api/prestations',
        data
      )

      return {
        prestation: response.data.prestation
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async updatePrestation(id, data) {
    try {
      const response = await axios.put(
        config.apiURL + '/api/prestations/' + id,
        data
      )

      return {
        prestation: response.data.prestation
      }
    } catch (err) {
      throw createError(err)
    }
  },
  async deletePrestation(id) {
    try {
      const response = await axios.delete(
        config.apiURL + '/api/prestations/' + id
      )

      return {
        prestation: response.data.prestation
      }
    } catch (err) {
      throw createError(err)
    }
  }
}
