import shops from './shops/shops'
import articles from './articles/articles'
import resources from './resources/resources'
import categories from './categories/categories'
import geogroupments from './geogroupments/geogroupments'
import appointments from './appointments/appointments'
import transactions from './transactions/transactions'
import statbooking from './statistiques/booking'
import customers from './customers/customers'

import config from './config'

export default {
  Config: config,
  Shops: {
    ...shops
  },
  Articles: {
    ...articles
  },
  Resources: {
    ...resources
  },
  Categories: {
    ...categories
  },
  Geogroupments: {
    ...geogroupments
  },
  Appointments: {
    ...appointments
  },
  Transactions: {
    ...transactions
  },
  StatBooking: {
    ...statbooking
  },
  Customers: {
    ...customers
  },

  initialize(config) {
    Object.keys(config).forEach(key => {
      this.Config[key] = config[key]
    })
  }
}
